import React, { useState , useEffect } from "react";
import { localize, changeLanguage } from '../../styles/GlobalStrings';
import { DetailsFrame, EventDetails, GameMetadata, Item, Outcome, Participant, Record, Result, TeamStats, WindowFrame, WindowParticipant } from "../types/baseTypes.js";
import championNames from '../Campeoes/championNames';
import jogadoresNames from '../Jogadores/jogadoresNames';
import { CHAMPIONS_URL, Link_App, Link_Video_URL, getFormattedPatchVersion } from '../../utils/LoLEsportsAPI';
import ChampsRes from './ChampsRes';
import {selectitembox , buildTournamentName} from './../Geral/SelectTournamentName'

export function BoxChampsData({event ,side1,side2, players,players2,gameMetadata,championsUrlWithPatchVersion}:{event:any,side1: string,side2: string,players: WindowParticipant[], players2: WindowParticipant[], gameMetadata: GameMetadata, championsUrlWithPatchVersion: string}) {
    const teamMetadata =  gameMetadata.blueTeamMetadata.participantMetadata ;
    const teamMetadata2 =  gameMetadata.redTeamMetadata.participantMetadata;
    const liganame = event.league.name === "Prime League" ? "LCK" : event.league.name ;


    const [ligaSelecionada, setLigaSelecionada] = useState('CBLOL');
    const [splitSelecionado, setSplitSelecionado] = useState('2024-2');
    const [tipoJogoSelecionado, setTipoJogoSelecionado] = useState('');

    const handleLigaChange = (event:any) => {
        setLigaSelecionada(event.target.value);
    };

    const handleSplitChange = (event:any) => {
        setSplitSelecionado(event.target.value);
    };

    const handleTipoJogoChange = (event:any) => {
        setTipoJogoSelecionado(event.target.value);
    };


    return (
        <div className='Org-box'>
           {selectitembox(ligaSelecionada,handleLigaChange,splitSelecionado,handleSplitChange,tipoJogoSelecionado,handleTipoJogoChange)}
        <div className="team-info-container-db">
            <table className="team-header-db">
                <thead>
                    <tr>
                        <th className={`${side1}`} data-tooltip={localize('EStext')} >Estatisticas</th>
                        <th className={`${side1}`} data-tooltip={localize('Rotatext')} >Rota</th>
                        <th className={`${side1}`} data-tooltip={localize('WRtext')} >WR</th>
                        <th className={`${side1}`} data-tooltip={localize('Wintext')} >W</th>
                        <th className={`${side1}`} data-tooltip={localize('Dertext')} >L</th>
                        <th className={`${side1}`} data-tooltip={localize('KDAtext')} >KDA</th>
                        <th className={`${side1}`} data-tooltip={localize('Priotext')} >P</th>
                        <th className={`${side1}`} data-tooltip={localize('TMtext')} >T/M</th>
                    </tr>
                </thead>
                <tbody>
                    {players.map((player) => {
                        const metadata = teamMetadata.find(meta => meta.participantId === player.participantId);
                        if (!metadata) return null;
                        return (
                            <React.Fragment key={`${metadata.championId}_${metadata.summonerName}`}>
                                <ChampsDetails
                                    metadata={metadata}
                                    UrlChamp={championsUrlWithPatchVersion}  
                                    ligaSelecionada={ligaSelecionada}
                                    splitSelecionado={splitSelecionado}
                                    tipoJogoSelecionado={tipoJogoSelecionado}                              
                                />
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
        </div>
        <div className="team-info-container-db">
            <table className="team-header-db">
                <thead>
                    <tr className='header-Table'>
                        <th className={`${side2}`} data-tooltip={localize('EStext')} >Estatisticas</th>
                        <th className={`${side2}`} data-tooltip={localize('Rotatext')} >Rota</th>
                        <th className={`${side2}`} data-tooltip={localize('WRtext')} >WR</th>
                        <th className={`${side2}`} data-tooltip={localize('Wintext')} >W</th>
                        <th className={`${side2}`} data-tooltip={localize('Dertext')} >L</th>
                        <th className={`${side2}`} data-tooltip={localize('KDAtext')} >KDA</th>
                        <th className={`${side2}`} data-tooltip={localize('Priotext')} >P</th>
                        <th className={`${side2}`} data-tooltip={localize('TMtext')} >T/M</th>
                    </tr>
                </thead>
                <tbody>
                    {players2.map((player) => {
                        const metadata2 = teamMetadata2.find(meta => meta.participantId === player.participantId);
                        if (!metadata2) return null;
                        return (
                            <React.Fragment key={`${metadata2.championId}_${metadata2.summonerName}`}>
                                <ChampsDetails
                                    metadata={metadata2}
                                    UrlChamp={championsUrlWithPatchVersion}
                                    ligaSelecionada={ligaSelecionada}
                                    splitSelecionado={splitSelecionado}
                                    tipoJogoSelecionado={tipoJogoSelecionado}
                                />
                            </React.Fragment>

                            
                        );
                    })}
                </tbody>
            </table>
        </div>



        </div>
    );
}

function ChampsDetails({ metadata, UrlChamp ,ligaSelecionada ,splitSelecionado, tipoJogoSelecionado }: {  metadata: any, UrlChamp: string ,ligaSelecionada :any ,splitSelecionado :any, tipoJogoSelecionado :any }) {
    const [error, setError] = useState<string | null>(null);
    
    const summonerNameWords = metadata.summonerName.split(' ');
    const summonerNameLastWord = summonerNameWords[summonerNameWords.length - 1];
    const championName = metadata.championId;
    const championCerto = championNames[championName as keyof typeof championNames] || championName;

   
    const lane = metadata.role === "bottom" ? "Bot" :metadata.role === "mid" ? "Mid":metadata.role === "jungle" ? "Jungle":metadata.role === "support" ? "Support":metadata.role === "top" ? "Top": metadata.role

    
        const [campeoesT, setCampeoesT] = useState<any[]>([]);
        const [campeoesPeB, setCampeoesPeB] = useState<any[]>([]);
        const [okteste, okset] = useState<any[]>([]);
      

        useEffect(() => {
            const nome = buildTournamentName(ligaSelecionada, splitSelecionado, tipoJogoSelecionado);
            const fetchData = async () => {
                try {
                    setCampeoesT(okteste)
                    const data24P = await fetch_champs_by_tournament_Indiv(nome, championCerto, lane);
                    setCampeoesT(data24P); // Se data24P for undefined, define um array vazio
                    setError(null); // Redefine o estado de erro se a solicitação for bem-sucedida
                } catch (error:any) {
                    console.error("An error occurred:", error);
                    setCampeoesT(okteste)
                    setError(null); // Define o estado de erro se ocorrer um erro na solicitação
                }
            };
            const fetchData2 = async () => {
                try {
                    const data24PeB = await fetch_champs_PeB_by_tournament(nome, championCerto);
                    setCampeoesPeB(data24PeB); // Se data24P for undefined, define um array vazio
                    setError(null); // Redefine o estado de erro se a solicitação for bem-sucedida
                } catch (error:any) {
                    console.error("An error occurred:", error);
                    setCampeoesPeB(okteste);
                    setError(null); // Define o estado de erro se ocorrer um erro na solicitação
                }
            };
            fetchData();
            fetchData2();
            setError(null);
        }, [ligaSelecionada, splitSelecionado, tipoJogoSelecionado]);

        const fetch_champs_by_tournament_Indiv = async (TournamentName: string,ChampName:string,LaneName:string) => {
            try {
                const response = await fetch(`${Link_App}/get_champs_by_tournament_Indiv?tournament_name=${TournamentName}&champ_name=${ChampName}&role_name=${LaneName}`);
              if (!response.ok) {
                return okteste;
              }
              const data = await response.json(); // Expecting an array of champions
              return(data);

            } catch (error: any) {
              /* setError(error.message);*/
              console.error("An error occurred:", error);
              return okteste;
            }
          };   
          
          const fetch_champs_PeB_by_tournament = async (TournamentName: string,ChampName:string) => {
            try {
                const response = await fetch(`${Link_App}/get_champs_Pick_e_Bans_by_tournament?tournament_name=${TournamentName}&champ_name=${ChampName}`);
              if (!response.ok) {
                return okteste;
              }
              const data = await response.json(); // Expecting an array of champions
              return(data);

            } catch (error: any) {
              /* setError(error.message);*/
              console.error("An error occurred:", error);
              return okteste;
            }
          };        
  

    return (
        <>
            <ChampsRes  data={campeoesT} data2={campeoesPeB} summonerName={metadata.summonerName} championId={metadata.championId} UrlChamp={UrlChamp}/>       
        </>
    );
}