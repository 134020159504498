import React, { useState, useEffect } from 'react';
import {Navigate, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut , sendEmailVerification } from "firebase/auth";
import { getFirestore, doc, getDoc, updateDoc } from 'firebase/firestore';
import { usePremium } from '../Premium/PremiumContext';
import './styles/perfil.css';
import AlertBox from '../Geral/AlertBox';
import { Link_App } from '../../utils/LoLEsportsAPI';
import axios from 'axios';

function Perfil() {
  const [user, setUser] = useState(null);
  const [userId, setUserId] = useState(null);
  const [isEmailVerified, setIsEmailVerified] = useState(false); // novo estado
  const [verificationEmailSent, setVerificationEmailSent] = useState(false); 
  const [isEditing, setIsEditing] = useState({});
  const [editedValues, setEditedValues] = useState({});
  const firestore = getFirestore();
  const { isPremium, stripePremium, endPremium } = usePremium();
  const auth = getAuth();
  const navigate = useNavigate(); 
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertVisible1, setAlertVisible1] = useState(false);
  const [loading, setLoading] = useState(false); // novo estado
  const [alertMessage, setAlertMessage] = useState('');

  const showAlert1 = (message) => {
    setAlertMessage(message);
    setAlertVisible1(true);
  };

  const closeAlert1 = () => {
    setAlertVisible1(false);
    setAlertMessage('');
  };

  const showAlert = () => {
    setAlertVisible(true);
  };

  const closeAlert = () => {
    setAlertMessage('');
  };
  useEffect(() => {
    document.title = "Perfil - Análise das Lendas";
    const unsubscribe = onAuthStateChanged(auth, async (authenticatedUser) => {
      if (authenticatedUser) {
        const userId = authenticatedUser.uid;
        setUserId(userId);
        setIsEmailVerified(authenticatedUser.emailVerified); // verificando se o e-mail foi verificado
        const userDocRef = doc(firestore, 'users', userId);
        const userDocSnap = await getDoc(userDocRef);

        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          setUser(userData);
          setEditedValues({
            code: userData.code,
            nome: userData.nome,
            email: userData.email,
            birthdate: userData.birthdate,
            convite: userData.convite,
          });
        } else {
          console.error("Dados do usuário não encontrados no Firestore");
        }
      } else {
        navigate("/login");
      }
    });

    return () => unsubscribe();
  }, [navigate, firestore, auth]);

  const toggleEdit = (field) => {
    setIsEditing(prevState => ({ ...prevState, [field]: !prevState[field] }));
  };

  const handleSave = async (field) => {
    if (!user || !userId) return;
    const userDocRef = doc(firestore, 'users', userId);

    if (field === 'convite' && editedValues[field]) {
      setLoading(true); // ativar estado de carregamento
      try {
        const response = await axios.post(`${Link_App}/saveinvitecode`, { userId, inviteCode: editedValues[field] });
        if (response.data.success) {
          setUser(prevState => ({ ...prevState, [field]: editedValues[field] }));
          showAlert1(response.data.success);
        } else if (response.data.error) {
          showAlert1(response.data.error);
        }
      } catch (error) {
        console.error('Erro ao salvar o código de convite:', error);
        showAlert1('Erro ao salvar o código de convite.');
      } finally {
        setLoading(false); // desativar estado de carregamento
      }
    } else {
      if (editedValues[field] !== undefined) {
        await updateDoc(userDocRef, { [field]: editedValues[field] });
        setUser(prevState => ({ ...prevState, [field]: editedValues[field] }));
      } else {
        console.error(`Valor indefinido para o campo ${field}`);
      }
    }
    toggleEdit(field);
  };

  const toggleEditEmail = () => {
    
  };

  function ProfilePeriod(end) {
    const convertTimestampToDate = (timestamp) => {
      const date = new Date(timestamp * 1000);
      return date.toLocaleDateString('pt-BR'); 
    };
  
    const endDate = convertTimestampToDate(end);
  
    return endDate;
  }

  const renderPremiumInfo = () => {
    const renewalText = stripePremium ? "se renovará em " + ProfilePeriod(endPremium) : "acabará em " + ProfilePeriod(endPremium);
    const typeText = stripePremium ? "Stripe" : "Pix";
    const manageButton = stripePremium ? (
      <button onClick={() => window.open('https://billing.stripe.com/p/login/28obLTgR019H9W0144', '_blank')}>Gerenciar Assinatura</button>
    ) : null;

    if (!isPremium) return (
      <div className="premium-container">
        <section className="account-settings">
          <div className="membership-info">
            <div className="membership-header">
              <h1 className="membership-title">Informações de Assinatura</h1>
              <p className="membership-description">Gerencie seu plano de assinatura &amp; histórico de cobranças</p>
            </div>
            <div className="membership-content">
              <div className="premium-info">
                <div className="premium-title-wrapper">
                  <h1 className="premium-title"> Não Possui Assinatura Ativa</h1>
                </div>
                <div className="price-info">
                <span className="amount">Caso deseje obter uma : </span>
                <button onClick={() => (navigate('/Premium'))}>Premium</button>
                </div>
               
              </div>
            </div>
            <div className="order-history">
            <button onClick={() => window.open('https://billing.stripe.com/p/login/28obLTgR019H9W0144', '_blank')}>Gerenciar Assinatura</button>
              <p className="order-history-description">
                Caso tenha alguma dúvida, entre em contato com o
                <a className="order-history-link" href="https://t.me/AnaliseLOL" rel="noopener noreferrer" target="_blank"> Suporte ao Consumidor</a>.
              </p>
            </div>
          </div>
        </section>
      </div>
    );

  
    return (
      <div className="premium-container">
        <section className="account-settings">
          <div className="membership-info">
            <div className="membership-header">
              <h1 className="membership-title">Informações de Assinatura</h1>
              <p className="membership-description">Gerencie seu plano de assinatura &amp; histórico de cobranças</p>
            </div>
            <div className="membership-content">
              <div className="premium-info">
                <div className="premium-title-wrapper">
                  <svg className="premium-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" aria-labelledby="premium-icon-title" aria-hidden="true" role="img">
                    <title id="premium-icon-title">Premium</title>
                    <path d="M2.419 13L0 4.797 4.837 6.94 8 2l3.163 4.94L16 4.798 13.581 13z"></path>
                  </svg>
                  <h1 className="premium-title">Assinante {typeText} Premium</h1>
                </div>
                <div className="price-info">
                  <span className="amount">R$ 19,99 / mensalmente</span>
                  <span className="billing-cycle"></span>
                  <span className="taxes">+ taxas aplicáveis</span>
                </div>
                <p className="renewal-date">Sua assinatura {renewalText}</p>
                {manageButton}
              </div>
            </div>
            <div className="order-history">
              <p className="order-history-description">
                Caso tenha alguma dúvida, entre em contato com o
                <a className="order-history-link" href="https://t.me/AnaliseLOL" rel="noopener noreferrer" target="_blank"> Suporte ao Consumidor</a>.
              </p>
            </div>
          </div>
        </section>
      </div>
    );
  };

  const logout = async () => {
    try {
      await signOut(auth);
      localStorage.setItem('logoutMessage', 'Você foi desconectado com sucesso.'); // Armazena a mensagem de logout
      navigate("/login");
    } catch (error) {
      console.error('Erro ao fazer logout:', error);
    }
  };

  const handleSendVerificationEmail = async () => {
    if (auth.currentUser) {
      try {
        await sendEmailVerification(auth.currentUser);
        setVerificationEmailSent(true);
      } catch (error) {
        console.error('Erro ao enviar e-mail de verificação:', error);
      }
    }
  };



 
  if (!user) {
    return (
      <div className="profile-container">
        <button onClick={logout}>Desconectar</button>
      </div>
    );
  }

  return (
    <div className="profile-container">
      <h2>Perfil do Usuário</h2>
      <div className="user-info">
        <label>Nome:</label>
        {isEditing.nome ? (
          <>
            <input
              type="text"
              value={editedValues.nome}
              onChange={(e) => setEditedValues({ ...editedValues, nome: e.target.value })}
            />
            <button onClick={() => handleSave('nome')}>Salvar</button>
          </>
        ) : (
          <>
            <span>{user.nome}</span>
            <button onClick={() => toggleEdit('nome')}>Editar</button>
          </>
        )}
      </div>
      <div className="user-info">
        <label>Email:</label>
        {isEditing.email ? (
          <>
            <input
              type="email"
              value={editedValues.email}
              onChange={(e) => setEditedValues({ ...editedValues, email: e.target.value })}
            />
            <button onClick={() => handleSave('email')}>Salvar</button>
          </>
        ) : (
          <>
            <span>{user.email}</span>            
            <button onClick={showAlert}>Editar</button>
            {alertVisible && <AlertBox message="Para Mudar seu E-mail entre em contato com o Suporte!" onClose={closeAlert} />}
          </>
        )}
      </div>
      <div className="user-info">
        <label>Data de Nascimento:</label>
        {isEditing.birthdate ? (
          <>
            <input
              type="date"
              value={editedValues.birthdate}
              onChange={(e) => setEditedValues({ ...editedValues, birthdate: e.target.value })}
            />
            <button onClick={() => handleSave('birthdate')}>Salvar</button>
          </>
        ) : (
          <>
            <span>{user.birthdate}</span>
            <button onClick={() => toggleEdit('birthdate')}>Editar</button>
          </>
        )}
      </div>
      <div className="user-info">
        <label>Código de Convite:</label>
        {user.convite ? (
          <span>{user.convite}</span>
        ) : (
          <>
            {isEmailVerified ? (
              isEditing.convite ? (
                <>
                  <input
                    type="email"
                    value={editedValues.convite}
                    onChange={(e) => setEditedValues({ ...editedValues, convite: e.target.value })}
                  />
                  <button onClick={() => handleSave('convite')}>Salvar</button>
                </>
              ) : (
                <button onClick={() => toggleEdit('convite')}>Adicionar Código de Convite</button>
              )
            ) : (
              <div>
                <span>Verifique seu e-mail para adicionar um código de convite.</span>
                {!verificationEmailSent ? (
                  <button onClick={handleSendVerificationEmail}>Enviar E-mail de Verificação</button>
                ) : (
                  <span>Um e-mail de verificação foi enviado.</span>
                )}
              </div>
            )}
          </>
        )}
      </div>
      {loading && <div>Verificando e salvando...</div>}
      {alertVisible1 && <AlertBox message={alertMessage} onClose={closeAlert1} />} {/* exibir alerta com a mensagem */}
      {renderPremiumInfo()}
      <button onClick={logout}>Desconectar</button>
    </div>
  );
}

export default Perfil;
