import './styles/playerStatusStyle.css'
import '../Schedule/styles/scheduleStyle.css'
import { localize, changeLanguage } from '../../styles/GlobalStrings.js';

import { useEffect } from "react";
import { EventDetails } from "../types/baseTypes";

type Props = {
    eventDetails: EventDetails,
    gameIndex: number
}

export function GameDetails({ eventDetails, gameIndex }: Props) {
    useEffect(() => {
        // Coloque aqui qualquer efeito necessário
    }, [eventDetails, gameIndex]);

    return (
        eventDetails.match.games.length > 1 ? (
            <div className='game-selector'>
                {eventDetails.match.games.map((game) => (
                    <a
                        className={`game-selector-item ${game.state} ${gameIndex === game.number ? 'selected' : ''}`}
                        href={`/live/${eventDetails.id}/game-index/${game.number}`}
                        key={`game-selector-${game.id}`}
                    >
                        <span className={`/live/${game.state}`}>
                            Game {game.number} - {GameStateName(game.state)}
                        </span>
                    </a>
                ))}
            </div>
        ) : null
    );
}

function GameStateName(value:any){
    if 
    (value == "completed") {
        return localize('Completedtext');
    } else if 
    (value == "unstarted") {
        return localize('Unstartedtext');
    }
    else if 
    (value == "inProgress") {
        return localize('InProgresstext');
    }
    else if 
    (value == "unneeded") {
        return localize('Unneededtext');
    }
return "erro"
   
}

function capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}