// src/components/ImageLightbox.tsx
import React from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

interface ImageLightboxProps {
  image: string;  // Nota que mudamos para "image"
}

const ImageLightbox: React.FC<ImageLightboxProps> = ({ image }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  return (
    <div>
      <img
        src={image}
        alt="Thumbnail"
        style={{ cursor: 'pointer' }}
        onClick={() => setIsOpen(true)}
      />
      {isOpen && (
        <Lightbox
          mainSrc={image}
          onCloseRequest={() => setIsOpen(false)}
        />
      )}
    </div>
  );
};

export default ImageLightbox;
