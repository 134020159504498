import React, { useState, useEffect } from 'react';
import { useChat } from '../../utils/ChatContext';
import { Link_Video_URL } from '../../utils/LoLEsportsAPI';
import Chat from './Chat';


function transformTwitchChatLink(originalLink:string) {
  // Verifica se originalLink é uma string
  if (typeof originalLink !== 'string') {
    return "Sem Chat";
  }
  
  // Extrai o nome do canal do link original
  const match = originalLink.match(/[?&]channel=([^&]+)/);
  if (!match || match.length < 2) {
    return "Sem Chat";
  }
  const channelName = match[1];
  
  // Constrói o novo link com o formato desejado
  const newLink = `https://www.twitch.tv/embed/${channelName}/chat?parent=${Link_Video_URL}`;
  console.log(newLink);
  return newLink;
}



const DraggableChat: React.FC<{ id: string }> = ({ id }) => {
  const { chatLink } = useChat();
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeTab, setActiveTab] = useState(1); // Default tab
  const [chatUrl, setChatUrl] = useState("");

  function Resultado(){
    if(chatUrl === "Sem Chat" ||chatUrl === null){
      return(<div>Nenhum Chat Disponivel</div>
      )}
      else{
        return(<iframe src={chatUrl} title="Chat" />)
      }
    
}

  // Atualiza o chatUrl sempre que o chatLink for alterado
  useEffect(() => {
    setChatUrl(transformTwitchChatLink(chatLink));
  }, [chatLink]);

  const toggleChat = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <div className={`floating-chat ${isExpanded ? 'expanded' : ''}`}>
        <button className="chat-button" onClick={toggleChat}>
          {isExpanded ? 'Fechar' : 'Chat'}
        </button>
        {isExpanded && (
          <div className="chat-box">
            <button
              className={`tab-button ${activeTab === 1 ? 'active-but' : ''}`}
              onClick={() => setActiveTab(1)}
            >
              Chat AL
            </button>
            <button
              className={`tab-button ${activeTab === 2 ? 'active-but' : ''}`}
              onClick={() => setActiveTab(2)}
            >
              Nativo
            </button>
            <button
              className={`tab-button ${activeTab === 3 ? 'active-but' : ''}`}
              onClick={() => setActiveTab(3)}
            >
              Baiano
            </button>
            {activeTab === 1 && <Chat />}
            {activeTab === 2 && Resultado()}
            {activeTab === 3 && (
              <iframe
                src={`https://www.twitch.tv/embed/baiano/chat?parent=${Link_Video_URL}`}
                title="Chat"
              />
            )}
          </div>
        )}
      </div>
    </>
  );
}

export default DraggableChat;
