import React, { useEffect, useState } from 'react';
import { useBet } from './BetContext';
import './styles/betMatch.css';
import { ScheduleEvent } from "../types/baseTypes";
import { useAuth } from './../../utils/AuthContext';
import { Navigate, useNavigate } from 'react-router-dom';
import AlertBox from './../Geral/AlertBox';

interface MatchProps {
  match_id: string;
  scheduleEvent: ScheduleEvent;
  timeRemaining: number;
}

const Match = ({ match_id, scheduleEvent, timeRemaining }: MatchProps) => {
  const [loading, setLoading] = useState(false);
  const {user} = useAuth();
  const { addBet } = useBet();
  const [amount, setAmount] = useState<number>(0);
  const [prediction, setPrediction] = useState<string>('');
  const [bet_img, setbet_img] = useState<string>('');
  const TeamA = scheduleEvent.match.teams[0];
  const TeamB = scheduleEvent.match.teams[1];
  const [betPlaced, setBetPlaced] = useState(false);
  const navigate = useNavigate();
 

  const formatTime = (seconds: number) => {
    if (seconds) {
      const minutes = Math.floor(seconds / 60);
      const sec = Math.floor(seconds % 60);
      return `Tempo restante para apostar: ${minutes}:${sec < 10 ? '0' : ''}${sec}`;
     
    } 
    return "";
  };

  const handleBetClick = async () => {
    if (amount <= 0 || amount > 1000 || !prediction) {
      alert("Preencha todos os campos corretamente. Valor máximo de aposta é 1000.");
      return;
    }
    setLoading(true);
    try {
      const bet_time = new Date().toISOString();
      await addBet({ match_id, amount, prediction, bet_time, bet_img });
      setBetPlaced(true); 
    } catch (error) {
      console.error('Failed to place bet:', error);
    } finally {
      setLoading(false);
      
    }
  };

  const handleTeamClick = (teamName: string, teamImage: string) => {
    setPrediction(teamName);
    setbet_img(teamImage);
  };

  const handleLoginRedirect = () => {
    navigate('/login');
};

  const renderButton = () => {
    if (user){
      return ( <>
      {!loading ? (
        <div>
        <input
        type="number"
        className="bet-bet-input"
        value={amount}
        onChange={(e) => setAmount(Math.min(1000, Number(e.target.value)))}
        placeholder="Aposta"
        max={1000}
        min={0}
      />
      
        <button className="bet-bet-button" onClick={handleBetClick} disabled={loading}>
          {loading ? 'Apostando...' : 'Apostar'}
        </button>
        </div>
      ) : (
        <div className="bet-bet-done">
          <div>Verificando e salvando...</div>
        </div>
      )}
      </> )
    }
    return(
    <button className="bet-bet-button" onClick={handleLoginRedirect} >Faça Login</button>
    )
  }
  return (
    <div className="bet-match-container">
      <div className="bet-match-header">
        <span className="bet-match-id">id:{match_id}</span>
        <span className="bet-match-league">{scheduleEvent.league.name} || ODD: 2x</span>
        <h2 className="bet-match-title">
          <span><img className="bet-team-image" src={TeamA.image} alt={TeamA.name} /></span> vs <span><img className="bet-team-image" src={TeamB.image} alt={TeamB.name} /></span>
        </h2>
        <div className="bet-match-timer">
          {formatTime(timeRemaining)}
        </div>
      </div>
      <div className="bet-match-buttons">
        <button
          className={`bet-match-button BetteamA ${prediction === TeamA.name ? 'selected' : ''}`}
          onClick={() => handleTeamClick(TeamA.name, TeamA.image)}
        >
          {TeamA.name}
        </button>
        <button
          className={`bet-match-button BetteamB ${prediction === TeamB.name ? 'selected' : ''}`}
          onClick={() => handleTeamClick(TeamB.name, TeamB.image)}
        >
          {TeamB.name}
        </button>
      </div>
      {renderButton()}
      <div><span className="bet-match-league"> Vencedor da Melhor de : {scheduleEvent.match.strategy.count}</span></div>
     
    </div>
  );
};

export default Match;
