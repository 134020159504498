const championNames = {
    MonkeyKing: "Wukong",
    KSante: "K'Sante",
    Chogath: "Cho'Gath",
    Belveth: "Bel'Veth",
    Renata: "Renata Glasc",
    XinZhao: "Xin Zhao",
    KogMaw: "Kog'Maw",
    Velkoz: "Vel'Koz",
    Khazix: "Kha'Zix",
    MasterYi: "Master Yi",
    LeeSin: "Lee Sin",
    Kaisa: "Kai'Sa",
    AurelionSol: "Aurelion Sol",
    JarvanIV: "Jarvan IV",
    DrMundo: "Dr. Mundo",
    TahmKench: "Tahm Kench",
    TwistedFate: "Twisted Fate",
    RekSai: "Rek'Sai",
    RenataGlasc: "Renata Glasc"
};

export default championNames;