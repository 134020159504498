import React, { useState, useEffect } from 'react';

interface PlayerInfo {
  'Player ID': string;
  'Name': string;
  'Total Games': string;
  'Teams': string;
  'League History': string;
  'League': string;
}

const ApiComponent: React.FC = () => {
  const [playerInfo, setPlayerInfo] = useState<PlayerInfo | null>(null);
  const [error, setError] = useState<string | null>(null);

  const fetchPlayerInfo = async (playerName: string) => {
    try {
      const response = await fetch(`http://localhost:5000/get_player_info?player_name=${playerName}`);
      if (!response.ok) {
        throw new Error('Player not found');
      }
      const data: PlayerInfo = await response.json();
      setPlayerInfo(data);
    } catch (error: any) {
      setError(error.message);
    }
  };

  useEffect(() => {
    fetchPlayerInfo('tinowns'); // Altere para o jogador desejado
  }, []);

  if (error) return <div>Error: {error}</div>;

  return (
    <div>
      {playerInfo ? (
        <table>
          <thead>
            <tr>
              <th>Player ID</th>
              <th>Name</th>
              <th>Total Games</th>
              <th>Teams</th>
              <th>League History</th>
              <th>League</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{playerInfo['Player ID']}</td>
              <td>{playerInfo['Name']}</td>
              <td>{playerInfo['Total Games']}</td>
              <td>{playerInfo['Teams']}</td>
              <td>{playerInfo['League History']}</td>
              <td>{playerInfo['League']}</td>
            </tr>
          </tbody>
        </table>
      ) : (
        <div>Loading...</div>
      )}
    </div>
  );
};

export default ApiComponent;
