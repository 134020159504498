// src/components/HeroSection.tsx
import React from 'react';
import './styles/HeroSection.css';
import { Link } from 'react-router-dom';

export function HeroSection() {
  return (
    <header className="hero-section">
     <h2>Veja o futuro dos jogos!</h2>
      <p>Análises detalhadas e dados ao vivo sobre campeões e jogadores.</p>
      <Link to="/login" className="cta-button">Inscreva-se Agora</Link>
    </header>
  );
}
