import './styles/playerStatusStyle.css'
import './styles/MatchStyles.css'
import { localize, changeLanguage } from '../../styles/GlobalStrings.js';

import {
    getEventDetailsResponse,
    getISODateMultiplyOf10,
    getGameDetailsResponse,
    getWindowResponse,
    getScheduleResponse,
    getStandingsResponse,
    getItemsResponse,
    getFormattedPatchVersion,
    CHAMPIONS_URL,
    Link_Video_URL
} from "../../utils/LoLEsportsAPI";
import { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Loading from '../../assets/images/loading.svg'
import { ReactComponent as TeamTBDSVG } from '../../assets/images/team-tbd.svg';
import { MatchDetails } from "./MatchDetails"
import { Game } from "./Game";
import { GameDetails } from "./GameDetails"

import { EventDetails, DetailsFrame, GameMetadata, Item, Outcome, Record, Result, ScheduleEvent, Standing, WindowFrame, ExtendedVod } from "../types/baseTypes"
import BoxData from "./../IA/BoxData";
import {BoxPlayersData} from "../Boxes/BoxPlayersData"
import {BoxChampsData} from "../Boxes/BoxChampsData"
import {BoxTeamStats} from "../Boxes/BoxTeamStats"

export function Match() {
    const { gameid } = useParams<{ gameid: any }>();  
    const [eventDetails, setEventDetails] = useState<EventDetails>();
    const [firstWindowFrame, setFirstWindowFrame] = useState<WindowFrame>();
    const [lastDetailsFrame, setLastDetailsFrame] = useState<DetailsFrame>();
    const [lastWindowFrame, setLastWindowFrame] = useState<WindowFrame>();
    const [metadata, setMetadata] = useState<GameMetadata>();
    const [records, setRecords] = useState<Record[]>();
    const [results, setResults] = useState<Result[]>();
    const [currentGameOutcome, setCurrentGameOutcome] = useState<Array<Outcome>>();
    const [scheduleEvent, setScheduleEvent] = useState<ScheduleEvent>();
    const [gameIndex, setGameIndex] = useState<number>(0);
    const [items, setItems] = useState<Item[]>();
    const [isMinimized, setIsMinimized] = useState(true);


  // Função para alternar a visibilidade da div
     const toggleMinimize = () => {
       setIsMinimized(!isMinimized);
     };
  

    const matchId = gameid ;
    let matchEventDetails = eventDetails
    let currentGameIndex = 1
    let lastFrameSuccess = false
    let currentTimestamp = ``
    let firstWindowReceived = false
    useEffect(() => {
        getEventDetails(getInitialGameIndex());
       

        const windowIntervalID = setInterval(() => {
            if (!matchEventDetails) return
            let newGameIndex = getGameIndex(matchEventDetails)
            let gameId = matchEventDetails.match.games[newGameIndex - 1].id
            if (currentGameIndex !== newGameIndex || !firstWindowReceived) {
                currentTimestamp = ``
                getFirstWindow(gameId)
                setGameIndex(newGameIndex)
                currentGameIndex = newGameIndex
            }
            getLiveWindow(gameId);
            getLastDetailsFrame(gameId);
        }, 500);

        return () => {
            clearInterval(windowIntervalID);
        }

        function getEventDetails(gameIndex: number) {
            getEventDetailsResponse(matchId).then(response => {
                let eventDetails: EventDetails = response.data.data.event;
                if (eventDetails === undefined) return undefined;
                let newGameIndex = getGameIndex(eventDetails)
                let gameId = eventDetails.match.games[newGameIndex - 1].id
                console.log(`Current Game ID: ${gameId}`)
                console.groupCollapsed(`Event Details`)
                console.groupEnd()
                setEventDetails(eventDetails)
                setGameIndex(gameIndex)
                getFirstWindow(gameId)
                getScheduleEvent(eventDetails)
                getResults(eventDetails)
                matchEventDetails = eventDetails
            })
        }

        function getInitialGameIndex(): number {
            let gameIndexMatch = window.location.href.match(/game-index\/(\d+)/)
            let initialGameIndex = gameIndexMatch ? parseInt(gameIndexMatch[1]) : 0
            console.log(`Initial Game Index: ${initialGameIndex}`)
            setGameIndex(initialGameIndex)
            return initialGameIndex
        }

        function getGameIndex(eventDetails: EventDetails): number {
            let gameIndexMatch = window.location.href.match(/game-index\/(\d+)/)
            let newGameIndex = gameIndexMatch ? parseInt(gameIndexMatch[1]) : getNextUnstartedGameIndex(eventDetails)
            setGameIndex(newGameIndex)
            return newGameIndex
        }

        function getScheduleEvent(eventDetails: EventDetails) {
            getScheduleResponse().then(response => {
                let scheduleEvents: ScheduleEvent[] = response.data.data.schedule.events
                let scheduleEvent = scheduleEvents.find((scheduleEvent: ScheduleEvent) => {
                    return scheduleEvent.match ? (scheduleEvent.match.id === matchId) : false
                })
                if (scheduleEvent === undefined) return
                let records = scheduleEvent.match.teams[0].record && scheduleEvent.match.teams[1].record ? [scheduleEvent.match.teams[0].record, scheduleEvent.match.teams[1].record] : undefined
                if (records === undefined) return

                console.groupCollapsed(`Schedule Event`)
                console.log(scheduleEvent)
                console.groupEnd()
                setRecords(records)
                setScheduleEvent(scheduleEvent);
            }).catch(error =>
                console.error(error)
            )
        }

        function getFirstWindow(gameId: string) {
            getWindowResponse(gameId).then(response => {
                console.log(getWindowResponse(gameId))
                if (response === undefined) return
                let frames: WindowFrame[] = response.data.frames;
                if (frames === undefined) return;

                console.groupCollapsed(`Meta Data`)
                console.log(response.data.gameMetadata)
                console.groupEnd()
                console.groupCollapsed(`First Frame`)
                console.log(frames[0])
                console.groupEnd()
                firstWindowReceived = true
                setFirstWindowFrame(frames[0])
                getitems(response.data.gameMetadata)
            });
        }

        function getLiveWindow(gameId: string) {
            let date = getISODateMultiplyOf10();
            getWindowResponse(gameId, date).then(response => {
                if (response === undefined) return
                let frames: WindowFrame[] = response.data.frames;
                if (frames === undefined) return
                const lastWindowFrame = frames[frames.length - 1]
                if (currentTimestamp > lastWindowFrame.rfc460Timestamp) return;
                currentTimestamp = lastWindowFrame.rfc460Timestamp

                setLastWindowFrame(lastWindowFrame)
                setMetadata(response.data.gameMetadata)

                if (matchEventDetails === undefined) return
                const homeTeam = matchEventDetails.match.teams[0]
                const awayTeam = matchEventDetails.match.teams[1]
                const cleanSweep = matchEventDetails.match.games[currentGameIndex - 1].state === `completed` && (matchEventDetails.match.teams[0].result.gameWins === 0 || matchEventDetails.match.teams[1].result.gameWins === 0)

                const blueTeam = matchEventDetails && matchEventDetails.match.games[currentGameIndex - 1].teams[0].id === homeTeam.id ? homeTeam : awayTeam
                const redTeam = matchEventDetails && matchEventDetails.match.games[currentGameIndex - 1].teams[1].id === homeTeam.id ? homeTeam : awayTeam
                const blueTeamWonMatch = matchEventDetails.match.games.every(game => game.state === `completed` || game.state === `unneeded`) && blueTeam.result.gameWins > redTeam.result.gameWins
                const redTeamWonMatch = matchEventDetails.match.games.every(game => game.state === `completed` || game.state === `unneeded`) && redTeam.result.gameWins > blueTeam.result.gameWins

                const blueTeamWonOnInhibitors = lastWindowFrame.blueTeam.inhibitors > 0 && lastWindowFrame?.redTeam.inhibitors === 0
                const redTeamWonOnInhibitors = lastWindowFrame?.redTeam.inhibitors > 0 && lastWindowFrame?.blueTeam.inhibitors === 0
                const blueTeamWon = matchEventDetails.match.games[currentGameIndex - 1].state === `completed` && (blueTeam.result.outcome === `win` || (cleanSweep && blueTeam.result.gameWins > 0) || blueTeamWonOnInhibitors || (blueTeamWonMatch && (currentGameIndex - 1) === matchEventDetails.match.games.filter(game => game.state === "completed").length))
                const redTeamWon = matchEventDetails.match.games[currentGameIndex - 1].state === `completed` &&  (redTeam.result.outcome === `win` || (cleanSweep && redTeam.result.gameWins > 0) || redTeamWonOnInhibitors || (redTeamWonMatch && (currentGameIndex - 1) === matchEventDetails.match.games.filter(game => game.state === "completed").length))

                const outcome: Array<Outcome> = [
                    {
                        outcome: blueTeamWon ? `win` : redTeamWon ? `loss` : undefined,
                    },
                    {
                        outcome: redTeamWon ? `win` : blueTeamWon ? `loss` : undefined
                    }
                ]
                setCurrentGameOutcome(outcome)
            });
        }

        function getLastDetailsFrame(gameId: string) {
            let date = getISODateMultiplyOf10();
            getGameDetailsResponse(gameId, date, lastFrameSuccess).then(response => {
                lastFrameSuccess = false
                if (response === undefined) return
                let frames: DetailsFrame[] = response.data.frames;
                if (frames === undefined) return;
                lastFrameSuccess = true
                setLastDetailsFrame(frames[frames.length - 1])
            });
        }

        function getResults(eventDetails: EventDetails) {
            if (eventDetails === undefined) return;
            getStandingsResponse(eventDetails.tournament.id).then(response => {
                let standings: Standing[] = response.data.data.standings
                let stage = standings[0].stages.find((stage) => {
                    let stageSection = stage.sections.find((section) => {
                        return section.matches.find((match) => match.id === matchId)
                    })
                    return stageSection
                })
                if (stage === undefined) return;
                let section = stage.sections.find((section) => {
                    return section.matches.find((match) => match.id === matchId)
                })
                if (section === undefined) return;
                let match = section.matches.find((match) => match.id === matchId)
                if (match === undefined) return;
                let teams = match.teams
                let results = teams.map((team) => team.result)
                setResults(results)
                console.groupCollapsed(`Results`)
                console.log(results)
                console.groupEnd()
            });
        }

        function getitems(metadata: GameMetadata) {
            const formattedPatchVersion = getFormattedPatchVersion(metadata.patchVersion)
            getItemsResponse(formattedPatchVersion).then(response => {
                setItems(response.data.data)
            })
        }

        
    }, [matchId]);

    

    if (firstWindowFrame !== undefined && lastWindowFrame !== undefined && lastDetailsFrame !== undefined && metadata !== undefined && eventDetails !== undefined && currentGameOutcome !== undefined && scheduleEvent !== undefined && gameIndex !== undefined && items !== undefined) {
        return (
            <div className='Match-container-test'>
                <GameDetails eventDetails={eventDetails} gameIndex={gameIndex} />
                 <div className={`minimizable-div ${isMinimized ? 'minimized' : ''}`}>
                    <button  onClick={toggleMinimize}>{isMinimized ? 'Expandir Cartão' : 'Minimizar Cartão'}</button>
                    <div className="content">
                    <MatchDetails eventDetails={eventDetails} gameMetadata={metadata} matchState={formatMatchState(eventDetails, lastWindowFrame, scheduleEvent)} records={records} results={results} scheduleEvent={scheduleEvent} />
                    </div>
                     </div>
                    <Game eventDetails={eventDetails} gameIndex={gameIndex} gameMetadata={metadata} firstWindowFrame={firstWindowFrame} lastDetailsFrame={lastDetailsFrame} lastWindowFrame={lastWindowFrame} outcome={currentGameOutcome} records={records} results={results} videoSUB={getStreamOrVod2(eventDetails).footNotes} videoLink={getStreamOrVod2(eventDetails).link} items={items} />
            </div>
        );
    } else if (eventDetails !== undefined) {
        return (
        <div className='Match-container-test'>
            <GameDetails eventDetails={eventDetails} gameIndex={gameIndex} />
            <div className="loading-game-container">
            <div className='tabelas-TeamStats'>
                 <BoxTeamStats TourName={eventDetails.league.name} Team1={eventDetails?.match.teams[0].name} Team2={eventDetails?.match.teams[1].name}  />
                 </div>
                <div className='status-live-game-card'>
                   
                    {eventDetails ? (<h3>{eventDetails?.league.name}</h3>) : null}
                    <div className="live-game-card-content">
                        <div className="live-game-card-team">
                            {eventDetails.match.teams[0].code === "TBD" ? (<TeamTBDSVG className="live-game-card-team-image" />) : (<img className="live-game-card-team-image" src={eventDetails.match.teams[0].image} alt={eventDetails.match.teams[0].name} />)}
                            <span className="live-game-card-title">
                                <span>
                                    <h4>
                                        {eventDetails?.match.teams[0].name}
                                    </h4>
                                </span>
                                {currentGameOutcome ?
                                    (<span className="outcome">
                                        <p className={currentGameOutcome[0].outcome}>
                                            {currentGameOutcome[0].outcome}
                                        </p>
                                    </span>)
                                    : null}
                                {records ?
                                    (<span>
                                        <p>
                                            {records[0].wins} - {records[0].losses}
                                        </p>
                                    </span>)
                                    : null}
                            </span>
                        </div>
                        <div className="game-card-versus">
                            <span>Melhor de {eventDetails.match.strategy.count}</span>
                            {eventDetails.match.teams[0].result && eventDetails.match.teams[1].result ?
                                (<span>
                                    <p>
                                        {eventDetails.match.teams[0].result.gameWins} - {eventDetails.match.teams[1].result.gameWins}
                                    </p>
                                </span>)
                                : null}
                            <h1>VS</h1>
                        </div>
                        <div className="live-game-card-team">
                            {eventDetails.match.teams[1].code === "TBD" ? (<TeamTBDSVG className="live-game-card-team-image" />) : (<img className="live-game-card-team-image" src={eventDetails.match.teams[1].image} alt={eventDetails.match.teams[1].name} />)}
                            <span className="live-game-card-title">
                                <span>
                                    <h4>
                                        {eventDetails?.match.teams[1].name}
                                    </h4>
                                </span>
                                {currentGameOutcome ?
                                    (<span className="outcome">
                                        <p className={currentGameOutcome[1].outcome}>
                                            {currentGameOutcome[1].outcome}
                                        </p>
                                    </span>)
                                    : null}
                                {records ?
                                    (<span>
                                        <p>
                                            {records[1].wins} - {records[1].losses}
                                        </p>
                                    </span>)
                                    : null}
                            </span>
                        </div>
                    </div>
                    <img className="loading-game-image" alt="game loading" src={Loading} />
                    {scheduleEvent && eventDetails ?
                        (<h3>Game {getNextUnstartedGameIndex(eventDetails)} out of {eventDetails.match.strategy.count} will start at {new Date(scheduleEvent.startTime).toLocaleTimeString([], { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: '2-digit' })}</h3>)
                        : null
                    }
                    
                     {videogame(getStreamOrVod2(eventDetails).link,scheduleEvent,eventDetails)}
                </div>
                
            </div>
        </div>

        
        )
    } else {
        return (
            <div className="loading-game-container">
                <div>
                    <img className="loading-game-image" alt="game loading" src={Loading} />
                </div>
            </div>
        )
    }

    
}

function videogame(linkSim: string, scheduleEvent: any,eventDetails:any) {
    const Link = linkSim;

    if (!scheduleEvent || !scheduleEvent.startTime) {
        return <div className="youtube-twitch-video-EX">Evento não encontrado</div>;
    }

    if (eventDetails?.league.name === "CBLOL"||eventDetails?.league.name === "LPL"||eventDetails?.league.name === "LCK") {
        return (
            <div className="youtube-twitch-video-EX">
                <iframe
                    className="Video-conteiner-match-vod-EX"
                    src={`https://player.twitch.tv/?channel=baiano&parent=${Link_Video_URL}`}
                    title={Link.includes("youtube") ? "YouTube Video" : "Twitch Video"}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
        );
    }

    if (Link === "") {
        return (
            <div className="youtube-twitch-video-EX">
                {calcularTempoRestante(scheduleEvent.startTime)}
            </div>
        );
    } else if (Link.includes("youtube") || Link.includes("twitch")) {
        // Se o link contém "youtube" ou "twitch"
        return (
            <div className="youtube-twitch-video-EX">
                <iframe
                    className="Video-conteiner-match-vod-EX"
                    src={Link}
                    title={Link.includes("youtube") ? "YouTube Video" : "Twitch Video"}
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                ></iframe>
            </div>
        );
    } else {
        // Outro tipo de link
        return (
            <div className="youtube-twitch-video-EX">
                <p> </p>
            </div>
        );
    }
}

function calcularTempoRestante(startTime: Date): string {
    const agora = new Date(); // Hora atual
    const inicioEvento = new Date(startTime); // Hora de início do evento
    const diferenca = inicioEvento.getTime() - agora.getTime(); // Diferença em milissegundos

    if (diferenca <= 0) {
        // Se o evento já começou ou está prestes a começar
        return "O evento começou ou está prestes a começar.";
    }

    // Convertendo a diferença de milissegundos para horas, minutos e segundos
    const horasRestantes = Math.floor(diferenca / (1000 * 60 * 60));
    const minutosRestantes = Math.floor((diferenca % (1000 * 60 * 60)) / (1000 * 60));
    const segundosRestantes = Math.floor((diferenca % (1000 * 60)) / 1000);

    // Formatando a string de retorno
    return `A transmissão começa em ${horasRestantes} horas, ${minutosRestantes} minutos e ${segundosRestantes} segundos.`;
}





function getNextUnstartedGameIndex(eventDetails: EventDetails) {
    let lastCompletedGame = eventDetails.match.games.slice().reverse().find(game => game.state === "completed")
    let nextUnstartedGame = eventDetails.match.games.find(game => game.state === "unstarted" || game.state === "inProgress")
    return nextUnstartedGame ? nextUnstartedGame.number : (lastCompletedGame ? lastCompletedGame.number : eventDetails.match.games.length)
}

function getStreamOrVod(eventDetails: EventDetails) {
    let vods = eventDetails.match.games[getNextUnstartedGameIndex(eventDetails) - 1].vods
    if (vods.length) {
        return (<span className="footer-notes"><a href={getExtendedVodLink(vods[0])} target="_blank" rel="noreferrer">VOD Link</a></span>)
    }

    if (!eventDetails.streams || !eventDetails.streams.length) {
        return (<span>Sem Transmissão ao Vivo / Vods  </span>)
    }
    let shortestDelayStream = eventDetails.streams.reduce((previousVod, currentVod) => currentVod.offset < 0 && currentVod.offset > previousVod.offset ? currentVod : previousVod, eventDetails.streams[0])

    let streamOffset = Math.round(shortestDelayStream.offset / 1000 / 60 * -1)
    let link = shortestDelayStream.provider === "youtube" ? `https://www.youtube.com/watch?v=${shortestDelayStream.parameter}` : `https://www.twitch.tv/${shortestDelayStream.parameter}`
    let delayString = streamOffset > 1 ? `Approximately ${streamOffset} minutes` : `Less than 1 minute`
    return (<span className="footer-notes">Stream Delay: {delayString} - <a href={link} target="_blank" rel="noreferrer">Watch Stream</a></span>)
}

function getStreamOrVod2(eventDetails: EventDetails): { footNotes: JSX.Element , link: string} {
    let vods = eventDetails.match.games[getNextUnstartedGameIndex(eventDetails) - 1].vods
    if (vods.length) {
        return { footNotes:<span className="footer-notes"><a href={getExtendedVodLink(vods[0])} target="_blank" rel="noreferrer">VOD Link</a></span>, link: getExtendedVodLink2(vods[0]) };
    }

    if (!eventDetails.streams || !eventDetails.streams.length) {
        return { footNotes:<span>Sem Transmissão ao Vivo / Vods </span>, link:"" };
    }
    let shortestDelayStream = eventDetails.streams.reduce((previousVod, currentVod) => currentVod.offset < 0 && currentVod.offset > previousVod.offset ? currentVod : previousVod, eventDetails.streams[0])

    let streamOffset = Math.round(shortestDelayStream.offset / 1000 / 60 * -1) 
    let link2 = shortestDelayStream.provider === "youtube" ? `https://www.youtube.com/watch?v=${shortestDelayStream.parameter}` : `https://www.twitch.tv/${shortestDelayStream.parameter}`
    let link = shortestDelayStream.provider === "youtube" ? `https://www.youtube.com/embed/${shortestDelayStream.parameter}` : `https://player.twitch.tv/?channel=${shortestDelayStream.parameter}&parent=${Link_Video_URL}`
    let delayString = streamOffset > 1 ? `Aproximadamente ${streamOffset} minutos` : `Menos de um Minuto`
    return { footNotes: <span className="footer-notes">Atraso da Stream : {delayString} - <a href={link2} target="_blank" rel="noreferrer">Assista Aqui</a></span>, link}
}

function getExtendedVodLink(extendedVod: ExtendedVod) {
    return extendedVod.provider === "youtube" ? `https://www.youtube.com/watch?v=${extendedVod.parameter}` : `https://www.twitch.tv/${extendedVod.parameter}`
}

function getExtendedVodLink2(extendedVod: any) {
    return extendedVod.provider === "youtube" ? `https://www.youtube.com/embed/${extendedVod.parameter}` : `https://player.twitch.tv/?channel=${extendedVod.parameter}`
}

function formatMatchState(eventDetails: EventDetails, lastWindowFrame: WindowFrame, scheduleEvent: ScheduleEvent): string {
    let gameStates = {
        "in_game": "Em jogo",
        "paused": "Pausado",
        "finished": "Acabou",
        "completed": localize('Completedtext'),
        "unstarted": "Ainda não Começou",
        "inProgress": "Em jogo"
    }

    if (eventDetails.match.games.length === 1) return gameStates[lastWindowFrame.gameState]
    let gamesFinished = eventDetails.match.games.filter(game => game.state === `completed` || game.state === `unneeded`)
    return gameStates[gamesFinished.length >= eventDetails.match.games.length ? `completed` : scheduleEvent.state]
}