import React, { useState, useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { getFirestore, doc, setDoc, getDoc } from "firebase/firestore"; 
import { useAuth } from '../../utils/AuthContext';
import './styles/login.css';
import { getErrorMessage } from './../../utils/errorMessages';
import { Link_App } from '../../utils/LoLEsportsAPI';
import axios from 'axios';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [logoutMessage, setLogoutMessage] = useState('');
  const [redirect, setRedirect] = useState(false);
  const auth = getAuth();
  const provider = new GoogleAuthProvider();
  const db = getFirestore();
  const { userLoggedIn } = useAuth();

  useEffect(() => {
    document.title = "Login - Análise das Lendas";

    const message = localStorage.getItem('logoutMessage');
    if (message) {
      setLogoutMessage(message);
      localStorage.removeItem('logoutMessage');
      setTimeout(() => {
        setLogoutMessage('');
      }, 5000);
    }
  }, []);

  const googleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, provider);
      const idToken = await result.user.getIdToken();
  
      const response = await axios.post(`${Link_App}/google_signin`, { idToken });
      
      if (response.data.status === 'success') {
        setRedirect(true);
      } else {
        throw new Error(response.data.message);
      }
    } catch (error:any) {
      const errorMessage = getErrorMessage(error.code);
      setError(errorMessage);
    }
  };

  const handleLogin = async () => {
    if (!validateEmail(email)) {
      setError("Por favor, insira um endereço de email válido.");
      return;
    }

    try {
      await signInWithEmailAndPassword(auth, email, password);
      setRedirect(true);
    } catch (error: any) {
      const errorMessage = getErrorMessage(error.code);
      setError(errorMessage);
    }
  };

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  if (redirect || userLoggedIn) {
    return <Navigate to="/inicio" />;
  }

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Login</h2>
        {logoutMessage && <p className="logout-message">{logoutMessage}</p>}
        {error && <p className="error-message">{error}</p>}
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Senha"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={handleLogin}>Login</button>
        <button className="google-login" onClick={googleSignIn}>Login com Google</button>
        <Link to="/Cadastro" className="register-link">Criar Conta</Link>
        <Link to="/forgot-password">Esqueci a Senha</Link>
      </div>
    </div>
  );
}

export default Login;
