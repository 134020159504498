import React from 'react';
import { useBet } from './BetContext';
import { formatNumber } from '../../utils/formatNumber';
import './styles/betList.css';
import { useAuth } from './../../utils/AuthContext';
import {  useNavigate } from 'react-router-dom';

const BetList = () => {
  const { bets } = useBet();
  const navigate = useNavigate();
  const {user} = useAuth();

  const handleLoginRedirect = () => {
    navigate('/login');
};

  const formatDate = (isoDate:any) => {
    const date = new Date(isoDate);
    const formattedDate = `${date.getFullYear()}-${padNumber(date.getMonth() + 1)}-${padNumber(date.getDate())}`;
    const formattedTime = `${padNumber(date.getHours())}:${padNumber(date.getMinutes())}:${padNumber(date.getSeconds())}`;
    return ` ${formattedDate} às ${formattedTime}`;
  };

  const padNumber = (num:any) => {
    return num.toString().padStart(2, '0');
  };

  // Ordenar as apostas por data (mais recente primeiro)
  const sortedBets = [...bets].sort((a, b) => {
    const dateA = b.bet_time ? new Date(b.bet_time).getTime() : 0;
    const dateB = a.bet_time ? new Date(a.bet_time).getTime() : 0;
    return dateA - dateB;
  });

  if (!user){
    return (
      <div className="bet-list-container">
        Faça Login Para ver Suas Apostas 
    <button className="bet-bet-button" onClick={handleLoginRedirect} > Login</button>
    </div>
  );
  }
  

 

  return (
    <div className="bet-list-container">
      <div className="bet-list-header">
        <h2 className="bet-list-title">Minhas Apostas</h2>
      </div>
      <ul className="bet-list">
        {sortedBets.map((bet, index) => (
          <li key={index} className={`bet-list-item-${bet.outcome}`}>
            <div className='bet-list-card-header'>
              <p>id: {bet.match_id}</p>
              <p>{bet.bet_time ? formatDate(bet.bet_time) : ''}</p> 
            </div>
            <p className='bet-list-outcome'>Resultado: <p className={bet.outcome}> {bet.outcome || 'Pendente'}</p></p>
            <div className='bet-list-card-middle'>
              <p className={`bet-list-card-middle-title-${bet.outcome}`}>{bet.prediction} <img className='bet-img' src={bet.bet_img}></img></p>
              <p>Escolha Vencedor do Match</p>
              
            </div>
            <div className='bet-list-card-bottom'>
              <p className='bet-list-p'>Aposta: <p className='bet-list-points'>AP$:{formatNumber(bet.amount)}</p> </p>
              <p className='bet-list-p'>Ganhos: <p className='bet-list-points'>AP$:{formatNumber(bet.amount)}</p></p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default BetList;
