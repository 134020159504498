export const selectitembox = (ligaSelecionada:any,handleLigaChange:any,splitSelecionado:any,handleSplitChange:any,tipoJogoSelecionado:any,handleTipoJogoChange:any) =>{

    return(
        <div className="select-box">
            <div className="select-item">
                <label htmlFor="ligaSelect">Liga:</label>
                <select id="ligaSelect" value={ligaSelecionada} onChange={handleLigaChange}>
                    <option value="CBLOL">CBLOL</option>
                    <option value="WORLDS">WORLDS</option>
                    <option value="MSI">MSI</option>
                    <option value="CBLOL Academy">CBLOL Academy</option>
                    <option value="LCK">LCK</option>
                    <option value="LPL">LPL </option>
                    <option value="LPL Summer Placements">LPL Summer Placements</option>
                    <option value="LEC">LEC</option>
                    <option value="LCS">LCS</option>
                    <option value="LLA">LLA</option>
                    <option value="VCS">VCS</option>
                    <option value="TCL">TCL</option>
                    <option value="PCS">PCS</option>
                    <option value="LJL">LJL</option>
                    <option value="LCO">LCO</option>
                </select>
            </div>
            <div className="select-item">
                <label htmlFor="splitSelect">Split:</label>
                <select id="splitSelect" value={splitSelecionado} onChange={handleSplitChange}>
                    <option value="2024-1">2024-1</option>
                    <option value="2024-2">2024-2</option>
                </select>
            </div>
            <div className="select-item">
                <label htmlFor="tipoJogoSelect">Tipo de Jogo:</label>
                <select id="tipoJogoSelect" value={tipoJogoSelecionado} onChange={handleTipoJogoChange}>
                    <option value="">Regular</option>
                    <option value="Playoffs">Playoffs</option>
                </select>
            </div>
        </div>
    )
}

export const buildTournamentName = (ligaSelecionada:any, splitSelecionado:any, tipoJogoSelecionado:any) => {
    const year = splitSelecionado.split('-')[0]; // Extrai o ano do splitSelecionado
    const split = splitSelecionado.split('-')[1]; // Extrai o split do splitSelecionado

    let tournamentName = '';

    switch (ligaSelecionada) {
        case 'MSI':
            tournamentName = `MSI ${year}`;
            break;
        case 'LPL Summer Placements':
            tournamentName = `LPL ${year} Summer Placements`;
        break;
        case 'WORLDS':
            tournamentName = `WORLDS ${year}`;
            break;
        case 'CBLOL':
        case 'CBLOL Academy':
        case 'LCO':
            tournamentName = `${ligaSelecionada} ${year} Split ${split} ${tipoJogoSelecionado}`;
            break;
        case 'PCS':
        case 'LJL':
        case 'VCS':
        case 'LCS':
        case 'LEC':
        case 'LPL':
            tournamentName = `LPL ${year} Summer Placements`;
        break;
        case 'LCK':
            tournamentName = `${ligaSelecionada} ${year} ${split === '1' ? 'Spring' : 'Summer'} ${tipoJogoSelecionado}`;
            break;
        case 'TCL':
            tournamentName = `TCL ${year} ${split === '1' ? 'Winter' : 'Summer'} ${tipoJogoSelecionado}`;
            break;
        case 'LLA':
            tournamentName = `LLA ${year} Opening ${tipoJogoSelecionado}`;
            break;
        default:
            tournamentName = `${ligaSelecionada} ${splitSelecionado} ${tipoJogoSelecionado}`;
            break;
    }

    return tournamentName;
};