import React, { useState , useEffect } from "react";
import { localize, changeLanguage } from '../../styles/GlobalStrings';
import { DetailsFrame, EventDetails, GameMetadata, Item, Outcome, Participant, Record, Result, TeamStats, WindowFrame, WindowParticipant } from "../types/baseTypes.js";
import championNames from '../Campeoes/championNames';
import jogadoresNames from '../Jogadores/jogadoresNames';
import { CHAMPIONS_URL, Link_App, getFormattedPatchVersion } from '../../utils/LoLEsportsAPI';
import ChampsResIndiv from './ChampsResIndiv';


export function BoxPlayersData(players: WindowParticipant[], side: string, gameMetadata: GameMetadata, frame: WindowFrame, championsUrlWithPatchVersion: string, TeamData: any) {
    const teamMetadata = side === 'blue' ? gameMetadata.blueTeamMetadata.participantMetadata : gameMetadata.redTeamMetadata.participantMetadata;


    return (
        <div className='Org-box'>
        <div className="team-info-container-db">
            <table className="team-header-db">
                <thead>
                    <tr >
                        <th className={`${side}`} >{TeamData.name}</th>
                        <th className={`${side}`} data-tooltip={localize('GTtext')} >G</th>
                        <th className={`${side}`}  data-tooltip={localize('WRtext')} >WR</th>
                        <th className={`${side}`} data-tooltip={localize('KDAtext')} >KDA</th>
                        <th className={`${side}`}  data-tooltip={localize('CStext')} >CS</th>
                        <th className={`${side}`}  data-tooltip={localize('DMGtext')} >DMG</th>
                        <th className={`${side}`}  data-tooltip={localize('TMtext')} >T/M</th>
                    </tr>
                </thead>
                <tbody>
                    {players.map((player) => {
                        const metadata = teamMetadata.find(meta => meta.participantId === player.participantId);
                        if (!metadata) return null;
                        return (
                            <React.Fragment key={`${metadata.championId}_${metadata.summonerName}`}>
                                <PlayerDetails
                                    player={player}
                                    metadata={metadata}
                                    UrlChamp={championsUrlWithPatchVersion}
                                />
                            </React.Fragment>
                        );
                    })}
                </tbody>
            </table>
        </div>
        </div>
    );
}

function PlayerDetails({ player, metadata, UrlChamp }: { player: WindowParticipant, metadata: any, UrlChamp: string }) {
    const summonerName = metadata.summonerName;
    let summonerNameLastWord: string = summonerName;
    if (/\s/.test(summonerName)) {
        const summonerNameWords = summonerName.split(' ');
        summonerNameLastWord = summonerNameWords[summonerNameWords.length - 1];
    } else {
        const prefixToRemove = ['PSG','T1','EST','PSG','FLY','LLL','FNC','G2','TL','GEN','GAM','RNG','WE','TES','RA','LNG','OMG','WBG','IG','BLG','JDG','NIP','FPX','AL','LGD','TT','EDG','UP']; // Lista de prefixos para remover
        const minimumPrefixLength = 2; // Comprimento mínimo do prefixo a ser removido
    
        for (const prefix of prefixToRemove) {
            if (summonerName.startsWith(prefix) && summonerName.length > prefix.length + minimumPrefixLength) {
                summonerNameLastWord = summonerName.slice(prefix.length); // Remove o prefixo
                break;
            }
        }
    }
    
    // Se nenhum prefixo aplicável for encontrado ou se o nome não tiver espaço, mantenha o nome original
    summonerNameLastWord = summonerNameLastWord || summonerName;
    const summonerNameCerto = jogadoresNames[summonerNameLastWord as keyof typeof jogadoresNames] || summonerNameLastWord;
    const championName = metadata.championId;
    const championCerto = championNames[championName as keyof typeof championNames] || championName;

    const [error, setError] = useState<string | null>(null);
    const [okteste, okset] = useState<any[]>([]);
    const [campeoesT, setCampeoesT] = useState<any[]>([]);
    
    useEffect(() => {
        
            const fetchData = async () => {
                try {
                    const data24P = await fetch_champs_Indiv(summonerNameCerto, championCerto);
                    setCampeoesT(data24P); // Se data24P for undefined, define um array vazio
                    console.log(data24P)
                    setError(null); // Redefine o estado de erro se a solicitação for bem-sucedida
                } catch (error:any) {
                    console.error("An error occurred:", error);
                    setCampeoesT(okteste)
                    setError(null); // Define o estado de erro se ocorrer um erro na solicitação
                }
            };
  
        fetchData();
    }, [summonerNameCerto, championCerto]);


    const fetch_champs_Indiv = async (playerName: string,ChampName:string) => {
        try {
            const response = await fetch(`${Link_App}/get_player_champs_Indiv?player_name=${playerName}&champ_name=${ChampName}`);
          if (!response.ok) {
            return okteste;
          }
          const data = await response.json(); // Expecting an array of champions
          return(data);

        } catch (error: any) {
          /* setError(error.message);*/
          console.error("An error occurred:", error);
          return okteste;
        }
      };   


    return (
        <>
             <ChampsResIndiv  data={campeoesT}  summonerName={metadata.summonerName} championId={metadata.championId} UrlChamp={UrlChamp}/>           
        </>
    );
}