// src/components/DetailsSection.tsx
import React from 'react';
import { DetailBox } from './DetailBox';
import img01 from '../../assets/img/TESTE1.png';
import img02 from '../../assets/img/teste3.png';
import img03 from '../../assets/img/teste4.png';
import img04 from '../../assets/img/teste6.png';
import img05 from '../../assets/img/teste5.png';

import './styles/DetailsSection.css';

export function DetailsSection() {
  return (
    <>
    <section className="details-section">   
    <DetailBox img={img03} title="Análises ao Vivo" content="Obtenha análises em tempo real dos jogos em andamento." />
    <DetailBox img={img02} title="Estatísticas Fresquinhas" content="Todos os Dados são 100% atualizados pela api do League of legends e pelo LeaguePedia. Ou seja são 99% aualizados e corretos." />
      <DetailBox img={img05} title="Sobre o Site" content="Nosso site oferece análises detalhadas e dados ao vivo dos jogos. Veja informações atualizadas sobre jogadores e campeões, e fique à frente com dados de jogos ao vivo que chegam antes do vídeo!" />
      <DetailBox img={img04} title="Jogos ao Vivo" content="Veja dados ao vivo dos jogos e fique à frente com informações atualizadas." />
      <DetailBox img={img01} title="Estatísticas de Campeões" content="Dados detalhados sobre os campeões e jogadores em todas as seasons." />
    </section>
    </>
  );
}
