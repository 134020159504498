import React, { useRef, useEffect, useState } from 'react';
import './styles/MatchStyles.css';
import {  Link_Video_URL } from '../../utils/LoLEsportsAPI';

function DraggableVideo({ videoLink }) {
    const videoRef = useRef(null);
    const [size, setSize] = useState({ width: 620, height: 378 });
    const [activeTab, setActiveTab] = useState(1);
    const [activeSize, setActiveSize] = useState('360p');

    const handleTabChange = (tabNumber) => {
        setActiveTab(tabNumber);
    };

    const handleSetSize = (width, height, sizeName) => {
        setSize({ width, height });
        setActiveSize(sizeName);
    };

    useEffect(() => {
        const videoElement = videoRef.current;
        let isDragging = false;
        let deltaX = 0;
        let deltaY = 0;

        const onMouseMove = (event) => {
            if (isDragging) {
                videoElement.style.left = `${event.clientX - deltaX}px`;
                videoElement.style.top = `${event.clientY - deltaY}px`;
            }
        };

        const onMouseDown = (event) => {
            isDragging = true;
            deltaX = event.clientX - videoElement.getBoundingClientRect().left;
            deltaY = event.clientY - videoElement.getBoundingClientRect().top;
            document.addEventListener('mousemove', onMouseMove);
        };

        const onMouseUp = () => {
            isDragging = false;
            document.removeEventListener('mousemove', onMouseMove);
        };

        videoElement.addEventListener('mousedown', onMouseDown);
        window.addEventListener('mouseup', onMouseUp);

        return () => {
            videoElement.removeEventListener('mousedown', onMouseDown);
            window.removeEventListener('mouseup', onMouseUp);
            document.removeEventListener('mousemove', onMouseMove);
        };
    }, []);

    return (
        <div className="draggable-video" ref={videoRef}>
            <div>
                {/* Abas */}
                <div className="video-tab-list-dd">
                    <button className={`tab-button ${activeTab === 1 ? 'active-tab' : 'tab'}`} onClick={() => handleTabChange(1)}>
                        Nativo
                    </button>
                    <button className={`tab-button ${activeTab === 2 ? 'active-tab' : 'tab'}`} onClick={() => handleTabChange(2)}>
                        Baiano
                    </button>
                    <button className={`tab-button ${activeTab === 3 ? 'active-tab' : 'tab'}`} onClick={() => handleTabChange(3)}>
                        Outro Link
                    </button>
                    <button className={`size-button ${activeSize === '360p' ? 'active-size' : ''}`} onClick={() => handleSetSize(620, 378, '360p')}>Menor</button>
                    <button className={`size-button ${activeSize === '720p' ? 'active-size' : ''}`} onClick={() => handleSetSize(920, 530, '720p')}>Medio</button>
                    <button className={`size-button ${activeSize === '1080p' ? 'active-size' : ''}`} onClick={() => handleSetSize(1120, 640, '1080p')}>Maior</button>
                </div>

                {/* Conteúdo das Abas */}
                <div className="tab-content-dd">
                    {/* Primeira aba: Video Link */}
                    {activeTab === 1 && (
                        <div className="youtube-twitch-video">
                            <iframe
                                className="Video-conteiner-match-vod"
                                style={{ width: size.width, height: size.height }}
                                src={videoLink}
                                title="YouTube Video"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; FullScreen"
                                allowFullScreen
                            ></iframe>
                        </div>
                    )}

                    {/* Segunda aba: Internal List (Placeholder, substitua pelo conteúdo real) */}
                    {activeTab === 2 && (
                        <div className="youtube-twitch-video">
                            <iframe
                                className="Video-conteiner-match-vod"
                                style={{ width: size.width, height: size.height }}
                                src={`https://player.twitch.tv/?channel=baiano&parent=${Link_Video_URL}`}
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; FullScreen"
                                allowFullScreen
                            ></iframe>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default DraggableVideo;
