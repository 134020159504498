const TimesNames = {
    'Flamengo MDL': "Flamengo Academy",
    'CBLOL Academy': "CBLOL Academy 2024 Split 2",

    LPL: "LPL 2024 Summer Placements",
    LLA: "LLA 2024 Closing",
    Renata: "Renata Glasc",
    XinZhao: "Xin Zhao",
    KogMaw: "Kog'Maw",
    Velkoz: "Vel'Koz",
    Khazix: "Kha'Zix",
    MasterYi: "Master Yi",
    LeeSin: "Lee Sin",
    Kaisa: "Kai'Sa",
    AurelionSol: "Aurelion Sol",
    JarvanIV: "Jarvan IV",
    DrMundo: "Dr. Mundo",
    TahmKench: "Tahm Kench",
    TwistedFate: "Twisted Fate",
    RekSai: "Rek'Sai",
    RenataGlasc: "Renata Glasc"
};

export default TimesNames;