const languages = {
    pt: {
      IAheadertext: "Essas probabilidades são feitas por uma IA , entenda que ela usa o sistema de machine learning então quanto mais tempo passar a tendencia é ficar melhor, ela ainda é um bebê tenha paciencia e faça suas analise com sabedoria.",
      GTtext: "Jogos Total na carreira",
      EStext: "Estatisticas do Campeão no Torneio",
      Rotatext: "Lane Jogada",
      WRtext: "Taxa de Vitória com o Campeão",
      Wintext: "Vitórias com o Campeão",
      Dertext: "Derrotas com o Campeão",
      Priotext: "Prioridade Pick ou Ban",
      KDAtext: "KDA Medio com o Campeão",
      CStext: "Farm Medio com o Campeão",
      DMGtext: "Dano Medio com o Campeão",
      TMtext: "Tempo Médio das Partidas com o Campeão",
      Inibitext: "Inibididores Derrubados na Partida",
      Barontext: "Barões do time Mortos na Partida",
      Towertext: "Torres do time Derrubados na Partida",
      Goldtext: "Ouro do time na Partida",
      KillPtext: "Kills do time na Partida",
      Picktext: "Picks do Campeão no Torneio",
      Bantext: "Bans do Campeão no Torneio",
      Killtext: ">Kills do Campeão no Torneio",
      Deathtext: "Mortes do Campeão no Torneio",
      Assisttext: "Assists do Campeão no Torneio",
      DpMtext: "Dano por Minuto do Campeão no Torneio",
      CspMtext: "CS por Minuto do Campeão no Torneio",
      GpMtext: "ouro por Minuto do Campeão no Torneio",
      Segundostext: "Segundos",
      Minutostext: "Minutos",
      AoVivotext: "Ao Vivo",
      JogosFuturostext: "Jogos Futuros",
      Historicotext:"Histórico",
      Bestoftext:"Melhor de ",
      Completedtext:"Acabou",
      Unstartedtext:"Não Começou",
      InProgresstext:"Ao vivo",
      Unneededtext:"Não Precisou",
      about: "About Us",
      contact: "Contact Us",
    },
    en: {
      about: "Sobre nosotros",
      contact: "Contáctenos",
    },
  };
  
  // Definição do idioma padrão
  let currentLanguage = 'pt';
  
  // Função para mudar o idioma
  function changeLanguage(newLanguage) {
    currentLanguage = newLanguage;
  }
  
  // Função para acessar as variáveis globais do idioma atual
  function localize(key) {
    return languages[currentLanguage][key] || key; // Retorna a chave diretamente se a tradução não existir
  }
  
  export { changeLanguage, localize };