import  { useEffect, useRef, useState } from 'react';
import { GameCard } from './GameLiveCard';
import './styles/GamesLiveCarousel.css';
import { getScheduleResponse } from "../../utils/LoLEsportsAPI";
import { ScheduleEvent } from "../types/baseTypes";

export function GamesLiveCarousel() {
    const carouselRef = useRef<HTMLDivElement | null>(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [liveEvents, setLiveEvents] = useState<ScheduleEvent[]>([]);
    const [hidden, setHidden] = useState(false);
    const [expor, setexpor] = useState(false);
    

    const fetchLiveEvents = () => {
        getScheduleResponse()
          .then(response => {
            const schedule = response.data.data.schedule;
            setLiveEvents(schedule.events.filter(filterLiveEvents));
          })
          .catch(error => console.error(error));
      };

      useEffect(() => {
        if(liveEvents.length === 0){
            setHidden(true);
            setexpor(true);
        }
    }, []);


    useEffect(() => {
        fetchLiveEvents();
        const interval = setInterval(fetchLiveEvents, 60000);
        return () => clearInterval(interval);
    }, []);

    useEffect(() => {
        const carousel = carouselRef.current;

        const autoScroll = () => {
            if (carousel && !hidden) {
                // Calcula a nova posição de rolagem
                const newScrollLeft = (carousel.scrollWidth / liveEvents.length) * currentIndex;

                // Se o índice atual é o último, redefine para o primeiro
                if (currentIndex >= liveEvents.length - 1) {
                    setCurrentIndex(0);
                    carousel.scrollLeft = 0;
                } else {
                    setCurrentIndex((prevIndex) => prevIndex + 1);
                    carousel.scrollLeft = newScrollLeft;
                }
            }
        };

        const interval = setInterval(autoScroll, 2000); // Ajuste o tempo conforme necessário
        return () => clearInterval(interval);
    }, [currentIndex, liveEvents.length, hidden]);

    const toggleHidden = () => {
      setHidden(!hidden);
      setexpor(!expor);
  };

    return (
      <section className={`games-carousel-mini ${expor ? 'expor' : ''}`}>
          <div className="games-mini-hidden" onClick={toggleHidden}>
              {hidden ? 'Mostrar Jogos Ao vivo' : 'Esconder Jogos Ao vivo'}
              </div>
            <div className={`carousel-container-mini ${hidden ? 'hidden' : ''}`} ref={carouselRef}>
                {liveEvents.length === 0 ? (
                    <div className="no-games-card">Nenhum jogo ao vivo</div>
                ) : (
                    liveEvents.map(scheduleEvent => {
                        return scheduleEvent.league.slug !== "tft_esports" ? (
                            <GameCard
                                key={`${scheduleEvent.match.id}_${scheduleEvent.startTime}`}
                                scheduleEvent={scheduleEvent}
                            />
                        ) : null;
                    })
                )}
            </div>
      </section>
  );
}
function filterLiveEvents(scheduleEvent: ScheduleEvent) {
    return scheduleEvent.match !== undefined && scheduleEvent.state === "inProgress";
}


function filterByLast7Days(scheduleEvent: ScheduleEvent) {
    if (scheduleEvent.state !== 'completed') {
        return false
    }
    let minDate = new Date();
    let maxDate = new Date()
    minDate.setDate(minDate.getDate() - 7)
    maxDate.setHours(maxDate.getHours() - 1)
    let eventDate = new Date(scheduleEvent.startTime)

    if(eventDate.valueOf() > minDate.valueOf() && eventDate.valueOf() < maxDate.valueOf()){

        if(scheduleEvent.match === undefined) return false
        if(scheduleEvent.match.id === undefined) return false

        return true;
    }else{
        return false;
    }
}