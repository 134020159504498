import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth } from './../../utils/AuthContext';
import { Link_App } from '../../utils/LoLEsportsAPI';
import './styles/betMissoes.css';
import { useNavigate } from 'react-router-dom';
import AlertBox from './../Geral/AlertBox';

interface Mission {
  id: string;
  title: string;
  description: string;
  points: number;
  completed: boolean;
}

const BetMissoes = () => {
  const { user} = useAuth();
  const [missions, setMissions] = useState<Mission[]>([]);
  const navigate = useNavigate();
  const [alertVisible, setAlertVisible] = useState(false);
  const [loading, setLoading] = useState(false); // novo estado
  const [alertMessage, setAlertMessage] = useState('');

  const showAlert = (message:any) => {
    setAlertMessage(message);
    setAlertVisible(true);
  };

  const closeAlert = () => {
    setAlertVisible(false);
    setAlertMessage('');
  };

  const handleLoginRedirect = () => {
    navigate('/login');
};

  useEffect(() => {
    fetchMissions();
  }, []);

  useEffect(() => {
    fetchMissions();
  }, []);

  const fetchMissions = async () => {
    try {
      const response = await axios.get(`${Link_App}/get_missions`, {
        params: { user_id: user.uid }
      });
      if (response.data.success) {
        setMissions(response.data.missions);
      }
    } catch (error) {
      console.error('Failed to fetch missions:', error);
    }
  };

  const completeMission = async (missionId:any) => {
    try {
      const response = await axios.post(`${Link_App}/complete_mission`, {
        user_id: user.uid,
        mission_id: missionId
      });

      if (response.data.success) {
        showAlert(response.data.message);
        fetchMissions();
      } else {
        showAlert(response.data.message);
      }
    } catch (error) {
      console.error('Failed to complete mission:', error);
    }
  };

  if (!user){
    return (
      <div className="bet-missoes-container">
        Faça Login Para ver Suas Missoes 
    <button className="bet-bet-button" onClick={handleLoginRedirect} > Login</button>
    </div>
  );
  }
  

  return (
    <div className="bet-missoes-container">
      <h2>Missões</h2>
      <div className="missions-list">
        {missions.map(mission => (
          <div key={mission.id} className={`mission-card ${mission.completed ? 'completed' : ''}`}>
            <h3>{mission.title}</h3>
            <p>{mission.description}</p>
            <p>AP$: {mission.points}</p>
            <button onClick={() => completeMission(mission.id)} disabled={mission.completed}>
              {mission.completed ? 'Completada' : 'Completar'}
            </button>
          </div>
        ))}
      </div>
      {alertVisible && <AlertBox message={alertMessage} onClose={closeAlert} />} {/* exibir alerta com a mensagem */}
    </div>
  );
};

export default BetMissoes;