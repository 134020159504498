// src/components/DetailBox.tsx
import React from 'react';
import ImageLightbox from '../Geral/ImageLightbox';
import './styles/DetailBox.css';


interface DetailBoxProps {
  img:any;
  title: string;
  content: string;
}

export function DetailBox({img, title, content }: DetailBoxProps) {
  return (
    <div className="detail-box">
      <ImageLightbox image={img}/>
      <h2>{title}</h2>
      <p>{content}</p>
    </div>
  );
}
