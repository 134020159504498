import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useAuth } from './../../utils/AuthContext';

// Criando a interface para o contexto
interface PremiumContextType {
    isPremium: boolean;
    setPremium: (isPremium: boolean) => void;
    stripePremium: boolean;
    endPremium: string;
}

// Criando o contexto com um valor padrão
const PremiumContext = createContext<PremiumContextType | undefined>(undefined);

// Provider componente
interface PremiumProviderProps {
    children: ReactNode;
}

export const PremiumProvider: React.FC<PremiumProviderProps> = ({ children }) => {
    const [isPremium, setIsPremium] = useState<boolean>(false);
    const [stripePremium, setStripePremium] = useState<boolean>(false);
    const [endPremium, setEndPremium] = useState<string>("");
    const { userProfile } = useAuth();

    useEffect(() => {
        const getstate = async () => {
            if (userProfile) {
                if (userProfile.ativo) {
                    setIsPremium(true);
                    setEndPremium(userProfile.end);
                    setStripePremium(userProfile.stripe);
                } else {
                    setIsPremium(false);
                }
            } else {
                setIsPremium(false);
            }
        };

        getstate();
    }, [userProfile]);

    useEffect(() => {
        const scriptId = "google-ads-script";

        if (!isPremium) {
            if (!document.getElementById(scriptId)) {
                const script = document.createElement('script');
                script.id = scriptId;
                script.async = true;
                script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-9658740515720190";
                script.crossOrigin = "anonymous";
                document.head.appendChild(script);
            }
        } else {
            const existingScript = document.getElementById(scriptId);
            if (existingScript) {
                document.head.removeChild(existingScript);
            }
        }
    }, [isPremium]);

    const setPremium = (newState: boolean) => {
        setIsPremium(newState);
    };

    return (
        <PremiumContext.Provider value={{ isPremium, setPremium, stripePremium, endPremium }}>
            {children}
        </PremiumContext.Provider>
    );
};

// Hook personalizado para usar o contexto
export const usePremium = () => {
    const context = useContext(PremiumContext);
    if (context === undefined) {
        throw new Error('usePremium must be used within a PremiumProvider');
    }
    return context;
};
