import React from 'react';
import './styles/alertBox.css';

interface AlertBoxProps {
  message: string;
  onClose: () => void;
}

const AlertBox: React.FC<AlertBoxProps> = ({ message, onClose }) => {
  return (
    <div className="alert-box-overlay">
      <div className="alert-box">
        <p>{message}</p>
        <button onClick={onClose}>Fechar</button>
      </div>
    </div>
  );
};

export default AlertBox;
