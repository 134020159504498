import React from "react";
import './styles/footerStyle.css'

import { ReactComponent as DiscordSVG } from '../../assets/images/discord.svg';
import { ReactComponent as GitHubLogoSVG } from '../../assets/images/github.svg';
import { ReactComponent as TwitterSVG } from '../../assets/images/twitter.svg';
import { ReactComponent as LinkedInSVG } from '../../assets/images/linkedin.svg';
import { ReactComponent as Telegram } from '../../assets/images/telegram.svg';

export function Footer() {

    return (
        <nav className="footer-container">
            
            <a target="_blank" rel="noreferrer" href="https://twitter.com/DDdesenvol23660">
                <TwitterSVG className="footer-img" />
            </a>
            <a target="_blank" rel="noreferrer" href="https://t.me/AnaliseLOL">
                <Telegram className="footer-img" />
            </a>
           
        </nav>
    );
}