import React, { useEffect, useState } from 'react';
import {BoxTabelaData} from '../Boxes/BoxTabelaData'
import TeamRes from './TeamRes';
import './styles/boxTeamStats.css'
import { Link_App} from '../../utils/LoLEsportsAPI';
import TourSplitsNames from './TourSplits';
import TimesNames from './TimesNames';
import {fetch_champs_Indiv} from './../../DatasBack'
import {fetch_tournament_tabela} from './../../DatasBack'


export const BoxTeamStats = ({ TourName , Team1 , Team2 }: any) =>{
    const [activeTab, setActiveTab] = useState("Times");
    const [selectedTournament, setSelectedTournament] = useState<string | null>('CBLOL 2024 Split 2');

    const [Tabela, setTabela] = useState<any[]>([]);
    const [TeamData1, setTeamData1] = useState<any[]>([]);
    const [TeamData2, setTeamData2] = useState<any[]>([]);
    const [error, setError] = useState<string | null>(null);

    const NameTime1Certo = TimesNames[Team1 as keyof typeof TimesNames] || Team1;
    const NameTime2Certo = TimesNames[Team2 as keyof typeof TimesNames] || Team2;
    const NameTourCerto = TourSplitsNames[TourName as keyof typeof TourSplitsNames] || TourName;

    useEffect(() => {
      const fetchData1 = async () => {
          try {
              const data24P = await fetch_champs_Indiv(NameTourCerto, NameTime1Certo);
              setTeamData1(data24P || []); // Se data24P for undefined, define um array vazio
              setError(null); // Redefine o estado de erro se a solicitação for bem-sucedida
          } catch (error: any) {
              console.error("An error occurred:", error);
              setTeamData1([]);
              setError(error.message); // Define o estado de erro se ocorrer um erro na solicitação
          }
      };

      const fetchData2 = async () => {
          try {
              const data24P = await fetch_champs_Indiv(NameTourCerto, NameTime2Certo);
              setTeamData2(data24P || []); // Se data24P for undefined, define um array vazio
              setError(null); // Redefine o estado de erro se a solicitação for bem-sucedida
          } catch (error: any) {
              console.error("An error occurred:", error);
              setTeamData2([]);
              setError(error.message); // Define o estado de erro se ocorrer um erro na solicitação
          }
      };

      fetchData1();
      fetchData2();
  }, [Team1, Team2]);

  

  const tourNameModif = TourSplitsNames[TourName as keyof typeof TourSplitsNames] || TourName;
    
    useEffect(() => {
        if (tourNameModif) {
            const fetchData1 = async () => {
                try {
                    const data24P = await fetch_tournament_tabela(tourNameModif);
                    setTabela(data24P || []); // Se data24P for undefined, define um array vazio
                    setError(null); // Redefine o estado de erro se a solicitação for bem-sucedida
                } catch (error: any) {
                    console.error("An error occurred:", error);
                    setTabela([]);
                    setError(error.message); // Define o estado de erro se ocorrer um erro na solicitação
                }
            };
            fetchData1()
            
        }
     
    }, [tourNameModif]);

    const handleTabChange = (tabName:any) => {
        setActiveTab(tabName);
      };

    

    return (
     
        <div >
            <div className='Tab-Team-Stats'>
        <button
          className={activeTab === "Tabela" ? "active-tab" : ""}
          onClick={() => handleTabChange("Tabela")}>Tabela</button>
        <button
          className={activeTab === "Times" ? "active-tab" : ""}
          onClick={() => handleTabChange("Times")}>Stats Times</button>
      </div>
      
        <div className="tabela-details-container">
        {activeTab === "Tabela" && <TableTab />}
        {activeTab === "Times" && <TimesTab />}
        </div>
        </div>
      
    );

    function TimesTab() {
    
          return (
              <div className="teams-container">
                <TeamRes TourName={tourNameModif} Team1={Team1} Team2={Team2} TeamData1={TeamData1} TeamData2={TeamData2}/>
              </div>
          );
      
  }
    
    function TableTab() {
      return <div className="details-container">
                  {selectedTournament && (
                    <div className="points-table" ><BoxTabelaData Tabela={Tabela} /></div> 
                  )}
              </div> ;
    }
}



export default BoxTeamStats;