const jogadoresNames = {
    Noah: "Noah (Oh Hyeon-taek)",
    Bin: "Bin (Chen Ze-Bin)",
    Maple: "Maple (Huang Yi-Tang)",
    Jun: "Jun (Yoon Se-jun)",
    HansSama: "Hans Sama",
    APA: "APA (Eain Stearns)",
    Yeon: "Yeon (Sean Sung)",
    knight: "knight (Zhuo Ding)",
    CreCre: "Crecre",
    Trigo: "Trigo (Matheus Trigo)",
    Ayu: "Ayu (Andrey Saraiva)",
    Zzk: "Zzk (Kwon Hee-won)",
    JoJo: "Jojo (Gabriel Dzelme)",
    Pilot: "Piloto",
    Dioge: "Dioge (Diogenes Barbosa)",
    Envy: "Envy (Bruno Farias)",
    Smiley: "SMILEY (Ludvig Granquist)",
    Aegis: "Aegis (Gabriel Lemos)",
    frosty: "frostyy"
};

export default jogadoresNames;