import React, { useEffect, useState } from 'react';
import championNamesReverse from './../Campeoes/championNamesReverse';
const TournamentTable = ({ data, tournamentName }: any) => {
  const [sortedData, setSortedData] = useState(data);
  const [sortBy, setSortBy] = useState<string>('games');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');
  const [loading, setLoading] = useState(true);
  const tourname = tournamentName === "null" ? "Todos jogos na Carreira" :tournamentName === null ? "Todos jogos na Carreira" : tournamentName === "" ? "Todos jogos na Carreira" :tournamentName === "error" ? "Sem jogos desse Jogador" : tournamentName ;


  useEffect(() => {
    if (data.length > 0) {
      setLoading(false); // Quando os dados são carregados, define loading como false
      handleSort(sortBy); // Organiza os dados por "games" em ordem decrescente
    }
  }, [data]); // Executa sempre que os dados mudam

  // Função para calcular o WR (Win Rate)
  const calcularWR = (vitorias: number, jogos: number): string => {
    if (jogos === 0) return '0%';
    const wr = (vitorias / jogos) * 100;
    return `${wr.toFixed(1)}%`;
  };

  // Função para calcular o farm por minuto
  const calcularFarmPorMinuto = (cs: number, tempoMinutos: number): string => {
    if (tempoMinutos === 0) return '0';
    const farmPorMinuto = cs / tempoMinutos;
    return farmPorMinuto.toFixed(1);
  };

  // Função para calcular o gold por minuto
  const calcularGoldPorMinuto = (gold: number, tempoMinutos: number, jogos: number): string => {
    if (tempoMinutos === 0) return '0';
    const goldPorMinuto = gold / (tempoMinutos * jogos);
    return goldPorMinuto.toFixed(1);
  };

  // Função para calcular o dano por minuto
  const calcularDanoPorMinuto = (dano: number, tempoMinutos: number, jogos: number): string => {
    if (tempoMinutos === 0) return '0';
    const danoPorMinuto = dano / (tempoMinutos * jogos);
    return danoPorMinuto.toFixed(1);
  };

  // Função para calcular a média de dano por jogo em milhares
  const calcularMediaPorJogo1000 = (danoTotal: number, jogos: number): string => {
    const mediaDanoPorJogo = danoTotal / jogos / 1000;
    return `${mediaDanoPorJogo.toFixed(1)}K`;
  };

  // Função para calcular a média de um valor por jogo
  const calcularMediaPorJogo = (valor: number, jogos: number): number => {
    if (jogos === 0) return 0;
    return valor / jogos;
  };

 
  // Função para calcular o KDA
  const calcularKDA = (kills: number, deaths: number, assists: number): number => {
    if (deaths === 0) {
      if (kills === 0) {
        return assists;
      } else {
        return (kills + assists) / 1;
      }
    } else {
      return (kills + assists) / deaths;
    }
  };

  const renderChampionIcon = (championName: any) => {
    const championCerto  = championNamesReverse[championName as keyof typeof championNamesReverse] || championName;
    const imageUrl = `https://ddragon.bangingheads.net/cdn/14.6.1/img/champion/${championCerto}.png`;
    return (
      <div className="champion-card-db">
        <img src={imageUrl} alt={championName} className="champion-image-db" />
        <div className="champion-name-db">{championName}</div>
      </div>
    );
  };

  const handleSort = (field: string) => {
    const isAsc = sortBy === field && sortDirection === 'asc';
    setSortBy(field);
    setSortDirection(isAsc ? 'desc' : 'asc');
  
    const sorted = [...data].sort((a, b) => {
      let valueA: any = a[field];
      let valueB: any = b[field];
  
      // Converte os valores para números, se aplicável
      if (!isNaN(parseFloat(valueA))) {
        valueA = parseFloat(valueA);
      } else if (valueA && valueA.includes('%')) {
        valueA = parseFloat(valueA.replace('%', ''));
      }
  
      if (!isNaN(parseFloat(valueB))) {
        valueB = parseFloat(valueB);
      } else if (valueB && valueB.includes('%')) {
        valueB = parseFloat(valueB.replace('%', ''));
      }
  
      // Realiza a comparação
      if (valueA < valueB) return isAsc ? -1 : 1;
      if (valueA > valueB) return isAsc ? 1 : -1;
      return 0;
    });
  
    setSortedData(sorted);
  };
  

  if (loading) {
    return <div>Carregando...</div>;
  }

  return (
    <div>
      <h3>{tourname}</h3>
      <table className="wikitable-Champs sortable team-members hoverable-rows team-members-current jquery-tablesorter">
        <thead>
          <tr>
            <th onClick={() => handleSort('champion')}>
              Campeao{sortBy === 'champion' && (<span>{sortDirection === 'asc' ? '↑' : '↓'}</span>)}
            </th>
            <th onClick={() => handleSort('games')}>
              Games{sortBy === 'games' && (<span>{sortDirection === 'asc' ? '↑' : '↓'}</span>)}
            </th>
            <th onClick={() => handleSort('wins')}>
              Win{sortBy === 'wins' && (<span>{sortDirection === 'asc' ? '↑' : '↓'}</span>)}
            </th>
            <th onClick={() => handleSort('losses')}>
              Loss{sortBy === 'losses' && (<span>{sortDirection === 'asc' ? '↑' : '↓'}</span>)}
            </th>
            <th onClick={() => handleSort('win_rate')}>
              WR{sortBy === 'win_rate' && (<span>{sortDirection === 'asc' ? '↑' : '↓'}</span>)}
            </th>
            <th onClick={() => handleSort('kills')}>
              K{sortBy === 'kills' && (<span>{sortDirection === 'asc' ? '↑' : '↓'}</span>)}
            </th>
            <th onClick={() => handleSort('deaths')}>
              D{sortBy === 'deaths' && (<span>{sortDirection === 'asc' ? '↑' : '↓'}</span>)}
            </th>
            <th onClick={() => handleSort('assists')}>
              A{sortBy === 'assists' && (<span>{sortDirection === 'asc' ? '↑' : '↓'}</span>)}
            </th>
            <th onClick={() => handleSort('kda')}>
              KDA{sortBy === 'kda' && (<span>{sortDirection === 'asc' ? '↑' : '↓'}</span>)}
            </th>
            <th onClick={() => handleSort('avg_game_length')}>
              T/M{sortBy === 'avg_game_length' && (<span>{sortDirection === 'asc' ? '↑' : '↓'}</span>)}
            </th>
            <th onClick={() => handleSort('total_cs')}>
              CS{sortBy === 'total_cs' && (<span>{sortDirection === 'asc' ? '↑' : '↓'}</span>)}
            </th>
            <th onClick={() => handleSort('total_cs')}>
              CS/M{sortBy === 'total_cs' && (<span>{sortDirection === 'asc' ? '↑' : '↓'}</span>)}
            </th>
            <th onClick={() => handleSort('total_gold')}>
              Gold{sortBy === 'total_gold' && (<span>{sortDirection === 'asc' ? '↑' : '↓'}</span>)}
            </th>
            <th onClick={() => handleSort('total_gold')}>
              G/M{sortBy === 'total_gold' && (<span>{sortDirection === 'asc' ? '↑' : '↓'}</span>)}
            </th>
            <th onClick={() => handleSort('total_dmg')}>
              DMG{sortBy === 'total_dmg' && (<span>{sortDirection === 'asc' ? '↑' : '↓'}</span>)}
            </th>
            <th onClick={() => handleSort('total_dmg')}>
              DMG/M{sortBy === 'total_dmg' && (<span>{sortDirection === 'asc' ? '↑' : '↓'}</span>)}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((campeao: any, index: any) => (
            <tr key={index}>
              <td>{renderChampionIcon(campeao.champion)}</td>
              <td>{parseInt(campeao.games)}</td>
              <td>{parseInt(campeao.wins)}</td>
              <td>{parseInt(campeao.losses)}</td>
              <td>{calcularWR(parseInt(campeao.wins), parseInt(campeao.games))}</td>
              <td>{calcularMediaPorJogo(parseInt(campeao.kills), parseInt(campeao.games)).toFixed(1)}</td>
              <td>{calcularMediaPorJogo(parseInt(campeao.deaths), parseInt(campeao.games)).toFixed(1)}</td>
              <td>{calcularMediaPorJogo(parseInt(campeao.assists), parseInt(campeao.games)).toFixed(1)}</td>
              <td>{calcularKDA(parseInt(campeao.kills), parseInt(campeao.deaths), parseInt(campeao.assists)).toFixed(2)}</td>
              <td>{parseFloat(campeao.avg_game_length).toFixed(1)}</td>
              <td>{calcularMediaPorJogo(parseInt(campeao.total_cs), parseInt(campeao.games)).toFixed(1)}</td>
              <td>{calcularFarmPorMinuto(parseInt(campeao.total_cs), parseFloat(campeao.avg_game_length))}</td>
              <td>{calcularMediaPorJogo1000(parseInt(campeao.total_gold), parseInt(campeao.games))}</td>
              <td>{calcularGoldPorMinuto(parseInt(campeao.total_gold), parseFloat(campeao.avg_game_length), parseInt(campeao.games))}</td>
              <td>{calcularMediaPorJogo1000(parseInt(campeao.total_dmg), parseInt(campeao.games))}</td>
              <td>{calcularDanoPorMinuto(parseInt(campeao.total_dmg), parseFloat(campeao.avg_game_length), parseInt(campeao.games))}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TournamentTable;
