import React, { createContext, useContext, useState, useEffect, useRef } from 'react';
import { onAuthStateChanged, getAuth } from "firebase/auth";
import { doc, getDoc, updateDoc, onSnapshot } from "firebase/firestore";
import db from '../firebaseConfig'; // Importe sua instância do Firebase Firestore aqui

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const [userLoggedIn, setUserLoggedIn] = useState(false);
    const [user, setUser] = useState(null);
    const [userProfile, setUserProfile] = useState(null);
    const [cachedPoints, setCachedPoints] = useState(null);
    const [initialLoad, setInitialLoad] = useState(true);
    const updateTimeout = useRef(null);

    useEffect(() => {
        const auth = getAuth();
        const unsubscribeAuth = onAuthStateChanged(auth, async (authUser) => {
            setUserLoggedIn(!!authUser);
            setUser(authUser);

            if (authUser) {
                const profileDoc = doc(db, "users", authUser.uid);

                // Usar onSnapshot para ouvir mudanças em tempo real no documento do usuário
                const unsubscribeSnapshot = onSnapshot(profileDoc, (docSnapshot) => {
                    if (docSnapshot.exists()) {
                        const profileData = docSnapshot.data();
                        setUserProfile(profileData);
                        setCachedPoints(profileData.points);
                    } else {
                        console.log("Profile not found");
                    }
                });

                return () => unsubscribeSnapshot();
            } else {
                setUserProfile(null);
                setCachedPoints(null);
            }

            setInitialLoad(false);
        });

        return () => unsubscribeAuth();
    }, []);

    useEffect(() => {
        if (initialLoad) return;
        if (updateTimeout.current) clearTimeout(updateTimeout.current);

        updateTimeout.current = setTimeout(async () => {
            await updatePointsInDB();
        }, 5000); // Atualiza a cada 5 segundos se houver mudanças

        return () => clearTimeout(updateTimeout.current);
    }, [cachedPoints, initialLoad]);

    const updatePointsInDB = async () => {
        if (user && userProfile?.points !== cachedPoints) {
            const userRef = doc(db, 'users', user.uid);
            await updateDoc(userRef, {
                points: cachedPoints
            });
            setUserProfile(prevProfile => ({ ...prevProfile, points: cachedPoints }));
        }
    };

    const updateUserPoints = (points) => {
        setCachedPoints(points);
    };

    return (
        <AuthContext.Provider value={{ userLoggedIn, user, userProfile, updateUserPoints }}>
            {children}
        </AuthContext.Provider>
    );
}
