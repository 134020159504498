// src/components/GameCard.tsx
import React from 'react';
import './styles/GameCard.css';
import {Link} from "react-router-dom";
import {ReactComponent as TeamTBDSVG} from '../../assets/images/team-tbd.svg';
import {ScheduleEvent} from "../types/baseTypes";
import { localize, changeLanguage } from '../../styles/GlobalStrings.js';


interface GameCardProps {
  scheduleEvent: ScheduleEvent;
  
}

export function GameCard({ scheduleEvent }: GameCardProps) {
  

  return (
    <Link to={`live/${scheduleEvent.match.id}`}>
        <div className="live-Card-game">
            <h3>{scheduleEvent.league.name} - {scheduleEvent.blockName}</h3>
            <h4>
                <span>
                    {new Date(scheduleEvent.startTime).toLocaleTimeString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: '2-digit'})}
                </span>
            </h4>
            <div className="live-content-Card-game">
                <div className="live-team-Card-game">
                    {scheduleEvent.match.teams[0].code === "TBD" ? (<TeamTBDSVG className="live-team-image-Card-game"/>) : (<img className="live-team-image-Card-game" src={scheduleEvent.match.teams[0].image} alt={scheduleEvent.match.teams[0].name}/>) }
                    <span>
                        <h3 className='live-content-Card-game-names'>
                            {scheduleEvent.match.teams[0].name}
                        </h3>
                    </span>
                    <span className="outcome-Card-game">
                        <p className={scheduleEvent.match.teams[0].result ? scheduleEvent.match.teams[0].result.outcome : ''}>
                            {scheduleEvent.match.teams[0].result ? scheduleEvent.match.teams[0].result.outcome : null}
                        </p>
                    </span>
                </div>

                <div className="game-card-versus-Card-game">
                    <span>{localize('Bestoftext')} {scheduleEvent.match.strategy.count}</span>
                    <span>
                        <p>
                            {scheduleEvent.match.teams[0].result && scheduleEvent.match.teams[1].result ? `${scheduleEvent.match.teams[0].result.gameWins} - ${scheduleEvent.match.teams[1].result.gameWins}` : null}
                        </p>
                    </span>
                    <h1>VS</h1>
                </div>

                <div className="live-team-Card-game">
                    {scheduleEvent.match.teams[1].code === "TBD" ? (<TeamTBDSVG className="live-team-image-Card-game"/>) : (<img className="live-team-image-Card-game" src={scheduleEvent.match.teams[1].image} alt={scheduleEvent.match.teams[1].name}/>) }
                    <span>
                        <h3 className='live-content-Card-game-names'>
                            {scheduleEvent.match.teams[1].name}
                        </h3>
                    </span>
                    <span className="outcome-Card-game">
                        <p className={scheduleEvent.match.teams[1].result ? scheduleEvent.match.teams[1].result.outcome : ''}>
                            {scheduleEvent.match.teams[1].result ? scheduleEvent.match.teams[1].result.outcome : null}
                        </p>
                    </span>
                    
                </div>
            </div>
        </div>
    </Link>
);
}
