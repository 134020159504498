import React, { useEffect, useState } from 'react';
import { BetProvider } from './BetContext';
import Match from './BetMatch';
import BetGames from './BetGames';
import BetList from './BetList';
import BetMissoes from './BetMissoes';



const BetBox = () => {
  const [activeTab, setActiveTab] = useState("Apostas");

  const handleTabChange = (tabName:any) => {
    setActiveTab(tabName);
  };
  return (
    <BetProvider>
      <div >
            <div className='Tab-Team-Stats'>
        <button
          className={activeTab === "Apostas" ? "active-tab" : ""}
          onClick={() => handleTabChange("Apostas")}>Apostas</button>
        <button
          className={activeTab === "Suas Apostas" ? "active-tab" : ""}
          onClick={() => handleTabChange("Suas Apostas")}>Suas Apostas</button>
        <button
          className={activeTab === "Missoes" ? "active-tab" : ""}
          onClick={() => handleTabChange("Missoes")}>Missoes</button>
      </div>    
        <div className="tabela-details-container">
        {activeTab === "Apostas" && <BetGames />}
        {activeTab === "Suas Apostas" && <BetList />}
        {activeTab === "Missoes" && <BetMissoes />}
        </div>
      </div> 
    </BetProvider>
  );
};

export default BetBox;
