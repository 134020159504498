import React, { useState, useEffect } from 'react';
import Joyride, { Step, CallBackProps } from 'react-joyride';

const steps: Step[] = [
    {
        target: '#draggable-chat',
        content: 'This is the chat component!',
    },
    {
        target: '#draggable-bet',
        content: 'This is the betting component!',
    },
    // Adicione mais etapas conforme necessário
];

const Tutorial: React.FC = () => {
    const [run, setRun] = useState(false);

    useEffect(() => {
        const tutorialShown = localStorage.getItem('tutorialShown');
        if (!tutorialShown) {
            setRun(true);
            localStorage.setItem('tutorialShown', 'true');
        }
    }, []);

    const handleJoyrideCallback = (data: CallBackProps) => {
        const { status } = data;
        const finishedStatuses: string[] = ['finished', 'skipped'];

        if (finishedStatuses.includes(status)) {
            setRun(false);
        }
    };

    return (
        <Joyride
            steps={steps}
            run={run}
            continuous
            showProgress
            showSkipButton
            callback={handleJoyrideCallback}
        />
    );
};

export default Tutorial;
