const TourSplitsNames = {
    CBLOL: "CBLOL 2024 Split 2",
    'CBLOL Academy': "CBLOL Academy 2024 Split 2",
    LPL: "LPL 2024 Summer Placements",
    LLA: "LLA 2024 Closing",
    LCK: "LCK 2024 Summer",
    LEC: "LEC 2024 Summer",
    LCS: "LCS 2024 Summer",
    'Prime League': "PRM 1st Division 2024 Summer",
    'La Ligue Française': "LFL 2024 Summer",
    TCL: "TCL 2024 Summer",
    'LCK Challengers': "LCK CL 2024 Summer",
    SuperLiga: "LVP SL 2024 Summer",
    'Elite Series': "Elite Series 2024 Summer",
    'Liga Portuguesa': "LPLOL 2024 Summer",
    
    DrMundo: "Dr. Mundo",
    TahmKench: "Tahm Kench",
    TwistedFate: "Twisted Fate",
    RekSai: "Rek'Sai",
    RenataGlasc: "Renata Glasc"
};

export default TourSplitsNames;