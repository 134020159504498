import React, { useState } from "react";
import '../Schedule/styles/TabbedContent.css'; // Import CSS styles for the tabbed content
import ApiComponent from "./ApiComponent";
import PlayersComponent from "./PlayersComponent";



function Cbolao() {
  const [activeTab, setActiveTab] = useState("Tabela");

  const handleTabChange = (tabName:any) => {
    setActiveTab(tabName);
  };

  return (
    <div className="tabbed-content-container-dd">
      <div className="tab-list-dd">
        <button
          className={activeTab === "Tabela" ? "active-tab" : ""}
          onClick={() => handleTabChange("Tabela")}
        >
          Tabela
        </button>
        <button
          className={activeTab === "ProViews" ? "active-tab" : ""}
          onClick={() => handleTabChange("ProViews")}
        >
          Pro Views
        </button>
        <button
          className={activeTab === "Times" ? "active-tab" : ""}
          onClick={() => handleTabChange("Times")}
        >
          Times
        </button>
      </div>

      <div className="tabbed-content-container-dd">
        {activeTab === "Tabela" && <TableTab />}
        {activeTab === "ProViews" && <ProViewsTab />}
        {activeTab === "Times" && <TimesTab />}
      </div>
    </div>
  );
}

function TableTab() {
  return <div><ApiComponent/></div>;
}

function ProViewsTab() {
  return <div><PlayersComponent/></div>;
}

function TimesTab() {
  return <div>Em Breve </div>;
}

export default Cbolao;
