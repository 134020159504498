import React from 'react';
import { Tooltip } from 'react-tooltip'
import './styles/badge.css';

interface BadgeProps {
  type: string;
}

const Badge: React.FC<BadgeProps> = ({ type }) => {
  const badgeDescriptions: { [key: string]: string } = {
    new: 'Novato',
    mod: 'Moderator',
    premium:'Premium',
    admin: 'Administrator',
    // Adicione mais tipos e descrições conforme necessário
  };

  const badgeIcons: { [key: string]: string } = {
    new: '🆕',
    premium:'👑',
    mod: '🛡️',
    admin: '👑',
    // Adicione mais ícones conforme necessário
  };

  return (
    <>
      <span data-tooltip-id="my-tooltip" data-tooltip-content={badgeDescriptions[type]} className="badge-icon">
        {badgeIcons[type]}
      </span>
      <Tooltip  id="my-tooltip" />
    </>
  );
};



export default Badge;
