import React, { useEffect, useState } from 'react';
import axios from 'axios';


interface Player {
    id: number;
    name: string;
    team: string;
}

const PlayersComponent: React.FC = () => {
   

    useEffect(() => {
       
    }, []);

    return (
        <div className='caixa-geral'>
           <div className='caixa-Time'></div>
              <div className='caixa-stats'>
                <div className='stats-data'>
                    <div className='Stats'>B 1 |</div>
                    <div className='Stats'>I 1 |</div>
                    <div className='Stats'>T 1 |</div>
                    <div className='Stats'>O 81,680 | </div>
                    <div className='Stats'>19 K |</div>
                    <div className='Stats'>K 19 | </div>
                    <div className='Stats'>81,680 O |</div>
                    <div className='Stats'>1 T |</div>
                    <div className='Stats'>1 I |</div>
                    <div className='Stats'>1 B</div>
                    
                </div>
                <div className='caixa-timer'>
                    <label>00:35</label>
                </div>
              </div>
           <div className='caixa-Time'></div>
        </div>
    );
};

export default PlayersComponent;
