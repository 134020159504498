import React from 'react';
import AdBanner from './../Ads/AdBanner01';
import { Navigate, useNavigate } from 'react-router-dom';
import { usePremium } from './PremiumContext';
import './styles/premium.css';
import { useAuth } from './../../utils/AuthContext';

function Premium() {
  const { isPremium } = usePremium();
  const { userLoggedIn } = useAuth();
  document.title = "Premium - Análise das Lendas ";

  const navigate = useNavigate();

    const handleLoginRedirect = () => {
        navigate('/login');
    };
  
  const renderPremiumInfo = () => {
    console.log(userLoggedIn)
    if (userLoggedIn === true){ 
      return(
        <div className="payment-buttons">     
         <button onClick={() => window.open('https://buy.stripe.com/8wM02P2yBfeAc806oo', '_blank')} >Assinatura com Cartão</button>
        {/* <button >Pagar com Pix</button> */}
      </div>
    )
    } else{
      return(  <div className="payment-buttons"> <button onClick={handleLoginRedirect}>Login</button></div>  )
    }
  }

  if (isPremium === true){ 
    return(
    <div>
      <Navigate to="/Perfil" />
    </div>
  )
  }
  else { return (
    <div className="premium-container">
      <AdBanner />
      <div className="premium-info-box">
        <h2>Benefícios do Conteúdo Premium</h2>
        <ul>
          <li>Remova os anúncios e ajude a manter o site funcionando</li>
          <li>Em breve Mais conteudos Exclusivos</li>
          <li>Cancele a hora que quiser.</li>
         {/*  <li>Informações exclusivas e insights avançados</li>*/}
         {/* <li>IA com dados e analizes de Probabilidade dos Jogos EXCUSIVAS </li>*/}
        </ul>
      </div>
      <div className="payment-options">
        <h2>Escolha seu Método de Pagamento</h2>
        {renderPremiumInfo()}
      </div>
    </div>
  );}
}

export default Premium;
