import './styles/scheduleStyle.css'
import { localize, changeLanguage } from '../../styles/GlobalStrings.js';

import {getScheduleResponse} from "../../utils/LoLEsportsAPI";
import {EventCard} from "./EventCard";
import {useEffect, useState} from "react";

import {Schedule, ScheduleEvent} from "../types/baseTypes";
import './styles/TabbedContent.css'; // Import CSS styles for the tabbed content
import './styles/ButtonMenu.css';
import { usePremium } from '../Premium/PremiumContext';

export function EventsSchedule() {
  const [AllEvents, setAllEvents] = useState([]);
  const [liveEvents, setLiveEvents] = useState([]);
  const [last7DaysEvents, setlast7DaysEvents] = useState([]);
  const [next7DaysEvents, setNext7DaysEvents] = useState([]);
  const [activeTab, setActiveTab] = useState('Todos'); // Tab inicial ativa
  const [selectedTournament, setSelectedTournament] = useState<string>('');
  const [TournamentName, setTournamentName] = useState<string>('');
  const [selectedDays, setSelectedDays] = useState<number>(7); 
  const handleTournamentSelect = (slug: string,Name:string) => {
    // Certifique-se de que o estado está sendo atualizado corretamente aqui.
    setTournamentName(Name === 'todos' ? '' : Name);
    setSelectedTournament(slug === 'todos' ? '' : slug);
};

    const { isPremium } = usePremium();

    const tournamentNames = ['Todos','Worlds','MSI', 'CBLoL', 'CBLoL Academy', 'LCK', 'LPL','LCS', 'LEC', 'PCS','VCS','LLA', 'LEC Masters', 'North Regional League', 'South Regional League', 'LCO'];
    const tournamentSlugs = ['todos','Worlds','msi' ,'cblol-brazil', 'cblol_academy', 'lck', 'lpl','emea','lcs','pcs','vcs','lla', 'emea_masters', 'north_regional_league', 'south_regional_league', 'lco'];

  // Chamada de API para buscar os eventos e categorizá-los
  useEffect(() => {
    console.log('Filtrando eventos com base em:', selectedDays); // Adicionar mensagem de log
    getScheduleResponse().then(response => {
        const schedule = response.data.data.schedule;
        console.groupCollapsed(`Scheduled Matches: ${schedule.events.length}`);
        console.table(schedule.events);
        console.groupEnd();
        setAllEvents(schedule.Events)
        setLiveEvents(schedule.events.filter(filterLiveEvents));
        setlast7DaysEvents(schedule.events.filter((event: ScheduleEvent) => filterByLastXDays(event, selectedDays)));
        setNext7DaysEvents(schedule.events.filter(filterByNext7Days));
        
       {/*} let tournamentsSet = new Set(); // Crie um conjunto vazio para armazenar os nomes dos torneios
        schedule.events.forEach((event: ScheduleEvent) => {
            if (event.league && event.league.name) {
                tournamentsSet.add(event.league.name);
            }
        });
        const tournaments = Array.from(tournamentsSet);
        console.log('Nomes dos Torneios:', tournaments); */}

    }).catch(error =>
        console.error(error)
    );
}, [selectedDays]); // Adicionando selectedDays como uma dependência do useEffect

  // Configuração do título da página
  useEffect(() => {
      document.title = "Jogos - Análise das Lendas ";
  }, []);

  const tabs = [ 
      { label: 'Todos', content: AllEvents },
      { label: localize('AoVivotext'), content: liveEvents },
      { label: localize('JogosFuturostext'), content: next7DaysEvents },
      { label: localize('Historicotext'), content: last7DaysEvents },
  ];

  let scheduledEvents = [
    {
        emptyMessage: `Sem Jogos Ao Vivo - ${TournamentName}`,
        scheduleEvents: liveEvents,
        title: `Jogos Ao Vivo - ${TournamentName}`,
    },
    {
        emptyMessage: `Sem Jogos Futuros - ${TournamentName}`,
        scheduleEvents: next7DaysEvents,
        title: `Jogos Futuros - ${TournamentName}`,
    },
    {
        emptyMessage: `Sem Jogos Recentes - ${TournamentName}`,
        scheduleEvents: last7DaysEvents,
        title: `Historico de Jogos - ${TournamentName}`,
    }
]
  
  const handleTabClick = (tabLabel: string) => {
    setActiveTab(tabLabel);
};

 
    const renderTabContent = () => {
    const activeTabContent = tabs.find(tab => tab.label === activeTab)?.content || []; // Assegurando que nunca será undefined
    if (activeTab === localize('Historicotext')) {
      const filteredEvents = last7DaysEvents.filter(event => filterByLastXDays(event, selectedDays));
      return (
          <div >
            <div className="tab-content2">
             <EventCards
                emptyMessage={`${activeTab} - ${TournamentName}`}
                scheduleEvents={activeTabContent}
                title={`${activeTab}`}
                tournamentFilter={selectedTournament}
            />
          </div>
          </div>
      );
  } else if (activeTab === localize('AoVivotext')) {
      return (
          <div className="tab-content2">
             <EventCards
                emptyMessage={`Sem Jogos ${activeTab} - ${TournamentName}`}
                scheduleEvents={activeTabContent}
                title={` ${activeTab}`}
                tournamentFilter={selectedTournament}
               
            />
          </div>
      );
  } else if (activeTab === localize('JogosFuturostext')) {
    return (
        <div className="tab-content2">
           <EventCards
              emptyMessage={`Sem ${activeTab} - ${TournamentName}`}
              scheduleEvents={activeTabContent}
              title={` ${activeTab}`}
              tournamentFilter={selectedTournament}
          />
        </div>
    );
} else if (activeTab === localize('Todos')) {
    return (
        <div className="tab-content2">
            {scheduledEvents.map(scheduledEvent => (
                <EventCards2 
                key={scheduledEvent.title} 
                emptyMessage={scheduledEvent.emptyMessage} 
                scheduleEvents={scheduledEvent.scheduleEvents} 
                title={scheduledEvent.title}
                tournamentFilter={selectedTournament}
                />
            ))}
        </div>
    );
}
};



  return (
 
      <div className="orders-container-dd">
           
          <div className="tournament-filter-dd">
    {tournamentNames.map((name, index) => (
        <button
        key={tournamentSlugs[index]}
        className={selectedTournament === (tournamentSlugs[index] === 'todos' ? '' : tournamentSlugs[index]) ? 'active-tournament' : 'tournament'}
        onClick={() => 
        handleTournamentSelect(tournamentSlugs[index],name) }>
        {name}
        </button>
    ))}
</div>
          <div className="tabbed-content-container-dd">
              <div className="tab-list-dd">
                  {tabs.map((tab) => (
                     <button
                     key={tab.label}
                     className={tab.label === activeTab ? 'active-tab' : 'tab'}
                     onClick={() => handleTabClick(tab.label)}
                 >
                     {tab.label}
                 </button>
                  ))}
              </div>
              {renderTabContent()}
          </div>
          </div>
  );
}

type EventCardProps = {
  emptyMessage: string;
  scheduleEvents: ScheduleEvent[];
  title: string;
}
function EventCards2({emptyMessage, scheduleEvents, title , tournamentFilter}: EventCardProps & { tournamentFilter: string }) {

    const filteredEvents = tournamentFilter ? scheduleEvents.filter(event => event.league.slug === tournamentFilter) : scheduleEvents;


    if (scheduleEvents !== undefined && scheduleEvents.length !== 0) {
        return (
            <div>
                <div className='header-titulos'>
                <h2 className="games-of-day">{title} </h2></div>
                <div className="games-list-container">
                    <div className="games-list-items">
                        {filteredEvents.map(scheduleEvent => (
                            <EventCard
                                key={`${scheduleEvent.match.id}_${scheduleEvent.startTime}`}
                                scheduleEvent={scheduleEvent}
                            />
                        ))}
                    </div>
                </div>
            </div>
        );
    }else {
        return (
            <div className='header-titulos-empt'>
            <h2 className="games-of-day">{emptyMessage}</h2>
            </div>
        );
    }
}

function EventCards({ emptyMessage, scheduleEvents, title, tournamentFilter}: EventCardProps & { tournamentFilter: string }) {
    // Filtrar eventos por slug do torneio, se um torneio estiver selecionado
    const filteredEvents = tournamentFilter ? scheduleEvents.filter(event => event.league.slug === tournamentFilter) : scheduleEvents;

    console.log('Eventos filtrados:', filteredEvents); // Adicione um log para verificar os eventos filtrados

    if (filteredEvents.length === 0) {
        console.log('Nenhum evento encontrado.'); // Adicione um log se nenhum evento for encontrado
        return <h2 className="games-of-day">{emptyMessage}</h2>;
    } else {
        console.log('Eventos encontrados:', filteredEvents.length); // Adicione um log para o número de eventos encontrados
        return (
            <div>
                <h2 className="games-of-day">{title}</h2>
                <div className="games-list-container">
                    <div className="games-list-items">
                        {filteredEvents.map(scheduleEvent => (
                            <EventCard
                                key={`${scheduleEvent.match.id}_${scheduleEvent.startTime}`}
                                scheduleEvent={scheduleEvent}
                            />
                        ))}
                    </div>
                </div>
            </div>
        );
    }
}



function filterLiveEvents(scheduleEvent: ScheduleEvent) {
    return scheduleEvent.match !== undefined && scheduleEvent.state === "inProgress";
}

function filterByLastXDays(scheduleEvent: ScheduleEvent, days: number) {
  if (scheduleEvent.state !== 'completed') {
      return false;
  }
  let minDate = new Date();
  let maxDate = new Date();
  minDate.setDate(minDate.getDate() - days); // Utiliza o parâmetro days aqui
  maxDate.setHours(maxDate.getHours() - 1); // Isso está correto se você deseja considerar eventos até a última hora
  let eventDate = new Date(scheduleEvent.startTime);

  if (eventDate.valueOf() > minDate.valueOf() && eventDate.valueOf() < maxDate.valueOf()) {
      if (scheduleEvent.match === undefined) return false;
      if (scheduleEvent.match.id === undefined) return false;

      return true;
  } else {
      return false;
  }
}

function filterByNext7Days(scheduleEvent: ScheduleEvent) {
    if (scheduleEvent.state === "inProgress" || scheduleEvent.state === "completed") {
        return
    }
    let minDate = new Date();
    let maxDate = new Date()
    minDate.setHours(minDate.getHours() - 1)
    maxDate.setDate(maxDate.getDate() + 7)
    let eventDate = new Date(scheduleEvent.startTime)

    if(eventDate.valueOf() > minDate.valueOf() && eventDate.valueOf() < maxDate.valueOf()){

        if(scheduleEvent.match === undefined) return false
        if(scheduleEvent.match.id === undefined) return false

        return true;
    }else{
        return false;
    }
}

export default EventsSchedule;