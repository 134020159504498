import React, { useEffect, useState } from 'react';
import { fetchCampeoesTotal2 } from '../../firebaseConfig.js';
import './styles/campeoes.css';
import '../Schedule/styles/TabbedContent.css'; // Import CSS styles for the tabbed content
import ChampionTable from './ChampionTable';
import {Link_App} from '../../utils/LoLEsportsAPI';
import {selectitembox , buildTournamentName} from './../Geral/SelectTournamentName'


const Campeoes = () => {
    const [ligaSelecionada, setLigaSelecionada] = useState('CBLOL');
    const [splitSelecionado, setSplitSelecionado] = useState('2024-2');
    const [tipoJogoSelecionado, setTipoJogoSelecionado] = useState('');
    const [campeoes24P, setCampeoes24P] = useState<any[]>([]);
    const [error, setError] = useState<string | null>(null);


    const handleLigaChange = (event:any) => { setLigaSelecionada(event.target.value); };
    const handleSplitChange = (event:any) => {setSplitSelecionado(event.target.value); };
    const handleTipoJogoChange = (event:any) => {setTipoJogoSelecionado(event.target.value); };
  

    const getTitulo = () => { return `${ligaSelecionada} ${splitSelecionado} - ${tipoJogoSelecionado.charAt(0).toUpperCase() + tipoJogoSelecionado.slice(1)}`; };

    useEffect(() => {
        document.title = "Campeões - Análise das Lendas ";
        const nome = buildTournamentName(ligaSelecionada, splitSelecionado, tipoJogoSelecionado);
        fetch_champs_by_tournamentTotal(nome);
        setError(null);
    }, [ligaSelecionada, splitSelecionado, tipoJogoSelecionado]);
    
    
    
    const fetch_champs_by_tournamentTotal = async (TournamentName: string) => {
        try {
          const response = await fetch(`${Link_App}/get_champs_by_tournament?tournament_name=${TournamentName}`);
          if (!response.ok) {
            throw new Error('Player not found');
          }
          const data = await response.json();  // Expecting an array of champions
          setCampeoes24P(data);
        } catch (error: any) {
          setError(error.message);
        }
      };

    const render = () =>{
      if (error) return ( 
           <div className='error-avi'> Dados Não encontrados, Ou essa pagina não existe, 
           Tente atualizar para tentar novamente</div>
       );
      
      return( 
      <ChampionTable  data={campeoes24P} />)
     
  }

    return (
        <div>
        <div className="campeoes-container">
        {selectitembox(ligaSelecionada,handleLigaChange,splitSelecionado,handleSplitChange,tipoJogoSelecionado,handleTipoJogoChange)}
        <h2>{getTitulo()}</h2>     
        </div >
        <div className="tab-content"> {render()} </div>
        </div>
    );
};

export default Campeoes;
