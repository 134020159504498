import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from './../../utils/AuthContext';
import { Link_App } from '../../utils/LoLEsportsAPI';
import './styles/lojaMain.css';

interface ShopItem {
  item_id: string;
  name: string;
  description: string;
  price: number;
  stock:number;
  image_url: string; 
}

const LojaMain = () => {
  const { user } = useAuth();
  const [shopItems, setShopItems] = useState<ShopItem[]>([]);

  useEffect(() => {
      fetchShopItems();
    
  }, []);

  const fetchShopItems = async () => {
    try {
      const response = await axios.get(`${Link_App}/get_shop_items`);
      if (response.data.success) {
        setShopItems(response.data.shop_items);
      }
    } catch (error) {
      console.error('Failed to fetch shop items:', error);
    }
  };
 

  const handlePurchase = async (itemId: string, price: number) => {
    const confirmPurchase = window.confirm('Are you sure you want to purchase this item?');
    if (!confirmPurchase) return;

    try {
      const response = await axios.post(`${Link_App}/purchase_item`, {
        user_id: user?.uid,
        item_id: itemId,
        price: price
      });

      if (response.data.success) {
        alert('Purchase successful!');
        // Optionally reload user points
      }
    } catch (error) {
      console.error('Failed to purchase item:', error);
      alert('Failed to purchase item');
    }
  };



  return (
    <div className="loja-main-container">
      <h2>Todos os APoints Resetam 31/12/2024 - 23:59</h2>
        <div className="shop-section">
          <h2>Shop</h2>
          <div className="shop-items">
            {shopItems.map(item => (
              <div key={item.item_id} className="shop-item">
                <img src={item.image_url} alt={item.name} className="item-image" />
                <h3>{item.name}</h3>
                <div className='shop-item-description'>
                <p>{item.description}</p>
                </div>
                <p className='shop-item-price'> AP$: {item.price} </p>
                <p className='shop-item-price'> {item.stock} Restantes </p>
                <div className='shop-item-box'>
                <button onClick={() => handlePurchase(item.item_id, item.price)}>Resgatar Pontos</button>
                </div>
              </div>
            ))}
          </div>
        </div>
        </div>
  );
};

export default LojaMain;
