import './styles/global.css';
import 'react-toastify/dist/ReactToastify.min.css';
import 'firebase/auth';
import { PremiumProvider } from './components/Premium/PremiumContext';

import React from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Match } from "./components/Match/Match";
import { Footer } from "./components/Footer/Footer";
import { Ranking } from "./components/Loja/Ranking";
import { Navbar } from "./components/Navbar/Navbar";
import { useTheme } from './theme/ThemeContext';

import Login from "./components/Login/login";
import Cadastro from "./components/Login/Cadastro";
import ForgotPassword from "./components/Login/ForgotPassword";
import Premium from "./components/Premium/Premium";
import Profile from './components/Perfil/Perfil';
import Inicio from './components/Inicio/Inicio';
import PlayersPage from './components/Jogadores/PlayersPage';
import PlayerDetailsPageapi from './components/Jogadores/PlayerDetailsPage-api';
import Campeoes from './components/Campeoes/Campeoes-api';
import Anuncios from './components/Ads/Anuncios';
import Cbolao from './components/baiano/Cbolao';
import TabelasGames from './components/TabelasGames/TabelasGames';
import Sobre from './components/Sobre/Sobre';
import NotFound from './components/Inicio/NotFound';
import { AuthProvider } from './utils/AuthContext';
import { GamesLiveCarousel } from './components/Ads/GamesLiveCarousel';
import DraggableChat from './components/Chat/DraggableChat';
import { ChatProvider } from './utils/ChatContext';
import DraggableBet from './components/Bet/DraggableBet';
import LojaMain from './components/Loja/LojaMain';
import Tutorial from './components/Inicio/Tutorial';

function App() {
    const { theme } = useTheme();

    return (
        <React.StrictMode>
            <AuthProvider>
                <PremiumProvider>
                    <Router basename="/">
                        <div className="theme-container" style={{ ...theme as React.CSSProperties }}>
                            <Navbar />
                            <Anuncios />
                            <GamesLiveCarousel />
                            <ChatProvider>
                                <DraggableChat id="draggable-chat" />
                                <DraggableBet id="draggable-bet" />
                                <div className="container">
                                    <Routes>
                                        <Route path="/" element={<Inicio />} />
                                        <Route path="/Inicio" element={<Inicio />} />
                                        <Route path="/live/:gameid" element={<Match />} />
                                        <Route path="/live/:gameid/game-index/:id" element={<Match />} />
                                        <Route path="/login" element={<Login />} />
                                        <Route path="/Ranking" element={<Ranking />} />
                                        <Route path="/premium" element={<Premium />} />
                                        <Route path="/Perfil" element={<Profile />} />
                                        <Route path="/forgot-password" element={<ForgotPassword />} />
                                        <Route path="/Cadastro" element={<Cadastro />} />
                                        <Route path="/Campeoes" element={<Campeoes />} />
                                        <Route path="/Jogadores" element={<PlayersPage />} />
                                        <Route path="/Cbolao" element={<Cbolao />} />
                                        <Route path="/TabelasGames" element={<TabelasGames />} />
                                        <Route path="/Sobre" element={<Sobre />} />
                                        <Route path="/players/:id" element={<PlayerDetailsPageapi />} />
                                        <Route path="/LojaMain" element={<LojaMain />} />
                                        <Route path="*" element={<NotFound />} />
                                    </Routes>
                                </div>
                                <Footer />
                                <Tutorial />
                            </ChatProvider>
                        </div>
                    </Router>
                </PremiumProvider>
            </AuthProvider>
        </React.StrictMode>
    );
}

export default App;
