import { useEffect, useRef, useState } from 'react';
import { GameCard } from './GameCard';
import './styles/GamesCarousel.css';
import {getScheduleResponse} from "../../utils/LoLEsportsAPI";
import { ScheduleEvent} from "../types/baseTypes";


export function GamesCarousel() {
    const carouselRef = useRef<HTMLDivElement | null>(null);
    const [currentIndex, setCurrentIndex] = useState(0);
    const [last7DaysEvents, setlast7DaysEvents] = useState<ScheduleEvent[]>([])

    useEffect(() => {
      getScheduleResponse().then(response => {
          const schedule = response.data.data.schedule;
          setlast7DaysEvents(schedule.events.filter(filterByLast7Days))
      }).catch(error =>
          console.error(error)
      );
  }, []); 

  
    useEffect(() => {
      const carousel = carouselRef.current;
  
      const autoScroll = () => {
        if (carousel) {
          // Calcula a nova posição de rolagem
          const newScrollLeft = (carousel.scrollWidth / last7DaysEvents.length) * currentIndex;
  
          // Se o índice atual é o último, redefine para o primeiro
          if (currentIndex >= last7DaysEvents.length - 1) {
            setCurrentIndex(0);
            carousel.scrollLeft = 0;
          } else {
            setCurrentIndex((prevIndex) => prevIndex + 1);
            carousel.scrollLeft = newScrollLeft;
          }
        }
      };
  
      const interval = setInterval(autoScroll, 2000); // Ajuste o tempo conforme necessário
      return () => clearInterval(interval);
    }, [currentIndex]);
  
    return (
      <section className="games-carousel">
        <h2>Jogos dos ultimos 7 dias</h2>
        <div className='teste'>
        <div className="carousel-container" ref={carouselRef}>
          {last7DaysEvents.map(scheduleEvent => {
                            return scheduleEvent.league.slug !== "tft_esports" ? (
                                <GameCard
                                    key={`${scheduleEvent.match.id}_${scheduleEvent.startTime}`}
                                    scheduleEvent={scheduleEvent}
                                />
                            ): null})
                        }
          </div>
        </div>
      </section>
    );
  }



function filterByLast7Days(scheduleEvent: ScheduleEvent) {
    if (scheduleEvent.state !== 'completed') {
        return false
    }
    let minDate = new Date();
    let maxDate = new Date()
    minDate.setDate(minDate.getDate() - 7)
    maxDate.setHours(maxDate.getHours() - 1)
    let eventDate = new Date(scheduleEvent.startTime)

    if(eventDate.valueOf() > minDate.valueOf() && eventDate.valueOf() < maxDate.valueOf()){

        if(scheduleEvent.match === undefined) return false
        if(scheduleEvent.match.id === undefined) return false

        return true;
    }else{
        return false;
    }
}

