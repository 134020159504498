import React, { useEffect, useState } from 'react';
import { tournamentsData } from './Dados';
import '../Schedule/styles/TabbedContent.css'; 
import './styles/tabelas.css';
import {BoxTabelaData} from '../Boxes/BoxTabelaData'
import  TeamsBack  from '../../assets/images/teamsback2.png';
import topImage from '../../assets/img/top.png';
import midImage from '../../assets/img/mid.png';
import jgImage from '../../assets/img/jungle.png';
import botImage from '../../assets/img/bot.png';
import supImage from '../../assets/img/support.png';
import {Link_App} from '../../utils/LoLEsportsAPI';
import {fetch_tournament_tabela} from './../../DatasBack'
import TourSplitsNames from '../Boxes/TourSplits';
import EventsSchedule from '../../components/Schedule/EventsSchedule'

const Tabelas: React.FC = () => {
    const [selectedTournament, setSelectedTournament] = useState<string | null>('CBLOL 2024 Split 2');
    const [schedule, setSchedule] = useState<any[]>([]);
    const [schedule2, setSchedule2] = useState<any[]>([]);
    const [Tabela, setTabela] = useState<any[]>([]);
    const [pointsTable, setPointsTable] = useState<any[]>([]);
    const [activeTab, setActiveTab] = useState("Jogos");
    const [error, setError] = useState<string | null>(null);

    

    const handleTabChange = (tabName:any) => {
      setActiveTab(tabName);
    };

    const laneImages: { [key: string]: string } = {
        'Top': topImage,
        'Jungle': jgImage,
        'Mid': midImage,
        'ADC': botImage,
        'Support': supImage,
    };

    const renderLaneImage = (lane: string) => {
        return <>
                   <img className='team-img' height={22} width={22} src={laneImages[lane]}/>
              </>;
    };

    useEffect(() => {
        if (selectedTournament) {
            const tournament = tournamentsData.find(t => t.name === selectedTournament);
            if (tournament) {
                setSchedule(tournament.schedule);
                setPointsTable(tournament.pointsTable);
            }
        }
    }, [selectedTournament]);

    useEffect(() => {
        if (selectedTournament) {
            fetch_tournament_schedule(selectedTournament)           
        }
    }, [selectedTournament]);

    const tourNameModif = TourSplitsNames[selectedTournament as keyof typeof TourSplitsNames] || selectedTournament;
    
    useEffect(() => {
        if (tourNameModif) {
            const fetchData1 = async () => {
                try {
                    const data24P = await fetch_tournament_tabela(tourNameModif);
                    setTabela(data24P || []); // Se data24P for undefined, define um array vazio
                    setError(null); // Redefine o estado de erro se a solicitação for bem-sucedida
                } catch (error: any) {
                    console.error("An error occurred:", error);
                    setTabela([]);
                    setError(error.message); // Define o estado de erro se ocorrer um erro na solicitação
                }
            };
            fetchData1()
            
        }
     
    }, [tourNameModif]);

     const fetch_tournament_schedule = async (TournamentName:string) => {
        try {
          const response = await fetch(`${Link_App}/get_tournament_schedule?tournament_name=${TournamentName}`);
          if (!response.ok) {
            throw new Error('Player not found');
          }
          const data = await response.json();  // Expecting an array of champions
          setSchedule2(data);
          console.log(data);
        } catch (error:any) {
          setError(error.message);
        }
      };

 

    return (
        <div className='tabbed-content-container-dd'>
            <div className="tab-list-dd">
        <button
          className={activeTab === "Jogos" ? "active-tab" : ""}
          onClick={() => handleTabChange("Jogos")}>Jogos</button>
        <button
          className={activeTab === "Tabela" ? "active-tab" : ""}
          onClick={() => handleTabChange("Tabela")}>Tabela</button>
        <button
          className={activeTab === "Times" ? "active-tab" : ""}
          onClick={() => handleTabChange("Times")}>Times</button>

      </div>
        {activeTab === "Jogos" && <EventsSchedule />}
        <div className="tabelas-container">
            <TournamentList selectedTournament={selectedTournament} onSelectTournament={setSelectedTournament} />
        {activeTab === "Tabela" && <TableTab />}
        {activeTab === "Times" && <TimesTab />}
        </div>
        </div>
    );
    function TableTab() {
        return <div className="details-container">
                    {selectedTournament && (
                      <div className="schedule-and-points">
                      <Schedule schedule={schedule2} />
                      <div className="points-table-css" ><BoxTabelaData Tabela={Tabela} /></div>
                      
                  </div>
                    )}
                </div> ;
      }
      
      function TimesTab() {
        if (selectedTournament) {
            const tournament = tournamentsData.find(t => t.name === selectedTournament);
            return (
                <div className="teams-container">
                    {tournament?.teams?.map((team: any, index: any) => (
                        <div key={index} className="team-card" style={{ backgroundImage: `url(${TeamsBack})` }}>
                            <h3>{team.name}</h3>
                            <div className="content">
                                <img src={team.logo} alt={team.name} className="team-logo" />
                                <ul className="players-list">
                                    {team.players.map((player: any, idx: any) => (
                                        <li key={idx} className="player-item">
                                            <a href={`/players/${player.id}`}>{player.id} - {renderLaneImage(player.role)} {player.role} </a>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            );
        } else {
            return <div>Selecione um torneio para ver os times.</div>;
        }
    }
};

const TournamentList: React.FC<{ selectedTournament: string | null, onSelectTournament: (tournament: string) => void }> = ({ selectedTournament, onSelectTournament }) => {
    return (
        <ul className="tournament-list">
            {tournamentsData.map(tournament => (
                <li
                    key={tournament.name}
                    className={selectedTournament === tournament.name ? 'selected' : ''}
                    onClick={() => onSelectTournament(tournament.name)}
                >
                    {tournament.namekey}
                </li>
            ))}
        </ul>
    );
};

const Schedule: React.FC<{ schedule: any[] }> = ({ schedule }) => {
    const groupedByDate = schedule.reduce((acc, game) => {
        const dateKey = `${game.Date} ${game.Day}`;
        if (!acc[dateKey]) {
            acc[dateKey] = [];
        }
        acc[dateKey].push(game);
        return acc;
    }, {});

    const iconMap: { [key: string]: string } = {
        Fluxo :'https://am-a.akamaihd.net/image?resize=96:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1670539056915_fluxo_escudo_w.png',
        LOUD : 'https://am-a.akamaihd.net/image?resize=96:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2FLogo-LOUD-Esports_Original.png',
        'paiN Gaming' : 'https://am-a.akamaihd.net/image?resize=96:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1674657011011_pain_logo_white.png',
        "Los Grandes" : 'https://am-a.akamaihd.net/image?resize=96:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1686087430642_logo_los_white_newest.png',
        "Vivo Keyd Stars" : 'https://am-a.akamaihd.net/image?resize=96:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1670542079678_vks.png',
        Liberty : 'https://am-a.akamaihd.net/image?resize=96:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1643305707691_RXfNcFMU.png',
        "RED Canids" : 'https://am-a.akamaihd.net/image?resize=96:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1631820575924_red-2021-worlds.png',
        "RED" : 'https://am-a.akamaihd.net/image?resize=96:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1631820575924_red-2021-worlds.png',
        'KaBuM! Esports' : 'https://am-a.akamaihd.net/image?resize=96:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1671197541491_LOGO-KABUM-ESPORTS-2023-NEGATIVO-FUNDOPRETO.png',
        'KaBuM!' : 'https://am-a.akamaihd.net/image?resize=96:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1671197541491_LOGO-KABUM-ESPORTS-2023-NEGATIVO-FUNDOPRETO.png',
        INTZ : 'https://am-a.akamaihd.net/image?resize=96:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1643056732785_INTZ_Logo_Principal_2022.png',
        FURIA : 'https://am-a.akamaihd.net/image?resize=96:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2FFURIA---black.png',
        fla : 'https://am-a.akamaihd.net/image?resize=32:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1706802053260_LOGOFLAESPORTSCRFCOLORIDA.png',
        idl : 'https://am-a.akamaihd.net/image?resize=1080:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1716451549109_ILHA-DAS-LENDAS-ESPORTS-BRANCO.png',
        rise : 'https://am-a.akamaihd.net/image?resize=32:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1716451640141_2-IYVhiot.png',
        trz : 'https://am-a.akamaihd.net/image?resize=48:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1716451713850_CopyofLogocoloridaTRZ.PNG'
    }

    const IconName = (iconName:any) => {
        const iconName2 = iconName.replace("Academy", "").trim() || iconName.replace("Esports", "").trim() ;
        return iconMap[iconName2] || 'default_url';
      };
    

    return (
        <div className="schedule">
            <h3>Cronograma de Jogos</h3>
            {Object.entries(groupedByDate).map(([date, games]:any) => (
                <div key={date} className="schedule-day">
                    <h4>{date}</h4>
                    <ul>
                     {games.map((game:any, index:any) => (
                            <li key={index} className="game-item">
                                <div className="game-time">{game.time}</div>
                                <div className="game-teams">
                                <div className="teams1">
                                    <span>{game.Time1}</span>
                                    <img src={IconName(game.Time1)} alt={game.team1} className="team-icon" />
                                    </div>
                                    <div className='game-vs'>vs</div>
                                    <div className="teams2">
                                    <img src={IconName(game.Time2)} alt={game.team2} className="team-icon" />
                                    <span>{game.Time2}</span>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            ))}
        </div>
    );
};



export default Tabelas;