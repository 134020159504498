import React from 'react';
import '../Schedule/styles/TabbedContent.css';
import './styles/teamRes.css';

import { ReactComponent as TowerSVG } from '../../assets/images/tower.svg';
import { ReactComponent as BaronSVG } from '../../assets/images/baron.svg';
import { ReactComponent as SwordSVG } from '../../assets/images/sword.svg';
import { ReactComponent as GoldSVG } from '../../assets/images/gold.svg';
import { ReactComponent as InhibitorSVG } from '../../assets/images/inhibitor.svg';
import { ReactComponent as ElderDragonSVG } from '../../assets/images/dragon-elder.svg';
import { ReactComponent as ArautoSVG } from '../../assets/images/ArautoT.svg';

const TeamRes = ({ TourName, Team1, Team2, TeamData1, TeamData2 }: any) => {
    const getColorClass = (percentage: any) => {
        if (percentage) {
            const numericValue = parseInt(percentage.replace('%', ''), 10); // Remove o símbolo de porcentagem e converte para inteiro

            if (numericValue >= 0 && numericValue <= 40) {
                return 'dark-red';
            } else if (numericValue >= 41 && numericValue <= 49) {
                return 'light-red';
            } else if (numericValue >= 50 && numericValue <= 60) {
                return 'light-green';
            } else if (numericValue > 60 && numericValue <= 100) {
                return 'bright-green';
            }
        }
        return 'default-background'; // Retorna um padrão se o valor for null, undefined ou não dentro dos ranges
    };

    const getColorClassKDA = (kda: any) => {
        const kdaFloat = parseFloat(kda);
        if (kdaFloat < 1.0) {
            return 'dark-red';
        } else if (kdaFloat >= 2.0 && kdaFloat <= 4.9) {
            return 'light-green';
        } else if (kdaFloat >= 5.0) {
            return 'bright-green';
        } else {
            return 'default-background';
        }
    };

    const Media = (Dado: number, jogos: number): string => {
        if (Dado === 0) return '0';
        const Resultado = Dado / jogos;
        return Resultado.toFixed(0);
    };

    const renderRow = (imagename: any, statName: string, statKey: string) => {
        const team1Stat = TeamData1[0] ? TeamData1[0][statKey] : 0;
        const team2Stat = TeamData2[0] ? TeamData2[0][statKey] : 0;
        const team1Games = TeamData1[0] ? TeamData1[0].games : 1;
        const team2Games = TeamData2[0] ? TeamData2[0].games : 1;

        return (
            <tr key={statName}>
                <td className='Stats'>{imagename}{statName}</td>
                <td className='team2'>{Media(team1Stat, team1Games)}</td>
                <td className='team1'>{Media(team2Stat, team2Games)}</td>
                <td className='Stats' >{imagename} {statName}</td>
            </tr>
        );
    };

    // Adicione um fallback para garantir que os dados sejam válidos antes de renderizar
    if (!TeamData1 || !TeamData2 || TeamData1.length === 0 || TeamData2.length === 0) {
        return <div className="team-res">Dados indisponíveis</div>;
    }

    return (
        <div className="team-res">
            <h3>Statisticas {TourName} </h3>
            <table className="stats-table">
                <thead>
                    <tr>
                        <th className='team2'>{Team1}</th>
                        <th>Media/Jogo</th>
                        <th>Media/Jogo</th>
                        <th className='team1'>{Team2}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr >
                        <td className='Stats'>Jogos Total</td>
                        <td className='team2'>{TeamData1[0] ? TeamData1[0].games : 1}</td>
                        <td className='team1'>{TeamData2[0] ? TeamData2[0].games : 1}</td>
                        <td className='Stats'>Jogos Total</td>
                    </tr>
                    {renderRow('', 'Tempo', 'tempo')}
                    {renderRow(<SwordSVG />, 'Kills', 'Kills')}
                    {renderRow(<TowerSVG />, 'Torres', 'torres')}
                    {renderRow(<InhibitorSVG />, 'Inibs', 'inibs')}
                    {renderRow(<ElderDragonSVG />, 'Drags', 'Drags')}
                    {renderRow(<BaronSVG />, 'Barons', 'barons')}
                    {renderRow(<ArautoSVG />, 'Arautos', 'arautos')}
                    {renderRow("", 'Vasti', 'vast')}
                </tbody>
            </table>
        </div>
    );
};

export default TeamRes;
