import { useEffect, useState } from 'react';
import { useParams,Link } from 'react-router-dom';
import './Style/PlayersPage.css'; // Importe o arquivo CSS para estilização
import TournamentTable from './TournamentTable';
import ImageLightbox from '../Geral/ImageLightbox';

import '../Schedule/styles/TabbedContent.css'; // Import CSS styles for the tabbed content
import { Link_App} from '../../utils/LoLEsportsAPI';
import {fetchPlayerData} from './../../firebaseConfig';

interface PlayerChamp {
  'champion': string;
  'games': string;
  'wins': string;
  'losses': string;
  'kills': string;
  'deaths': string;
  'assists': string;
  'win_rate': string;
  'kda': string;
  'total_cs': string;
  'total_dmg': string;
  'total_gold': string;
  'total_vision_score': string;
  'avg_game_length': string;

}



function PlayerDetailsPage() {
  const { id } = useParams<{ id: string }>();
  const [playerData, setPlayerData] = useState<any[]>([]);
  const [mainImage, setMainImage] = useState<string>('');
  const [campeoes, setCampeoes] = useState<PlayerChamp[]>([]);
  const [activeTab, setActiveTab] = useState<string>('total');
  const [error, setError] = useState<string | null>(null);

  const fetchPlayerChampsTotal = async (playerName: string) => {
    try {
      const response = await fetch(`${Link_App}/get_player_champs?player_name=${playerName}`);
      if (!response.ok) {
        throw new Error('Player not found');
      }
      const data = await response.json();  // Expecting an array of champions
      setCampeoes(data);
    } catch (error: any) {
      setError(error.message);
    }
  };

  const fetchPlayerChampsByTournament = async (playerName: string , year:any) => {
    try {
      const response = await fetch(`${Link_App}/get_player_champs_by_tournament?player_name=${playerName}&year=${year}`);
      if (!response.ok) {
        
      }
      const data = await response.json();
      setCampeoes(data);
    } catch (error: any) {
      setError(error.message);
    }
  };

  const fetchPlayer_img = async (playerName: string ) => {
    try {
      const response = await fetch(`${Link_App}/get_player_image?player_name=${playerName}`);
      if (!response.ok) {
        throw new Error('Player not found');
      }
      const data = await response.json();
      const imageLinks = data.Image_links;
      
      if (imageLinks.length > 0) {
        const cleanedImageLinks = imageLinks.map((link: string) => extractPngLink(link));
        setMainImage(cleanedImageLinks[0]); 
      } else {
        setError('No images found for this player.');
      }
    } catch (error: any) {
      setError(error.message);
    }
  };
  
  const extractPngLink = (url: string, options?: { width?: number }): string => {
    const baseUrl = url.match(/(https:\/\/.*\.png)/)?.[0] || url;
    const params = new URLSearchParams(url.split('?')[1]);
    if (options?.width) {
      params.set('width', options.width.toString());
    }
    return `${baseUrl}?${params.toString()}`;
  };

  useEffect(() => {
    document.title = id + " - Analise das Lendas ";
    if (id) {
    const fetchData = async (id:any) => {
        const data24P = await fetchPlayerData(id);
        setPlayerData(data24P);
    };
    fetchData(id);
    fetchPlayerChampsTotal(id);
    fetchPlayer_img(id);}
  }, [id]);  // Dependency array updated to include id

 

  
  const handleTabChange = (tab: string) => {
    if (id) { setActiveTab(tab);
    if (tab === 'total') {
      fetchPlayerChampsTotal(id)
    } else if (tab === 's24') {
      fetchPlayerChampsByTournament(id,"2024")
    }
    else if (tab === 's23') {
      fetchPlayerChampsByTournament(id,"2023")
    }
    else if (tab === 's22') {
      fetchPlayerChampsByTournament(id,"2022")
    }
    else if (tab === 's21') {
      fetchPlayerChampsByTournament(id,"2021")
    }
    else if (tab === 's20') {
      fetchPlayerChampsByTournament(id,"2020")
    }
    else if (tab === 's19') {
      fetchPlayerChampsByTournament(id,"2019")
    }}
  };



  if (error) return <div>Error: {error}</div>;

  return (
  <div>
      <Link className='head-links' to="/Jogadores"> {"<-- Voltar para jogadores"}</Link> {/* Botão de voltar */}
      <div className="player-details-container">
        <div>
          <div className="tab-list-dd">
           <button className={activeTab === 'total' ? "active-tab" : 'tab'} onClick={() => handleTabChange('total')}>Total</button>
           <button className={activeTab === 's24' ? "active-tab" : 'tab'} onClick={() => handleTabChange('s24')}>2024</button>
           <button className={activeTab === 's23' ? "active-tab" : 'tab'} onClick={() => handleTabChange('s23')}>2023</button>
           <button className={activeTab === 's22' ? "active-tab" : 'tab'} onClick={() => handleTabChange('s22')}>2022</button>
           <button className={activeTab === 's21' ? "active-tab" : 'tab'} onClick={() => handleTabChange('s21')}>2021</button>
           <button className={activeTab === 's20' ? "active-tab" : 'tab'} onClick={() => handleTabChange('s20')}>2020</button>
           <button className={activeTab === 's19' ? "active-tab" : 'tab'} onClick={() => handleTabChange('s19')}>2019</button>
        </div>
        <div className='conteiner-items'>
        {playerData.map((playerData,index) => (
        <table className="player-info">
        <tbody>
        <tr><th colSpan={2}>{playerData.nick}</th></tr>
        <tr><td></td></tr>
        <tr><td colSpan={2}>
       <div className='player-info-image'> <ImageLightbox  image={mainImage}/> </div>
          </td></tr>
        <tr><td></td></tr>
        <tr><td>Nome:</td><td>{playerData.nome|| '--'}</td></tr>
        <tr><td>Nacionalidade: </td><td>{playerData.nacionalidade|| '--'}</td></tr>
        <tr><td>Data de nascimento:</td><td>{playerData.birthday|| '--'}</td></tr>
        <tr><td>Posição Principal:</td><td>{playerData.lane|| '--'}</td></tr>
        <tr><td>Residencia:</td><td>{playerData.lane|| '--'}</td></tr>
        <tr><th colSpan={2}>Competitivo</th></tr>
        <tr><td>Liga:</td><td>{playerData.liga|| '--'}</td></tr>
        <tr><td>Time: </td><td>{playerData.time|| '--'}</td></tr>
        <tr><td>Aposentado?:</td><td>{playerData.aposentado|| '--'}</td></tr>   
        </tbody>
      </table> 
      ))}
      <div className='tab-content'>
          {Object.entries(campeoes).map(([tournamentName, champsData]) => (
        <TournamentTable  key={tournamentName} tournamentName={tournamentName} data={champsData} />
      ))}
         </div>
         </div>
        </div>
 
    </div>
  </div> 
  );
}

export default PlayerDetailsPage;
