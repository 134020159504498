import React, { useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import './styles/login.css'; // Use a mesma folha de estilo para consistência
import { getErrorMessage } from './../../utils/errorMessages';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState(null);
  const auth = getAuth();

  const handlePasswordReset = async () => {
    try {
      await sendPasswordResetEmail(auth, email);
      setMessage('Email de recuperação enviado. Por favor, verifique sua caixa de entrada.');
      setError(null); // Limpa quaisquer erros anteriores
    } catch (error) {
      const errorMessage = getErrorMessage(error.code);
      setError(errorMessage);
      setMessage(''); // Limpa qualquer mensagem de sucesso anterior
    }
  };

  return (
    <div className="login-container">
      <div className="login-box">
        <h2>Recuperar Senha</h2>
        {message && <p className="success-message">{message}</p>}
        {error && <p className="error-message">{error}</p>}
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <button onClick={handlePasswordReset}>Enviar Email de Recuperação</button>
        <Link to="/login" className="back-link">Voltar ao Login</Link>
      </div>
    </div>
  );
}

export default ForgotPassword;
