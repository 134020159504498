import React, { useEffect, useState } from 'react';
import { localize, changeLanguage } from '../../styles/GlobalStrings.js';

import '../Schedule/styles/TabbedContent.css'; 

const ChampsResIndiv = ({ data , summonerName,championId,UrlChamp}: any) => {
    
  const [sortedData, setSortedData] = useState(Array.isArray(data) ? data : []);
  const [loading, setLoading] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const toggleExpanded = () => setExpanded(!expanded);



  useEffect(() => {
    console.log('teste2',data)
    if (data && data.length > 0) {
      setLoading(false); // Quando os dados são carregados, define loading como false
      setSortedData(data)
      console.log('teste',sortedData)

      
    }
  }, [data]); // Executa sempre que os dados mudam
  
  // Função para calcular o WR (Win Rate)
  const calcularWR = (vitorias: number, jogos: number): string => {
    if (jogos === 0) return '0%';
    const wr = (vitorias / jogos) * 100;
    return `${wr.toFixed(1)}%`;
  };

  const calcular_total = (valor: number,jogos: number): string => {
    if (jogos === 0) return '-';
    const wr = (valor/ jogos);
    return `${wr.toFixed(0)}`;
  };

  // Função para calcular o farm por minuto
  const calcularFarmPorMinuto = (cs: number, tempoMinutos: number): string => {
    if (tempoMinutos === 0) return '0';
    const farmPorMinuto = cs / tempoMinutos;
    return farmPorMinuto.toFixed(1);
  };

  // Função para calcular o gold por minuto
  const calcularGoldPorMinuto = (gold: number, tempoMinutos: number, jogos: number): string => {
    if (tempoMinutos === 0) return '0';
    const goldPorMinuto = gold / (tempoMinutos * jogos);
    return goldPorMinuto.toFixed(1);
  };

  // Função para calcular o dano por minuto
  const calcularDanoPorMinuto = (dano: number, tempoMinutos: number, jogos: number): string => {
    if (tempoMinutos === 0) return '0';
    const danoPorMinuto = dano / (tempoMinutos * jogos);
    return danoPorMinuto.toFixed(1);
  };

// Função para calcular a média de dano por jogo em milhares
const calcularMediaPorJogo1000 = (danoTotal: number, jogos: number): string => {
  const mediaDanoPorJogo = danoTotal / jogos / 1000; // Dividir por 1000 para converter para milhares
  return `${mediaDanoPorJogo.toFixed(1)}K`; // Adicionar 'K' no final para indicar milhares
  };
  // Função para calcular o KDA
  const calcularKDA = (kills: number, deaths: number, assists: number): number => {
    if (deaths === 0) {
      if (kills === 0) {
        return assists;
      } else {
        return (kills + assists) / 1;
      }
    } else {
      return (kills + assists) / deaths;
    }
  };

  function formatTime(minutes:any) {
    const wholeMinutes = Math.floor(minutes);
    const fractionalMinutes = minutes - wholeMinutes;
    const seconds = Math.round(fractionalMinutes * 60);
    return `${wholeMinutes}:${seconds.toString().padStart(2, '0')}`;
}
 

 

const getColorClass = (percentage:any) => {
    if (percentage) {
        const numericValue = parseInt(percentage.replace('%', ''), 10); // Remove o símbolo de porcentagem e converte para inteiro

        if (numericValue >= 0 && numericValue <= 40) {
            return 'dark-red';
        } else if (numericValue >= 41 && numericValue <= 49) {
            return 'light-red';
        } else if (numericValue >= 50 && numericValue <= 60) {
            return 'light-green';
        } else if (numericValue > 60 && numericValue <= 100) {
            return 'bright-green';
        }
    }
    return 'default-background'; // Retorna um padrão se o valor for null, undefined ou não dentro dos ranges
};


const getColorClassKDA = (kda:any) => {
    const kdaFloat = parseFloat(kda);
    if (kdaFloat < 1.0) {
        return 'dark-red';
    } else if (kdaFloat >= 2.0 && kdaFloat <= 4.9) {
        return 'light-green';
    } else if (kdaFloat >= 5.0) {
        return 'bright-green';
    } else {
        return 'default-background';
    }
};

const getColorBasic = (kda:any) => {
      return 'dark-gray';
};

  if (loading) {
    return(
    <>
    <tr className="player-container-db">
                  <td>
                    <div  onClick={toggleExpanded}  className="player-info-db">
                        <div className="champion-icon-db">
                        <svg className={`chevron-down ${expanded ? 'rotated' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 429.3l22.6-22.6 192-192L493.3 192 448 146.7l-22.6 22.6L256 338.7 86.6 169.4 64 146.7 18.7 192l22.6 22.6 192 192L256 429.3z" /></svg>
                            <img src={`${UrlChamp}/${championId}.png`} alt={`${championId}`} className="player-champion" onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.style.display = 'none'; }} />
                        </div>
                        <div className="player-names-db">
                            <span className="player-name-db">{summonerName}</span>
                            <span className="champion-name-db">{championId}</span>
                        </div>
                    </div>
                  </td>  
                 <td>-</td> 
                 <td>-</td> 
                 <td>-</td> 
                 <td>-</td> 
                 <td>-</td> 
                 <td>-</td> 
                 <td>-</td>    
       </tr>
    </>    
    );
  }

  return (
    <>
     <tr className="player-container-db">
                  <td>
                    <div  onClick={toggleExpanded}  className="player-info-db">
                        <div className="champion-icon-db">
                        <svg className={`chevron-down ${expanded ? 'rotated' : ''}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M256 429.3l22.6-22.6 192-192L493.3 192 448 146.7l-22.6 22.6L256 338.7 86.6 169.4 64 146.7 18.7 192l22.6 22.6 192 192L256 429.3z" /></svg>
                            <img src={`${UrlChamp}/${championId}.png`} alt={`${championId}`} className="player-champion" onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.style.display = 'none'; }} />
                        </div>
                        <div className="player-names-db">
                            <span className="player-name-db">{summonerName}</span>
                            <span className="champion-name-db">{championId}</span>
                        </div>
                    </div>
                  </td> 
            {sortedData.map((campeao: any, index: any) => (
                    <>
                     <td className={`cell ${getColorClass(campeao.lane)}`} data-tooltip={localize('GTtext')} >{campeao.games}</td>
                     <td className={`cell ${getColorClass(calcularWR(parseInt(campeao.wins), parseInt(campeao.games)))}`} data-tooltip={localize('WRtext')}>{calcularWR(parseInt(campeao.wins), parseInt(campeao.games))}</td>
                     <td className={`cell ${getColorClassKDA(calcularKDA(parseInt(campeao.kills), parseInt(campeao.deaths), parseInt(campeao.assists)))}`} data-tooltip={localize('KDAtext')} >{calcularKDA(parseInt(campeao.kills), parseInt(campeao.deaths), parseInt(campeao.assists)).toFixed(1)}</td>
                     <td className={`cell ${getColorClass(campeao.lane)}`}>{calcular_total(parseInt(campeao.total_cs),parseInt(campeao.games))}</td>
                     <td className={`cell ${getColorClass(campeao.lane)}`}>{calcularMediaPorJogo1000(parseInt(campeao.total_dmg),parseInt(campeao.games))}</td>
                     <td className={`cell ${getColorClass(campeao.lane)}`} data-tooltip={`${formatTime(campeao.avg_game_length)} ${localize('Segundostext')}`}  >{parseFloat(formatTime(campeao.avg_game_length)).toFixed(0)}</td> 
                    </>  
                
              ))}
       </tr>
        {expanded && sortedData.map((campeao: any, index: any) => (  
            <tr className={`player-container-db ${summonerName}`} key={index}>
            <th > - </th>
            <th className="tooltip" data-tooltip={localize('Killtext')} >K</th>
            <th className="tooltip" data-tooltip={localize('Deathtext')} >D</th>
            <th className="tooltip" data-tooltip={localize('Assisttext')} >A</th>
            <th className="tooltip" data-tooltip={localize('DpMtext')} >D/M</th>
            <th className="tooltip" data-tooltip={localize('CspMtext')} >CS/M</th>
            <th className="tooltip" data-tooltip={localize('GpMtext')} >G/M</th>
            </tr>
        ))}
        {expanded && sortedData.map((campeao, index) => (
      <tr className={`player-container-db ${summonerName}`} key={campeao.champion + index}>
          <td></td>
          <td className={`cell ${getColorBasic(campeao.lane)}`}>{calcular_total(parseInt(campeao.kills),parseInt(campeao.games))}</td>
          <td className={`cell ${getColorBasic(campeao.lane)}`}>{calcular_total(parseInt(campeao.deaths),parseInt(campeao.games))}</td>
          <td className={`cell ${getColorBasic(campeao.lane)}`}>{calcular_total(parseInt(campeao.assists),parseInt(campeao.games))}</td>
          <td className={`cell ${getColorBasic(campeao.lane)}`}>{calcularDanoPorMinuto(parseInt(campeao.total_dmg), parseFloat(campeao.avg_game_length), parseInt(campeao.games))}</td>
          <td className={`cell ${getColorBasic(campeao.lane)}`}>{calcularFarmPorMinuto(parseInt(campeao.total_cs), parseFloat(campeao.avg_game_length))}</td>
          <td className={`cell ${getColorBasic(campeao.lane)}`}>{calcularGoldPorMinuto(parseInt(campeao.total_gold), parseFloat(campeao.avg_game_length), parseInt(campeao.games))}</td>
      </tr>
  ))}
    </>    
  );
};

export default ChampsResIndiv;
