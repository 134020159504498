import React, { useState } from 'react';
import './styles/sobre.css'; // Arquivo CSS para estilos adicionais
import img01 from '../../assets/img/print01.png';
import img02 from '../../assets/img/print02.png';
import img03 from '../../assets/img/print03.png';
import img04 from '../../assets/img/pix.png';

type ImageKey = 'img01' | 'img02' | 'img03';

function Sobre() {
  document.title = "Sobre - Análise das Lendas ";
  const images = {
    img01: img01,
    img02: img02,
    img03: img03
  };

  const [galleryOpen, setGalleryOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState<ImageKey>('img01'); // Definindo o tipo de currentImageIndex

  const openGallery = () => {
    setGalleryOpen(true);
  };

  const closeGallery = () => {
    setGalleryOpen(false);
  };

  const goToPreviousImage = () => {
    setCurrentImageIndex((prevIndex) => {
      switch (prevIndex) {
        case 'img01':
          return 'img03';
        case 'img02':
          return 'img01';
        case 'img03':
          return 'img02';
        default:
          return prevIndex;
      }
    });
  };

  const goToNextImage = () => {
    setCurrentImageIndex((prevIndex) => {
      switch (prevIndex) {
        case 'img01':
          return 'img02';
        case 'img02':
          return 'img03';
        case 'img03':
          return 'img01';
        default:
          return prevIndex;
      }
    });
  };

  const handleCopy = () => {
    const textToCopy = '00020126580014BR.GOV.BCB.PIX0136b1e530ae-2b01-40b0-8160-7328258085365204000053039865802BR5923DIOVANNI JESUS FRANKLIN6009SAO PAULO62140510rgkUMd0BXJ6304431C';
    const textArea = document.createElement('textarea');
    textArea.value = textToCopy;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);
    alert('Chave aleatoria copiado!');
  };

  return (
    <div className="anuncios-container">
      <div className="info-box">
        <h2>Bem-vindo ao Live Stats</h2>
        <p>Somos uma página de estatísticas para jogos ao vivo. Acompanhe as estatísticas e análises em tempo real.  </p>
        <p>EM BREVE. IA com analises e Palpites Sobre Todos os jogos </p>
        <p>Para erros, duvidas ou Sugestões mande uma mensagem.  <a href="https://t.me/AnaliseLOL" target="_blank" rel="noopener noreferrer">Contato</a></p>
        <img
          src={images['img01']} // Exibir a primeira imagem na miniatura
          alt="Imagem de exemplo"
          className="info-img"
          width={600}
          onClick={openGallery}
        />
        <img
          src={images['img02']} // Exibir a primeira imagem na miniatura
          alt="Imagem de exemplo"
          className="info-img"
          width={255}
          onClick={openGallery}
        />
        <img
          src={images['img03']} // Exibir a primeira imagem na miniatura
          alt="Imagem de exemplo"
          className="info-img"
          width={310}
          onClick={openGallery}
        />
      </div>
      {galleryOpen && (
        <div className="gallery-container">
          <div className="gallery-content">
            <img src={images[currentImageIndex]} alt="Imagem em destaque" className="gallery-image" />
            <button className="prev-button" onClick={goToPreviousImage}>
              &#10094;
            </button>
            <button className="next-button" onClick={goToNextImage}>
              &#10095;
            </button>
            <button className="close-button" onClick={closeGallery}>
              Fechar
            </button>
          </div>
        </div>
      )}
      <div className='content-box'>
      <div className="info-box">
        <h2>Ajude a Manter o Site</h2>
        <p>Ajude-nos a manter o site ativo e em funcionamento. Qualquer contribuição é bem-vinda! Faça um pix:     <button className='coppy-button' onClick={handleCopy}>Copiar Chave aleatoria</button></p>
        <h2>Status de Progresso</h2>
        <div className="progress">
          <p>Conclusão Site -</p>
          <div className="progress-bar" style={{ width: '98%' }}> 98%</div>
        </div>
        <div className="progress">
          <p>Conclusão IA - </p>
          <div className="progress-bar" style={{ width: '80%' }}>80%</div>
        </div>
        <div className="progress">
          <p>Implementação da IA no site -</p>
          <div className="progress-bar" style={{ width: '70%' }}> 70%</div>
        </div>
      </div>
      </div>
    </div>
  );
}

export default Sobre;
