import React, { useEffect, useState } from 'react';
import Match from './BetMatch';
import { useBet } from './BetContext';
import { ScheduleEvent } from '../types/baseTypes';
import { getScheduleResponse } from '../../utils/LoLEsportsAPI';
import './styles/betGames.css';
import { getWindowResponse, getEventDetailsResponse, getISODateMultiplyOf10 } from '../../utils/LoLEsportsAPI';
import { WindowFrame } from '../types/baseTypes'; // Importe o tipo WindowFrame corretamente
import AlertBox from './../Geral/AlertBox';


const BetGames = (alerta:any , message:any) => {
  const { bets } = useBet();
  const [liveEvents, setLiveEvents] = useState<ScheduleEvent[]>([]);
  const [next7DaysEvents, setNext7DaysEvents] = useState<ScheduleEvent[]>([]);
  const [timeRemaining, setTimeRemaining] = useState<{ [key: string]: number }>({});

  useEffect(() => {
    fetchEvents();
    const interval = setInterval(fetchEvents, 30000);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const countdownInterval = setInterval(updateCountdown, 1000);
    return () => clearInterval(countdownInterval);
  }, [timeRemaining]);

  const fetchEvents = async () => {
    try {
      const response = await getScheduleResponse();
      const schedule = response.data.data.schedule;
  
      try {
      // Process live events
      const liveEventsPromises = schedule.events
        .filter((event: ScheduleEvent) => event.state === 'inProgress')
        .map(async (event: ScheduleEvent) => {
          const timeLeft = await fetchRemainingTime(event.match.id);
          return { ...event, timeLeft };
        });
  
      const liveEvents = await Promise.all(liveEventsPromises);
      const filteredLiveEvents = liveEvents.filter(event => event.timeLeft !== null && event.timeLeft < 7 * 60);
      setLiveEvents(filteredLiveEvents);
  
      // Create the timeRemaining object only if there are live events
      if (filteredLiveEvents.length > 0) {
        const timeRemainingObj: { [key: string]: number } = {};
        filteredLiveEvents.forEach(event => {
          timeRemainingObj[event.match.id] = event.timeLeft;
        });
        setTimeRemaining(timeRemainingObj);
      }
      } catch(error){
        console.error(error);
      }

      // Always load next 7 days events
      const next7DaysEvents = schedule.events.filter(filterByNext7Days);
      setNext7DaysEvents(next7DaysEvents);
    } catch (error) {
      console.error(error);
    }
  };

  

  const fetchRemainingTime = async (matchId: string): Promise<number | null> => {
    try {
      const eventDetailsResponse = await getEventDetailsResponse(matchId);
      const eventDetails = eventDetailsResponse.data.data.event;
  
      if (!eventDetails) return null;
  
      const gameId = eventDetails.match.games[0].id;
      const date = getISODateMultiplyOf10();
  
      const response1 = await getWindowResponse(gameId);
      if (!response1 || !response1.data.frames) return null;
      let frames1: WindowFrame[] = response1.data.frames;
      if (frames1.length === 0) return null;
      const firstFrame = frames1[0];


      const response = await getWindowResponse(gameId, date);
      if (!response || !response.data.frames) return null;
      let frames: WindowFrame[] = response.data.frames;
      if (frames.length === 0) return null;

      const lastFrame = frames[frames.length - 1];

      const now = new Date(firstFrame.rfc460Timestamp);
      const eventTime1 = new Date(lastFrame.rfc460Timestamp);
      const eventTime = new Date();

      if (eventTime.getTime() > now.getTime()) {
        const timeDiff = (eventTime.getTime() - now.getTime()) / 1000; // em segundos
        console.log('Time difference in seconds:', timeDiff);
        return timeDiff;
      } else {
        console.log('Event time is in the past.');
      }
  
      return null;
    } catch (error) {
      console.error('Error fetching remaining time:', error);
      return null;
    }
  };

  const updateCountdown = () => {
    setTimeRemaining(prevTimeRemaining => {
      const updatedTimeRemaining = { ...prevTimeRemaining };
      Object.keys(updatedTimeRemaining).forEach(matchId => {
        if (updatedTimeRemaining[matchId] > 0) {
          updatedTimeRemaining[matchId] -= 1;
        }
      });
      return updatedTimeRemaining;
    });
  };

  const hasBetOnMatch = (match_id: string) => {
    return bets.some(bet => bet.match_id === match_id);
  };

  const renderMatches = (events: ScheduleEvent[], emptyMessage: string, title: string) => {
    return (
      <div className="bet-no-games-card">
        <h2>{title}</h2>
        {events.length === 0 ? (
          <div>{emptyMessage}</div>
        ) : (
          events.map(scheduleEvent => {
            if (scheduleEvent.league.slug !== 'tft_esports') {
              const matchId = scheduleEvent.match.id;
              const hasBet = hasBetOnMatch(matchId);
              if (!hasBet) {
                return (
                  <Match
                    key={matchId}
                    match_id={matchId}
                    scheduleEvent={scheduleEvent}
                    timeRemaining={timeRemaining[matchId]}
                  />
                );
              }
            }
            return null;
          })
        )}
      </div>
    );
  };

  return (
    <div>
      {renderMatches(liveEvents, 'Nenhum jogo ao vivo', 'Jogos Ao Vivo')}
      {renderMatches(next7DaysEvents, 'Nenhum jogo futuro', 'Jogos Futuros')}
    </div>
  );
};

function filterLiveEvents(scheduleEvent: ScheduleEvent) {
  return scheduleEvent.match !== undefined && scheduleEvent.state === "inProgress";
}

function filterByNext7Days(scheduleEvent: ScheduleEvent) {
  if (scheduleEvent.state === "inProgress" || scheduleEvent.state === "completed") {
      return
  }
  let minDate = new Date();
  let maxDate = new Date()
  minDate.setHours(minDate.getHours() - 1)
  maxDate.setDate(maxDate.getDate() + 7)
  let eventDate = new Date(scheduleEvent.startTime)

  if(eventDate.valueOf() > minDate.valueOf() && eventDate.valueOf() < maxDate.valueOf()){

      if(scheduleEvent.match === undefined) return false
      if(scheduleEvent.match.id === undefined) return false

      return true;
  }else{
      return false;
  }
}

export default BetGames;
