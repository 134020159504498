import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../utils/AuthContext';
import { Link_App } from '../../utils/LoLEsportsAPI';
import './styles/lojaMain.css';

interface RankingUser {
    user_id: string;
    username: string;
    points: number;
    rank: number;
  }

export const Ranking = () => {
    const { user } = useAuth();
    const [ranking, setRanking] = useState<RankingUser[]>([]);
    const [userPosition, setUserPosition] = useState<RankingUser | null>(null);
    const [totalItems, setTotalItems] = useState(0); 
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 20;
    const userId = user?.uid;

    useEffect(() => {
        fetchRanking();
      }, [ currentPage]);

    const fetchRanking = async () => {
        try {
          const response = await axios.get(`${Link_App}/get_ranking`);
          if (response.data.success) {
            const allRanking = response.data.ranking;
            // Ordenar o ranking por pontos
            allRanking.sort((a: RankingUser, b: RankingUser) => b.points - a.points);
            setTotalItems(allRanking.length);
            
            // Atribuir posições aos usuários
            allRanking.forEach((user: RankingUser, index: number) => {
              user.rank = index + 1;
            });
    
            const startIdx = (currentPage - 1) * itemsPerPage;
            const slicedRanking = allRanking.slice(startIdx, startIdx + itemsPerPage);
            setRanking(slicedRanking);
            
            const userRank = allRanking.find((user: RankingUser) => user.user_id === userId);
            setUserPosition(userRank || null);
          }
        } catch (error) {
          console.error('Failed to fetch ranking:', error);
        }
      };

      const startIdx = (currentPage - 1) * itemsPerPage;

    return(
        <div className="ranking-section">
          <h2>Ranking</h2>
          <table className="ranking-table">
            <thead>
              <tr>
                <th>Posição</th>
                <th>Usuário</th>
                <th>Pontos</th>
              </tr>
            </thead>
            <tbody>
              {ranking.map((user, index) => (
                <tr key={user.user_id} className={user.user_id === userId ? 'highlight' : ''}>
                  <td>
                    <div className="ranking-position">
                      {startIdx + index + 1 === 1 && '🥇 1'}
                      {startIdx + index + 1 === 2 && '🥈 2'}
                      {startIdx + index + 1 === 3 && '🥉 3'}
                      {startIdx + index + 1 > 3 && startIdx + index + 1}
                    </div>
                  </td>
                  <td className="username">{user.username}</td>
                  <td>{user.points} points</td>
                </tr>
              ))}
            </tbody>
          </table>
          {userPosition && !ranking.some(user => user.user_id === userId) && (
            <div className="user-position">
              <table className="ranking-table">
                <tbody>
                  <tr className="highlight">
                    <td>
                      <div className="ranking-position">
                        {userPosition.rank === 1 && '🥇 1'}
                        {userPosition.rank === 2 && '🥈 2'}
                        {userPosition.rank === 3 && '🥉 3'}
                        {userPosition.rank > 3 && userPosition.rank}
                      </div>
                    </td>
                    <td className="username">{userPosition.username}</td>
                    <td>{userPosition.points} points</td>
                  </tr>
                </tbody>
              </table>
            </div>
           )}
             <div className="pagination">
             <span>
               Mostrando {Math.min((currentPage - 1) * itemsPerPage + 1, totalItems)} - {Math.min(currentPage * itemsPerPage, totalItems)} de {totalItems}
             </span>
             <button onClick={() => setCurrentPage(1)} disabled={currentPage === 1}>Início</button>
             <button onClick={() => setCurrentPage(prev => prev - 1)} disabled={currentPage === 1}>Anterior</button>
             <button onClick={() => setCurrentPage(prev => prev + 1)} disabled={currentPage * itemsPerPage >= totalItems}>Próximo</button>
             <button onClick={() => setCurrentPage(Math.ceil(totalItems / itemsPerPage))} disabled={currentPage * itemsPerPage >= totalItems}>Final</button>
           </div>
         </div>
      )
}

export default Ranking;