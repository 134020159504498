const fluxo = 'https://am-a.akamaihd.net/image?resize=96:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1670539056915_fluxo_escudo_w.png'
const loud = 'https://am-a.akamaihd.net/image?resize=96:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2FLogo-LOUD-Esports_Original.png'
const pain = 'https://am-a.akamaihd.net/image?resize=96:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1674657011011_pain_logo_white.png'
const los = 'https://am-a.akamaihd.net/image?resize=96:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1686087430642_logo_los_white_newest.png'
const keyd = 'https://am-a.akamaihd.net/image?resize=96:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1670542079678_vks.png'
const liberty = 'https://am-a.akamaihd.net/image?resize=96:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1643305707691_RXfNcFMU.png'
const red = 'https://am-a.akamaihd.net/image?resize=96:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1631820575924_red-2021-worlds.png'
const kbm = 'https://am-a.akamaihd.net/image?resize=96:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1671197541491_LOGO-KABUM-ESPORTS-2023-NEGATIVO-FUNDOPRETO.png'
const intz = 'https://am-a.akamaihd.net/image?resize=96:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1643056732785_INTZ_Logo_Principal_2022.png'
const fur = 'https://am-a.akamaihd.net/image?resize=96:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2FFURIA---black.png'
const fla = 'https://am-a.akamaihd.net/image?resize=32:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1706802053260_LOGOFLAESPORTSCRFCOLORIDA.png'
const idl = 'https://am-a.akamaihd.net/image?resize=1080:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1716451549109_ILHA-DAS-LENDAS-ESPORTS-BRANCO.png'
const rise = 'https://am-a.akamaihd.net/image?resize=32:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1716451640141_2-IYVhiot.png'
const trz = 'https://am-a.akamaihd.net/image?resize=48:&f=http%3A%2F%2Fstatic.lolesports.com%2Fteams%2F1716451713850_CopyofLogocoloridaTRZ.PNG'





export const tournamentsData = [
    {
        name: 'CBLOL 2024 Split 2',
        namekey: 'CBLOL',
        schedule: [
            { team1: 'LOUD', team2: 'Pain', time: '13:00', date: '01/06', day: 'Sab', team1Icon: loud, team2Icon: pain },
            { team1: 'Fluxo', team2: 'FURIA', time: '14:00', date: '01/06', day: 'Sab', team1Icon: fluxo, team2Icon: fur },
            { team1: 'LOS', team2: 'INTZ', time: '15:00', date: '01/06', day: 'Sab', team1Icon: los, team2Icon: intz },
            { team1: 'Liberty', team2: 'KaBuM!', time: '16:00', date: '01/06', day: 'Sab', team1Icon: liberty, team2Icon: kbm },
            { team1: 'Vivo Keyd Stars', team2: 'RED', time: '17:00', date: '01/06', day: 'Sab', team1Icon: keyd, team2Icon: red },
            { team1: 'FURIA', team2: 'LOS', time: '13:00', date: '02/06', day: 'Dom', team1Icon: fur, team2Icon: los },
            { team1: 'KaBuM!', team2: 'Vivo Keyd Stars', time: '14:00', date: '02/06', day: 'Dom', team1Icon: kbm, team2Icon: keyd },
            { team1: 'Pain', team2: 'Fluxo', time: '15:00', date: '02/06', day: 'Dom', team1Icon: pain, team2Icon: fluxo },
            { team1: 'INTZ', team2: 'Liberty', time: '16:00', date: '02/06', day: 'Dom', team1Icon: intz, team2Icon: liberty },
            { team1: 'RED', team2: 'LOUD', time: '17:00', date: '02/06', day: 'Dom', team1Icon: red, team2Icon: loud },
        ],
        pointsTable: [
            { name: 'LOUD', points: 0, wins: 0, losses: 0, games: 0 },
            { name: 'Pain', points: 0, wins: 0, losses: 0, games: 0 },
            { name: 'RED', points: 0, wins: 0, losses: 0, games: 0 },
            { name: 'LOS', points: 0, wins: 0, losses: 0, games: 0 },
            { name: 'Fluxo', points: 0, wins: 0, losses: 0, games: 0 },
            { name: 'Liberty', points: 0, wins: 0, losses: 0, games: 0 },
            { name: 'INTZ', points: 0, wins: 0, losses: 0, games: 0 },
            { name: 'FURIA', points: 0, wins: 0, losses: 0, games: 0 },
            { name: 'Vivo Keyd Stars', points: 0, wins: 0, losses: 0, games: 0 },
            { name: 'KaBuM!', points: 0, wins: 0, losses: 0, games: 0 },

        ],
        teams: [
            {
                name: 'LOUD',
                logo: pain,
                players: [
                    { id: 'Robo', name: 'Robo', role: 'Top' },
                    { id: 'Croc', name: 'Croc', role: 'Jungle' },
                    { id: 'tinowns', name: 'Tinowns', role: 'Mid' },
                    { id: 'Route', name: 'Route', role: 'ADC' },
                    { id: 'RedBert', name: 'RedBert', role: 'Support' }
                ]
            },
            {
                name: 'PAIN',
                logo: loud,
                players: [
                    { id: 'Wizer', name: 'Wizer', role: 'Top' },
                    { id: 'CarioK', name: 'CarioK', role: 'Jungle' },
                    { id: 'dyNquedo', name: 'dyNquedo', role: 'Mid' },
                    { id: 'TitaN', name: 'TitaN', role: 'ADC' },
                    { id: 'Kuri', name: 'Kuri', role: 'Support' }
                ]
            },
            {
                name: 'RED',
                logo: red,
                players: [
                    { id: 'fNb', name: 'fNb', role: 'Top' },
                    { id: 'Aegis', name: 'Aegis', role: 'Jungle' },
                    { id: 'Grevthar', name: 'Grevthar', role: 'Mid' },
                    { id: 'Brance', name: 'Brance', role: 'ADC' },
                    { id: 'frostyy', name: 'frostyy', role: 'Support' }
                ]
            },
            {
                name: 'Vivo KEYD Stars',
                logo: keyd,
                players: [
                    { id: 'Guigo',  role: 'Top' },
                    { id: 'Disamis',  role: 'Jungle' },
                    { id: 'Leleko',  role: 'Mid' },
                    { id: 'Smiley',  role: 'ADC' },
                    { id: 'ProDelta',  role: 'Support' }
                ]
            },
            {
                name: 'LOS Grandes',
                logo: los,
                players: [
                    { id: 'SuperCleber',  role: 'Top' },
                    { id: 'Seize',  role: 'Jungle' },
                    { id: 'Envy',  role: 'Mid' },
                    { id: 'brTT',  role: 'ADC' },
                    { id: 'kabbie',  role: 'Support' }
                ]
            },
            {
                name: 'Liberty',
                logo: liberty,
                players: [
                    { id: 'Makes',  role: 'Top' },
                    { id: 'Drakehero',  role: 'Jungle' },
                    { id: 'Pilot',  role: 'Mid' },
                    { id: 'micaO',  role: 'ADC' },
                    { id: 'Cavalo',  role: 'Support' }
                ]
            },
            {
                name: 'KaBuM!',
                logo: kbm,
                players: [
                    { id: 'Lonely',  role: 'Top' },
                    { id: 'Malrang',  role: 'Jungle' },
                    { id: 'Hauz',  role: 'Mid' },
                    { id: 'Netuno',  role: 'ADC' },
                    { id: 'Ceos',  role: 'Support' }
                ]
            },
            {
                name: 'INTZ',
                logo: intz,
                players: [
                    { id: 'Yupps',  role: 'Top' },
                    { id: 'Yampi',  role: 'Jungle' },
                    { id: 'Dioge',  role: 'Mid' },
                    { id: 'NinjaKiwi',  role: 'ADC' },
                    { id: 'Damage',  role: 'Support' }
                ]
            },
            {
                name: 'FURIA',
                logo: fur,
                players: [
                    { id: 'Zzk',  role: 'Top' },
                    { id: 'Wiz',  role: 'Jungle' },
                    { id: 'Tutsz',  role: 'Mid' },
                    { id: 'Ayu',  role: 'ADC' },
                    { id: 'JoJo',  role: 'Support' }
                ]
            },
            {
                name: 'FLUXO',
                logo: fluxo,
                players: [
                    { id: 'Kiari',  role: 'Top' },
                    { id: 'Shini',  role: 'Jungle' },
                    { id: 'Fuuu',  role: 'Mid' },
                    { id: 'Trigo',  role: 'ADC' },
                    { id: 'scamber',  role: 'Support' }
                ]
            },
           
            // Mais times...
        ]
    },
    {
        name: 'CBLOL Academy 2024 Split 2',
        namekey: 'CBLOL - Academy',
        schedule: [
            { team1: 'paiN Academy', team2: 'Vivo Keyd Stars Academy', time: '16:00', date: '03/06', day: 'Seg', team1Icon: pain, team2Icon: keyd },
            { team1: 'FURIA Academy', team2: 'INTZ Academy', time: '17:00', date: '03/06', day: 'Seg', team1Icon: fur, team2Icon: intz },
            { team1: 'KaBuM! Academy', team2: 'LOUD Academy', time: '18:00', date: '03/06', day: 'Seg', team1Icon: kbm, team2Icon: loud },
            { team1: 'Flamengo MDL', team2: 'IDL Esports', time: '19:00', date: '03/06', day: 'Seg', team1Icon: fla, team2Icon: idl },
            { team1: 'Rise Gaming', team2: 'Tropa Raizen', time: '20:00', date: '03/06', day: 'Seg', team1Icon: rise, team2Icon: trz },
            { team1: 'RED Academy', team2: 'Liberty Academy', time: '21:00', date: '03/06', day: 'Seg', team1Icon: red, team2Icon: liberty },
            { team1: 'Fluxo Academy', team2: 'LOS Academy', time: '22:00', date: '03/06', day: 'Seg', team1Icon: fluxo, team2Icon: los },
            
            { team1: 'IDL Esports', team2: 'KaBuM! Academy', time: '16:00', date: '04/06', day: 'Ter', team1Icon: idl, team2Icon: kbm },
            { team1: 'Vivo Keyd Stars Academy', team2: 'Rise Gaming', time: '17:00', date: '04/06', day: 'Ter', team1Icon: keyd, team2Icon: rise },
            { team1: 'INTZ Academy', team2: 'Tropa Raizen', time: '18:00', date: '04/06', day: 'Ter', team1Icon: intz, team2Icon: trz },
            { team1: 'Liberty Academy', team2: 'FURIA Academy', time: '19:00', date: '04/06', day: 'Ter', team1Icon: liberty, team2Icon: fur },
            { team1: 'Flamengo MDL', team2: 'Fluxo Academy', time: '20:00', date: '04/06', day: 'Ter', team1Icon: fla, team2Icon: fluxo },
            { team1: 'LOS Academy', team2: 'RED Academy', time: '21:00', date: '04/06', day: 'Ter', team1Icon: los, team2Icon: red },
            { team1: 'LOUD Academy', team2: 'paiN Academy', time: '22:00', date: '04/06', day: 'Ter', team1Icon: loud, team2Icon: pain},
        ],
        pointsTable: [
            { name: 'LOUD Academy', points: 0, wins: 0, losses: 0, games: 0 },
            { name: 'paiN Academy', points: 0, wins: 0, losses: 0, games: 0 },
            { name: 'RED Academy', points: 0, wins: 0, losses: 0, games: 0 },
            { name: 'LOS Academy', points: 0, wins: 0, losses: 0, games: 0 },
            { name: 'Fluxo Academy', points: 0, wins: 0, losses: 0, games: 0 },
            { name: 'Liberty', points: 0, wins: 0, losses: 0, games: 0 },
            { name: 'INTZ', points: 0, wins: 0, losses: 0, games: 0 },
            { name: 'FURIA Academy', points: 0, wins: 0, losses: 0, games: 0 },
            { name: 'Vivo Keyd Stars Academy', points: 0, wins: 0, losses: 0, games: 0 },
            { name: 'KaBuM! Academy', points: 0, wins: 0, losses: 0, games: 0 },
            { name: 'Flamengo MDL', points: 0, wins: 0, losses: 0, games: 0 },
            { name: 'IDL Esports', points: 0, wins: 0, losses: 0, games: 0 },
            { name: 'Rise Gaming', points: 0, wins: 0, losses: 0, games: 0 },
            { name: 'Tropa Raizen', points: 0, wins: 0, losses: 0, games: 0 },
        ],
        
    },
];

