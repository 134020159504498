import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { collection, query, where, getDocs ,collectionGroup } from 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyAfStDhAKCb2R1WG1Ob9oXxRqFq9-bR9Go",
    authDomain: "analise-das-lendas.firebaseapp.com",
    projectId: "analise-das-lendas",
    storageBucket: "analise-das-lendas.appspot.com",
    messagingSenderId: "519849334104",
    appId: "1:519849334104:web:dcea79bf4a185f06adc12d",
    measurementId: "G-NM34RLK62E"
};


const app = initializeApp(firebaseConfig);
const db = getFirestore(app); // Exporte db como default

export const fetchCampeoesTotal2 = async (season, seasonTime, liga, lanename) => { // Adicionei champName como parâmetro
    const campeoesCollection = collection(db, 'campeoes', season, seasonTime, liga,'lane', lanename, 'campeoes');
    const q = query(campeoesCollection); // Filtrar por nome do campeão
    const campeoesDocs = await getDocs(q);
    const campeoesData = campeoesDocs.docs.map(doc => doc.data());
    return campeoesData;
};


export const fetchCampeoesTotal = async (season, seasonTime, liga, lanename) => {
    let campeoesCollection;

    if (lanename) {
        campeoesCollection = collection(db, 'campeoes', season, seasonTime, liga, 'lane', lanename, 'campeoes');
        const q = query(campeoesCollection);
        const campeoesDocs = await getDocs(q);
        const campeoesData = campeoesDocs.docs.map(doc => doc.data());
        console.log(campeoesData)
        return campeoesData;   
    } else {
        const campeoesCollection = collectionGroup(db, 'campeoes');
        const q = query(campeoesCollection);
        const campeoesDocs = await getDocs(q);
        const campeoesData = campeoesDocs.docs
            .filter(doc => doc.ref.path.includes(`${season}/${seasonTime}/${liga}/lane/`)) // Filtra documentos com o caminho correto
            .map(doc => doc.data());
        console.log(campeoesData);
    return campeoesData;
    }
};
   

export const fetchCampeoesPlayers = async (player,champName,time) => {
    const campeoesCollection = collection(db, 'players', player, time);
    const q = query(campeoesCollection, where('champion', '==', champName));
    const campeoesDocs = await getDocs(q);
    const campeoesData = campeoesDocs.docs.map(doc => doc.data());
    return campeoesData;
};


export const fetchCampeoesFilter = async (season, seasonTime, liga, champName) => { // Adicionei champName como parâmetro
    const campeoesCollection = collection(db, 'campeoes', season, seasonTime, liga, 'campeoes');
    const q = query(campeoesCollection, where('champion', '==', champName)); // Filtrar por nome do campeão
    const campeoesDocs = await getDocs(q);
    const campeoesData = campeoesDocs.docs.map(doc => doc.data());
    return campeoesData;
};

export const fetchCampeoesFilterGame = async (season, seasonTime, liga, champName, lane) => { // Adicionei champName como parâmetro
    const campeoesCollection = collection(db, 'campeoes', season, seasonTime,liga,'lane', lane,'campeoes');
    const q = query(campeoesCollection, where('champion', '==', champName)); // Filtrar por nome do campeão
    const campeoesDocs = await getDocs(q);
    const campeoesData = campeoesDocs.docs.map(doc => doc.data());
    return campeoesData;
};

export const fetchPlayerData = async (id) => {
    const campeoesCollection = collection(db, 'players');
    const q = query(campeoesCollection,where('id', '==', id));
    const PlayerDocs = await getDocs(q);
    const PlayerData = PlayerDocs.docs.map(doc => {return doc.data();
     });
  
     return PlayerData; 
};
export default db;