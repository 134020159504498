export const getErrorMessage = (errorCode: string): string => {
    switch (errorCode) {
      case 'auth/email-already-in-use':
        return 'O endereço de email já está em uso. Por favor, use outro email.';
      case 'auth/invalid-email':
        return 'O endereço de email é inválido. Por favor, insira um email válido.';
      case 'auth/operation-not-allowed':
        return 'Operação não permitida. Por favor, entre em contato com o suporte.';
      case 'auth/weak-password':
        return 'A senha é muito fraca. Por favor, use uma senha mais forte.';
      case 'auth/user-disabled':
        return 'Este usuário foi desativado. Por favor, entre em contato com o suporte.';
      case 'auth/user-not-found':
        return 'Usuário não encontrado. Por favor, verifique suas credenciais.';
      case 'auth/wrong-password':
        return 'Senha incorreta. Por favor, tente novamente.';
      default:
        return 'Ocorreu um erro. Por favor, tente novamente.';
    }
  };