import React, { useState } from 'react';
import { GamesCarousel } from './GamesCarousel';
import { HeroSection } from './HeroSection';
import { DetailsSection } from './DetailsSection';
import './styles/inicio.css'; // Arquivo CSS para estilos adicionais


function Inicio() {

  document.title = "Inicio - Análise das Lendas ";
  return (
  <div>
   
    <HeroSection />
    <DetailsSection />
    <GamesCarousel />
  </div>
  );
}

export default Inicio;
