import './styles/navbarStyle.css'; // Assuming styles for Navbar and menu bar
import { ReactComponent as LoLLogoSVG } from '../../assets/images/logo.svg';
import { ReactComponent as AnaliseName } from '../../assets/images/Analise.svg';
import { usePremium } from './../Premium/PremiumContext';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ThemeToggler } from "./ThemeToggler";
import { SoundToggler } from "./SoundToggler";
import React, { useState } from "react";

import { ReactComponent as Profile } from '../../assets/icons/profile.svg';
import { ReactComponent as Calendar } from '../../assets/icons/calendar.svg';
import { ReactComponent as Home } from '../../assets/icons/home.svg';
import { ReactComponent as Player } from '../../assets/icons/player.svg';
import { ReactComponent as Premium } from '../../assets/icons/premium.svg';
import { ReactComponent as Champ } from '../../assets/icons/spartan.svg';
import { ReactComponent as About } from '../../assets/icons/about3.svg';
import { ReactComponent as Config } from '../../assets/icons/config.svg';
import { useAuth } from './../../utils/AuthContext';

export function Navbar() {
  const [searchTerm, setSearchTerm] = useState('');
  const [configVisible, setConfigVisible] = useState(false);
  const history = useNavigate();
  const location = useLocation();
  const { isPremium } = usePremium();
  const { userLoggedIn , userProfile } = useAuth();

  const handleSearch = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && searchTerm.trim()) {
      history(`/search?query=${searchTerm.trim()}`); // Redireciona para a página de pesquisa
    }
  };

  const toggleConfigBox = () => {
    setConfigVisible(!configVisible);
  };

  const formatNumber = (number: any): string => {
    try{
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
     }
    catch{

    }
   return("-") 
  };
  return (
    <nav className="navbar-container">
      <div className="navbar-container1">
        <Link className="navbar-logo" to="/">
          <LoLLogoSVG className="navbar-icon-img" />
          <h2 className="navbar-icon">Analise das Lendas</h2>
        </Link>
        <div className="menu-container">
          <li><Link to="/" className={location.pathname === "/" ? "active" : ""}> <Home className='icons-nav'/> Início</Link></li>
          <li><Link to="/LojaMain" className={location.pathname === "/LojaMain" ? "active" : ""}> <Home className='icons-nav'/>Loja</Link></li>
          <li><Link to="/Ranking" className={location.pathname === "/Ranking" ? "active" : ""}><Calendar className='icons-nav'/> Ranking</Link></li>
          <li><Link to="/TabelasGames" className={location.pathname === "/TabelasGames" ? "active" : ""}> <Home className='icons-nav'/>Cronograma</Link></li>
          {isPremium ? (
             <></>
          ) : (
            <li><Link to="/Premium" className={location.pathname === "/Premium" ? "active" : ""}> <Premium className='icons-nav'/> Premium</Link></li>
          )}
          <li><Link to="/Campeoes" className={location.pathname === "/Campeoes" ? "active" : ""}> <Champ className='icons-nav' /> Campeões</Link></li>
          <li><Link to="/Jogadores" className={location.pathname === "/Jogadores" ? "active" : ""}>< Player className='icons-nav'/> Jogadores </Link></li>
         {/* <li><Link to="/Cbolao" className={location.pathname === "/Cbolao" ? "active" : ""}> <Player className='icons-nav'/> CBolão</Link></li>*/}
          <li><Link to="/Sobre" className={location.pathname === "/Sobre" ? "active" : ""}> <About className='icons-nav'/> Sobre</Link></li>
          <li className='config-button' onClick={toggleConfigBox}><Config className='icons-nav'/>Config</li>
          <div className={`config-box ${configVisible ? 'visible' : ''}`}>
          <SoundToggler />
          <ThemeToggler />
        </div>        
        </div>
        {userLoggedIn ? (
          <div className='User-Perfil'>
            <div className='Al-points'><Link to="/Perfil"> <a className='Alp-box'>AP$:</a> <a>{formatNumber(userProfile?.points)}</a></Link></div>
            <div className='User-Perfil-div'><Link to="/Perfil" className={location.pathname === "/Perfil" ? "active" : ""}><Profile className='icons-Perfil'/></Link></div>    
            </div>       
          ) : (
            <li> <Link to="/Login"> <Profile className='icons-nav'/> Login</Link></li>
          )}
       
      </div>
    </nav>
  );
}
