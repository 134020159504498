import React, { useState, useEffect } from 'react';
import BetBox from './BetBox'
import './styles/betBox.css'


const DraggableBet: React.FC<{ id: string }> = ({ id }) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleBet = () => {
        setIsExpanded(!isExpanded);
      };

  return (

     <div className={`floating-Bet ${isExpanded ? 'expanded' : ''}`}>
         <button className="Bet-button" onClick={toggleBet}>
          {isExpanded ? 'Fechar' : 'Bet'}
        </button>
        {isExpanded && (
          <div className='Bet-box'>
      <BetBox/>
      </div>
         )}
      </div>

  );
}

export default DraggableBet;
