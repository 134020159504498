// src/components/GameCard.tsx
import React from 'react';
import './styles/GameLiveCard.css';
import {Link} from "react-router-dom";
import {ReactComponent as TeamTBDSVG} from '../../assets/images/team-tbd.svg';
import {ScheduleEvent} from "../types/baseTypes.js";
import { localize, changeLanguage } from '../../styles/GlobalStrings.js';


interface GameCardProps {
  scheduleEvent: ScheduleEvent;
  
}

export function GameCard({ scheduleEvent }: GameCardProps) {
    function TruncateH3({ text }:any) {
        // Divide o texto em palavras
        const words = text.split(' ');
        // Pega as duas primeiras palavras
        const truncatedText = words.slice(0, 1).join(' ');
      
        return <h3 className='live-content-Card-game-names-mini'>{truncatedText}</h3>;
      }

  return (
    <Link to={`live/${scheduleEvent.match.id}`}>
        <div className="live-Card-game-mini">
            <h3>{scheduleEvent.league.name}</h3> 
            <h1>Ao Vivo</h1>
            <div className="live-content-Card-game-mini">
                <div className="live-team-Card-game-mini">
                    {scheduleEvent.match.teams[0].code === "TBD" ? (<TeamTBDSVG className="live-team-image-Card-game-mini"/>) : (<img className="live-team-image-Card-game-mini" src={scheduleEvent.match.teams[0].image} alt={scheduleEvent.match.teams[0].name}/>) }
                    <span className='live-content-Card-game-mini-span'>
                        <TruncateH3 text={scheduleEvent.match.teams[0].name} /> 
                    </span>
                </div>
                <h2>VS</h2>
                <div className="live-team-Card-game-mini">
                    {scheduleEvent.match.teams[1].code === "TBD" ? (<TeamTBDSVG className="live-team-image-Card-game-mini"/>) : (<img className="live-team-image-Card-game-mini" src={scheduleEvent.match.teams[1].image} alt={scheduleEvent.match.teams[1].name}/>) }
                    <span className='live-content-Card-game-mini-span'>
                        <TruncateH3 text={scheduleEvent.match.teams[1].name} /> 

                    </span>
                    
                    
                </div>
            </div>
        </div>
    </Link>
);
}
