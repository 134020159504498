const championNamesReverse = {
    "Wukong" : "MonkeyKing" ,
    "K'Sante" : "KSante" ,
    "Cho'Gath" : "Chogath" ,
    "LeBlanc" : "Leblanc" ,
    "Bel'Veth" : "Belveth" ,
    "Renata Glasc" : "Renata" ,
    'Xin Zhao' : "XinZhao" ,
    "Kog'Maw" : "KogMaw" ,
    "Vel'Koz" : "Velkoz" ,
    "Kha'Zix" : "Khazix" ,
    "Master Yi" : "MasterYi" ,
    "Lee Sin" : "LeeSin" ,
    "Kai'Sa" : "Kaisa" ,
    "Aurelion Sol" : "AurelionSol" ,
    "Jarvan IV" : "JarvanIV" ,
    "Dr. Mundo" : "DrMundo" ,
    "Tahm Kench": "TahmKench" ,
    "Twisted Fate" : "TwistedFate" ,
    "Rek'Sai" : "RekSai" ,
    "RenataGlasc" : "RenataGlasc" ,
    "Miss Fortune" : "MissFortune" 
};

export default championNamesReverse;