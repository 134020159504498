import React, { useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import './styles/login.css';
import { getErrorMessage } from './../../utils/errorMessages';
import { Link_App } from '../../utils/LoLEsportsAPI';
import axios from 'axios';

const Cadastro = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [nome, setNome] = useState('');
    const [birthdate, setBirthdate] = useState('');
    const [error, setError] = useState<string | null>(null);
    const [redirect, setRedirect] = useState(false);

    const handleCadastro = async (e: React.FormEvent) => {
        e.preventDefault();
        setError(null);

        if (password !== confirmPassword) {
            setError('As senhas não coincidem.');
            return;
        }

        try {
            const response = await axios.post(`${Link_App}/register`, {
                email,
                password,
                nome,
                birthdate
            });

            if (response.data.success) {
                setRedirect(true);
            } else {
                setError(response.data.message);
            }
        } catch (error:any) {
            setError(getErrorMessage(error.response.status));
        }
    };

    if (redirect) {
        return <Navigate to="/inicio" />;
    }

    return (
        <div className="signup-container">
            <div className="signup-box">
                <h2>Cadastro</h2>
                {error && <p className="error-message">{error}</p>}
                <form onSubmit={handleCadastro}>
                    <input type="text" placeholder="Nome" value={nome} onChange={(e) => setNome(e.target.value)} required />
                    <input type="date" placeholder="Data de Nascimento" value={birthdate} onChange={(e) => setBirthdate(e.target.value)} required />
                    <input type="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
                    <input
                        type="password"
                        placeholder="Senha"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                    <input
                        type="password"
                        placeholder="Confirme a Senha"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        required
                    />
                    <button type="submit">Cadastrar</button>
                </form>
                <Link to="/login">Já tem uma conta? Faça login</Link>
            </div>
        </div>
    );
};

export default Cadastro;
