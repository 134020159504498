import React from 'react';
import './styles/tabelasPontos.css';

export const BoxTabelaData = ({ Tabela }: any) => {
    // Verifica se Tabela é um array válido
    if (!Array.isArray(Tabela) || Tabela.length === 0) {
        return (
            <div className="points-table">
                <h3>Tabela de Pontos</h3>
                <table>
                    <thead>
                        <tr>
                            <th>Posição</th>
                            <th>Equipe</th>
                            <th>V</th>
                            <th>D</th>
                            <th>G</th>
                        </tr>
                    </thead>
                    <tbody>
                        {/* Pode adicionar uma linha aqui para indicar que não há dados */}
                        <tr>
                            <td colSpan={5}>Nenhum dado disponível</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        );
    }

    return (
        <PointsTable pointsTable={Tabela} />
    );
}

const PointsTable: React.FC<{ pointsTable: any[] }> = ({ pointsTable }) => {
    // Ordena a tabela de pontos por vitórias, da maior para a menor
    const sortedPointsTable = [...pointsTable].sort((a, b) => b.wins - a.wins) || [];

    return (
        <div className="points-table">
            <h3>Tabela de Pontos</h3>
            <table>
                <thead>
                    <tr>
                        <th>Posição</th>
                        <th>Equipe</th>
                        <th>V</th>
                        <th>D</th>
                        <th>G</th>
                    </tr>
                </thead>
                <tbody>
                    {sortedPointsTable.map((team, index) => (
                        <tr key={index} className={getRowClass(index, sortedPointsTable.length)}>
                            <td>{index + 1}</td>
                            <td>{team.team}</td>
                            <td>{team.wins}</td>
                            <td>{team.losses}</td>
                            <td>{team.wins + team.losses}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

// Função auxiliar para determinar a classe da linha com base na posição
const getRowClass = (index: number, totalTeams: number) => {
    if (index < 3) return 'top-three';
    if (index >= totalTeams - 4) return 'bottom-four';
    return '';
};

export default BoxTabelaData;
