import React, { useEffect, useState } from 'react';
import { getDocs, collection, query, orderBy, where } from 'firebase/firestore';
import { Link } from 'react-router-dom';
import './Style/PlayersPage.css';
import db from './../../firebaseConfig';

interface Player {
  id: string;
  liga: string;
  time: string;
  nick: string;
  nome: string;
  lane: string;
  nacionalidade: string;
  birthday: Date;
  aposentado: string;
}

function PlayersPage() {
  const [players, setPlayers] = useState<Player[]>([]);
  const [playersFil, setPlayersFil] = useState<Player[]>([]);
  const [sortBy, setSortBy] = useState<string>('nome');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [filteredLiga, setFilteredLiga] = useState<string | null>(null);
  const [filteredLane, setFilteredLane] = useState<string | null>(null);
  const [showDropdownLiga, setShowDropdownLiga] = useState<boolean>(false);
  const [showDropdownLane, setShowDropdownLane] = useState<boolean>(false);

  useEffect(() => {
    document.title = "Jogadores - Análise das Lendas ";
    const fetchPlayers = async () => {
      const playersCollection = collection(db, 'players');
      let q = query(playersCollection, orderBy(sortBy, sortDirection));

      if (filteredLiga) {
        q = query(q, where('liga', '==', filteredLiga));
      }

      if (filteredLane) {
        q = query(q, where('lane', '==', filteredLane));
      }

      const playerDocs = await getDocs(q);
      const playersList = playerDocs.docs.map(doc => ({ id: doc.id, ...doc.data() } as Player));
      setPlayers(playersList);

    };

    fetchPlayers();
  }, [sortBy, sortDirection, filteredLiga, filteredLane]);

  const handleSort = (field: string) => {
    if (field === sortBy) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortBy(field);
      setSortDirection('asc');
    }
  };

  const toggleDropdown = (dropdown: 'liga' | 'lane') => {
    if (dropdown === 'liga') {
      setShowDropdownLiga(!showDropdownLiga);
      setShowDropdownLane(false);
    } else if (dropdown === 'lane') {
      setShowDropdownLane(!showDropdownLane);
      setShowDropdownLiga(false);
    }
  };

  const handleFilter = (field: 'liga' | 'lane', value: string) => {
    if (field === 'liga') {
      setFilteredLiga(value);
      setShowDropdownLiga(false);
    } else if (field === 'lane') {
      setFilteredLane(value);
      setShowDropdownLane(false);
    }
  };

  const performSearch = () => {
    // Converte o termo de pesquisa para minúsculas para facilitar a comparação
    const searchTermLowerCase = searchTerm.toLowerCase();
  
    // Filtra os jogadores apenas se houver um termo de pesquisa presente
    if (searchTerm) {
      // Filtra os jogadores com base em qualquer propriedade que contenha o termo de pesquisa
      const filteredPlayers = players.filter((player: Player) =>
        Object.values(player).some((value: any) => {
          if (typeof value === 'string') {
            return value.toLowerCase().includes(searchTermLowerCase);
          }
          return false;
        })
      );
  
      // Atualiza a lista de jogadores filtrados
      setPlayersFil(filteredPlayers);
    } else {
      // Se a barra de pesquisa estiver vazia, exibe todos os jogadores
     // Função responsável por buscar todos os jogadores
     setPlayersFil(players);
    }
  };
  
  return (
    <div className="players-container">
     <div className="search-bar">
        <input
          type="text"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyUp={performSearch}
          placeholder="Pesquisar..."
        />
        <button onClick={performSearch}>Pesquisar</button>
      </div>
      <table className="wikitable sortable team-members hoverable-rows team-members-current jquery-tablesorter">
        <thead>
          <tr>
            <th className="th-with-dropdown" onClick={() => toggleDropdown('liga')}>
              Liga {showDropdownLiga ? '▲' : '▼'}
              {showDropdownLiga && (
                <div className="dropdown-menu">
                  <div onClick={() => handleFilter('liga', 'CBLOL')}>CBLOL</div>
                  <div onClick={() => handleFilter('liga', 'LCK')}>LCK</div>
                  <div onClick={() => handleFilter('liga', 'LPL')}>LPL</div>
                  <div onClick={() => handleFilter('liga', 'LEC')}>LEC</div>
                </div>
              )}
            </th>
            <th onClick={() => handleSort('time')}>Time</th>
            <th onClick={() => handleSort('nick')}>Nick</th>
            <th onClick={() => handleSort('nome')}>Nome</th>
            <th className="th-with-dropdown" onClick={() => toggleDropdown('lane')}>
              Lane {showDropdownLane ? '▲' : '▼'}
              {showDropdownLane && (
                <div className="dropdown-menu">
                  <div onClick={() => handleFilter('lane', 'Top')}>Top</div>
                  <div onClick={() => handleFilter('lane', 'Jungle')}>Jungle</div>
                  <div onClick={() => handleFilter('lane', 'Mid')}>Mid</div>
                  <div onClick={() => handleFilter('lane', 'Bot')}>Bot</div>
                  <div onClick={() => handleFilter('lane', 'Support')}>Support</div>
                </div>
              )}
            </th>
            <th onClick={() => handleSort('nacionalidade')}>Nacionalidade</th>
            <th onClick={() => handleSort('birthday')}>Idade</th>
            <th onClick={() => handleSort('aposentado')}>Status</th>
          </tr>
        </thead>
        <tbody>
          {players
            .filter(player => 
            /*  player.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
              player.nacionalidade.toLowerCase().includes(searchTerm.toLowerCase()) ||*/
              player.nick.toLowerCase().includes(searchTerm.toLowerCase()) ||
              player.liga.toLowerCase().includes(searchTerm.toLowerCase()) ||
              player.lane.toLowerCase().includes(searchTerm.toLowerCase()) 
            )
            .map((player, index) => (
              <tr key={index}>
                <td>{player.liga|| 'NA'}</td>
                <td>{player.time|| 'NA'}</td>
                <td><Link to={`/players/${player.id}`}>{player.nick|| 'NA'}</Link></td>
                <td><Link to={`/players/${player.id}`}>{player.nome|| 'NA'}</Link></td>
                <td>{player.lane|| 'NA'}</td>
                <td>{player.nacionalidade|| 'NA'}</td>
                <td>{calcularIdade(player.birthday)|| 'NA'}</td>
                <td>{player.aposentado === 'Yes' ? 'Aposentado' : 'Ativo'|| 'NA'}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

const calcularIdade = (dataNascimento: Date) => {
  const hoje = new Date();
  const dataNasc = new Date(dataNascimento);
  let idade = hoje.getFullYear() - dataNasc.getFullYear();
  const mesAtual = hoje.getMonth() + 1;
  const diaAtual = hoje.getDate();
  const mesNasc = dataNasc.getMonth() + 1;
  const diaNasc = dataNasc.getDate();

  if (mesAtual < mesNasc || (mesAtual === mesNasc && diaAtual < diaNasc)) {
    idade--;
  }

  return idade;
};

export default PlayersPage;
