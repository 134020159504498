import React, { useEffect, useState, useRef  } from 'react';
import  db  from './../../firebaseConfig';
import { collection, addDoc, query, orderBy, onSnapshot, updateDoc, doc } from "firebase/firestore";
import { useAuth } from './../../utils/AuthContext';
import { useNavigate } from 'react-router-dom';
import './styles/Chat.css';
import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import { ReactComponent as Config } from '../../assets/icons/config.svg';
import Badge from './Badge'; // Importe o componente de badge


const Chat: React.FC = () => {
  const [messages, setMessages] = useState<any[]>([]);
  const [newMessage, setNewMessage] = useState('');
  const [autoScroll, setAutoScroll] = useState(true);
  const [showEmojis, setShowEmojis] = useState(false);
  const [showConfig, setShowConfig] = useState(false);
  const { user, userLoggedIn, userProfile } = useAuth();
  const navigate = useNavigate();
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const emojiPickerRef = useRef(null);
  const [nameColor, setNameColor] = useState('#000000'); // Estado para a cor do nome

  useEffect(() => {
    if (userProfile?.nameColor) {
      setNameColor(userProfile.nameColor);
    }
  }, [userProfile]);

  useEffect(() => {
    const q = query(collection(db, "messages"), orderBy("timestamp"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      setMessages(snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      if (autoScroll) {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
      }
    });
    return () => unsubscribe();
  }, [autoScroll]);

  const sendMessage = async () => {
    if (newMessage.trim() !== '' && user) {
      await addDoc(collection(db, "messages"), {
        text: newMessage,
        uid: user.uid,
        displayName: userProfile?.nome,
        badgeType: userProfile?.badgeType,
        nameColor: userProfile?.nameColor,
        timestamp: new Date()
      });
      setNewMessage('');
    }
  };

  const handleLoginRedirect = () => {
    navigate('/login');
  };

  const handleEmojiSelect = (emoji: any) => {
    setNewMessage(prev => prev + emoji.native);
    setShowEmojis(false);
  };

  const toggleEmojiPicker = () => {
    setShowEmojis(!showEmojis);
  };

  const toggleConfigPicker = () => {
    setShowConfig(!showConfig);
  };

  const formatTimestamp = (timestamp: any) => {
    const date = timestamp.toDate();
    return `${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  };

  const formatDate = (timestamp: any) => {
    const date = timestamp.toDate();
    return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()}`;
  };
  
  const groupedMessages = messages.reduce((acc, message) => {
    const dateKey = formatDate(message.timestamp);
    if (!acc[dateKey]) {
      acc[dateKey] = [];
    }
    acc[dateKey].push(message);
    return acc;
  }, {});

  
  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      sendMessage();
    }
  };
  
  const handleColorChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const newColor = e.target.value;
    setNameColor(newColor);
    if (user) {
      const userDoc = doc(db, "users", user.uid);
      await updateDoc(userDoc, { nameColor: newColor });
    }
  };

  return (
    <div className="chat-container">
      <div className="chat-header">
        <div className='config-header-box-icon'> <h2>Chat Analise Das Lendas</h2></div>
       
        <div className='config-box-icon' ><Config className='config-box-icons-nav' onClick={toggleConfigPicker}/></div>
        
        <div ref={emojiPickerRef} className={`config-box ${showConfig ? 'show' : ''}`}>
        <label>
         <a>Auto-scroll</a> 
          <input
            type="checkbox"
            checked={autoScroll}
            onChange={(e) => setAutoScroll(e.target.checked)}
          />
        </label>
        <label>
        <a>Cor do nome</a>
            <input type="color" value={nameColor} onChange={handleColorChange} />
          </label>
          </div>
      </div>
      <div className="chat-messages">
        {Object.keys(groupedMessages).map(date => (
          <div key={date}>
            <div className="date-separator">{date}</div>
            {groupedMessages[date].map((msg: any) => (
              <div key={msg.id} className={`message ${msg.uid === user?.uid ? 'sent' : 'received'}`}>
               <div className="message-user" style={{ color: msg.nameColor }}>
                  <Badge type={msg.badgeType} /> {/* Renderize o badge com base na propriedade badgeType do usuário */}              
                  {msg.displayName}:  {msg.text}                  
                </div>
                <div className="message-timestamp">{formatTimestamp(msg.timestamp)}</div>
                <div ref={messagesEndRef} />
              </div>
            ))}
          </div>
        ))}
      </div>
      {userLoggedIn ? (
        <div className="chat-input">
          <button className='emoji-but' onClick={toggleEmojiPicker}>😊</button>
          <div ref={emojiPickerRef} className={`emoji-picker ${showEmojis ? 'show' : ''}`}>
            <Picker data={data} onEmojiSelect={handleEmojiSelect} />
          </div>
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            onKeyPress={handleKeyPress}
            placeholder="Digite sua mensagem..."
          />
          <button onClick={sendMessage}>Enviar</button>
        </div>
      ) : (
        <div className="chat-login-prompt">
          <p>Você precisa estar logado para enviar mensagens.</p>
          <button onClick={handleLoginRedirect}>Ir para Login</button>
        </div>
      )}
    </div>
  );
};

export default Chat;