import {Link_App} from './utils/LoLEsportsAPI';

export const fetch_tournament_tabela = async (TournamentName) => {
      const response = await fetch(`${Link_App}/get_tournament_tabela?tournament_name=${TournamentName}`);
      const data = await response.json();  // Expecting an array of champions
      return data
      
  };

export  const fetch_champs_Indiv = async (tourname, team) => {
        const response = await fetch(`${Link_App}/get_team_data?tournament_name=${tourname}&team_name=${team}`);
        const data = await response.json(); // Expecting an array of champions
        return data;
    
};