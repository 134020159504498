import React from 'react';
import './styles/adBanner.css';

const AdBanner: React.FC = () => {
  return (
    <div className="ad-banner">
      <h1>Faça parte do nosso conteúdo Premium! </h1>
      <p>Para primeiros Registro use o Bonus : </p>
      <h2> Novo50 </h2>
      <p>Para </p><h2>50%</h2><p>de Desconto no primeiro Mês e</p>
      <h1>7 Dias Gratis!</h1>
    </div>
  );
};

export default AdBanner;
