import React, { useEffect, useState } from 'react';
import './styles/ad.css';
import { Link } from 'react-router-dom';


const Anuncios = () => {
const [visible , setvisible] = useState(true);

  if (visible === true){
    return(
        <div className="anuncios-header">
            
            <div>        
            <a >Estamos no Beta Teste reporte se encontrar algum erro,  se quiser nos ajudar clique no Saiba Mais. </a>
            <a >Feito por fãs do Baiano e de League of Legends (Não somos um site oficial, Adota nois Baiano). </a>
            <Link to="/Sobre">
            <button>Saiba Mais</button>
            </Link>
            </div>
            <button className="close-button2" onClick={() => setvisible(false)}>X</button>
        </div>
    )

  } else {
    return(
        <></>
    )
     }

}


export default Anuncios;