import React, { createContext, useState, useContext, ReactNode, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from './../../utils/AuthContext';
import { Link_App } from '../../utils/LoLEsportsAPI';
import AlertBox from './../Geral/AlertBox';

interface Bet {
  match_id: string;
  amount: number;
  prediction: string;
  outcome?: string;
  userId?: string;
  bet_time?:string;
  bet_img?:string;
}

interface BetContextProps {
    bets: Bet[];
    addBet: (bet: Bet) => Promise<void>;
  }

const BetContext = createContext<BetContextProps | undefined>(undefined);

const BetProvider = ({ children }: { children: ReactNode }) => {
  const { user, userProfile} = useAuth();
  const [bets, setBets] = useState<Bet[]>([]);
  const [alertVisible, setAlertVisible] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');

  const showAlert = (message: string) => {
    setAlertMessage(message);
    setAlertVisible(true);
  };

  const closeAlert = () => {
    setAlertVisible(false);
    setAlertMessage('');
  };
  useEffect(() => {
    if (user) {
      loadBets(user.uid);
    } else {
      setBets([]);
    }
  }, [user]);

  const loadBets = async (userId: string) => {
    try {
      const response = await axios.get(`${Link_App}/get_bets`, {
        params: {
          user_id: userId // Substituir pelo ID do usuário atual
        }
      });

      if (response.data.success) {
        setBets(response.data.bets);
        console.log(bets)
      }
    } catch (error) {
      console.error('Error loading bets:', error);
    }
  };



  const addBet = async (bet: Bet) => {
    if (!user) {
      console.error('User is not authenticated');
      return;
    }
  
    if (userProfile.points < bet.amount) {
      showAlert('Pontos insuficientes');
      return;
    }
  
    try {
      const existingBet = bets.find(b => b.match_id === bet.match_id);
      if (existingBet) {
        showAlert('Aposta já existe para este jogo');
        return;
      }
  
      const response = await axios.post(`${Link_App}/place_bet`, {
        user_id: user.uid,
        match_id: bet.match_id,
        amount: bet.amount,
        prediction: bet.prediction,
        bet_img:bet.bet_img
      });
  
      if (response.data.success) {
        setBets([...bets, { ...bet, userId: user.uid, outcome: 'pending' }]);
        showAlert("Aposta Feita com Sucesso!!");
        // Remover o jogo da lista de seleção
        // Exemplo: supondo que bets seja uma lista de jogos disponíveis para apostar
        // Aqui você precisa implementar a lógica para remover o jogo da lista de seleção
      }
    } catch (error) {
      console.error('Failed to place bet:', error);
      showAlert('Erro ao fazer a aposta. Tente novamente mais tarde.');
    }
  };


  const contextValue: BetContextProps = {
    bets,
    addBet,
    
  };

  return (
      <BetContext.Provider value={contextValue}>
         {children}
         {alertVisible && <AlertBox message={alertMessage} onClose={closeAlert} />} {/* exibir alerta com a mensagem */}
      </BetContext.Provider>
  );
};

const useBet = () => {
  const context = useContext(BetContext);
  if (!context) {
    throw new Error('useBet must be used within a BetProvider');
  }
  return context;
};

export { BetProvider, useBet };
