

export default {
  //export const ITEMS_URL = "https://ddragon.leagueoflegends.com/cdn/14.3.1/img/item/"
// export const CHAMPIONS_URL = "https://ddragon.bangingheads.net/cdn/14.3.1/img/champion/"
// const ITEMS_JSON_URL = `https://ddragon.leagueoflegends.com/cdn/14.3.1/data/en_US/item.json`
ITEMS_URL : "https://ddragon.bangingheads.net/cdn/PATCH_VERSION/img/item/",
CHAMPIONS_URL : "https://ddragon.bangingheads.net/cdn/PATCH_VERSION/img/champion/",
Link_App: "https://api.analisedaslendas.com",
Link_App1: "http://localhost:5000",
Link_Video_URL1 : "localhost",
Link_Video_URL : "analisedaslendas.com",
ITEMS_JSON_URL : `https://ddragon.leagueoflegends.com/cdn/PATCH_VERSION/data/en_US/item.json`,

LOL_API_URL_PERSISTED : "https://esports-api.lolesports.com/persisted/gw",
LOL_API_URL_LIVE : "https://feed.lolesports.com/livestats/v1",
LOL_API_KEY : "0TvQnueqKa5mxJntVWt0w4LpLfEkrV1Ta8rQBb9Z"
}